import {
  INSURANCE_CONTRACT_FREQUENCY,
  INSURANCE_CONTRACT_STATUS,
} from 'qonto/constants/insurance-hub';

export const getInsuranceContractStatusProps = ({ intl, status }) => {
  switch (status) {
    case INSURANCE_CONTRACT_STATUS.PENDING_PAYMENT:
    case INSURANCE_CONTRACT_STATUS.ACTION_REQUIRED:
      return {
        level: 'warning',
        text: intl.t('insurance-hub.policies.status.action-required'),
      };

    case INSURANCE_CONTRACT_STATUS.ACTIVE:
      return {
        level: 'validated',
        text: intl.t('insurance-hub.policies.status.active'),
      };

    case INSURANCE_CONTRACT_STATUS.PENDING_OTHERS:
      return {
        level: 'waiting',
        text: intl.t('insurance-hub.policies.status.scheduled'),
      };

    case INSURANCE_CONTRACT_STATUS.EXPIRED:
      return {
        level: 'inactive',
        text: intl.t('insurance-hub.policies.status.expired'),
      };
  }
};

export const getInsuranceContractPaymentFrequency = ({ intl, frequency }) => {
  switch (frequency) {
    case INSURANCE_CONTRACT_FREQUENCY.MONTHLY:
      return intl.t('insurance-hub.policies.price.monthly');

    case INSURANCE_CONTRACT_FREQUENCY.QUARTERLY:
      return intl.t('insurance-hub.policies.price.quarterly');

    case INSURANCE_CONTRACT_FREQUENCY.YEARLY:
      return intl.t('insurance-hub.policies.price.yearly');

    default:
      return '-';
  }
};
