import { PREFERRED_COUNTRIES } from 'qonto/constants/countries';

const sortByPreferedCountries = sortByPreferedCountriesWith({ code: 'code', name: 'name' });
export { sortByPreferedCountries };

export function sortByPreferedCountriesWith({ code, name }) {
  return function preferedCountriesSort(a, b) {
    let uppercaseCodes = code => code.toUpperCase();
    let reversedPreferredCountries = PREFERRED_COUNTRIES.map(uppercaseCodes);

    let aIndex = reversedPreferredCountries.indexOf(a[code]);
    let bIndex = reversedPreferredCountries.indexOf(b[code]);

    if (aIndex === -1 && bIndex !== -1) {
      return 1;
    }

    if (aIndex !== -1 && bIndex === -1) {
      return -1;
    }

    if (aIndex !== -1 && bIndex !== -1) {
      return aIndex > bIndex ? 1 : -1;
    }

    let aName = a[name].toUpperCase();
    let bName = b[name].toUpperCase();

    return aName > bName ? 1 : -1;
  };
}

export function sortByOrgLocale(a, b, orgLocale) {
  // orgLocale always comes first
  if (a.code === orgLocale) {
    return -1;
  }

  if (b.code === orgLocale) {
    return 1;
  }

  // english comes before all other locales
  if (a.code === 'en') {
    return -1;
  }

  if (b.code === 'en') {
    return 1;
  }

  // otherwise, sort alphabetically considering
  // there are no equal locales
  return a.code < b.code ? -1 : 1;
}
