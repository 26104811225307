import { buildValidations, validator } from 'ember-cp-validations';

const ChecksDetailsValidations = buildValidations(
  {
    'check.amount': [
      validator('ds-error'),
      validator('number', {
        allowString: true,
        positive: true,
        gt: 0,
        messageKey: 'validations.errors.check-amount',
      }),
      validator('presence', true),
    ],
    'check.cmc7': [
      validator('ds-error'),
      validator('length', {
        min: 31,
        messageKey: 'validations.errors.cmc7_too_short',
      }),
      validator('presence', true),
    ],
    'check.rlmc': [
      validator('ds-error'),
      validator('length', {
        min: 2,
        messageKey: 'validations.errors.rlmc_too_short',
      }),
      validator('presence', true),
    ],
  },
  {
    lazy: true,
  }
);

export default ChecksDetailsValidations;
