import Transform from '@ember-data/serializer/transform';

export default class MapTransform extends Transform {
  deserialize(serialized) {
    let map = new Map();
    Object.keys(serialized).forEach(key => map.set(key, serialized[key]));
    return map;
  }

  serialize(deserialized) {
    let map = {};
    if (deserialized) {
      deserialized.forEach((key, value) => (map[key] = value));
    }
    return map;
  }
}
