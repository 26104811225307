import { EmbeddedRecordsMixin } from '@ember-data/serializer/rest';

import { normalizeMissingBankAccount } from 'qonto/utils/normalize-missing-bank-account';

import ApplicationSerializer from './application';

export default class RequestTransferSerializer extends ApplicationSerializer.extend(
  EmbeddedRecordsMixin
) {
  attrs = {
    attachments: {
      serialize: 'ids',
      deserialize: 'ids',
    },
  };

  modelNameFromPayloadKey() {
    return 'request-transfer';
  }

  payloadKeyFromModelName() {
    return 'request_transfer';
  }

  normalize(modelClass, payload) {
    normalizeMissingBankAccount(this.store, payload);
    return super.normalize(modelClass, payload);
  }
}
