import type { XlsFile } from 'qonto/lib/xls-file';
import type { TemplateMatcher } from './types.ts';

export function matchTemplate(xlsFile: XlsFile, templates: TemplateMatcher) {
  if (xlsFile.columns) {
    const templateEntries = Object.entries(templates);
    for (let [templateName, template] of templateEntries) {
      if (Object.keys(template.mapping).every(key => xlsFile.columns.includes(key))) {
        return {
          template: templateName,
          ...template,
        };
      }
    }
  }

  return { template: null, mapping: {}, colsToHide: [] };
}
