import Evented from '@ember/object/evented';
import Service from '@ember/service';

import { task, waitForEvent } from 'ember-concurrency';
import window from 'ember-window-mock';
import { Socket } from 'phoenix';

import ENV from 'qonto/config/environment';
import { websocketBaseURL } from 'qonto/constants/hosts';

const { environment } = ENV;

/**
 *  Service extended from PhoenixSocket to open and manage
 *  a Websocket connection with our backend websocket service
 *
 *  @class NotifierManager
 *  @extends Service
 */
export default class NotifierManager extends Service.extend(Evented) {
  socket = null;

  /**
   * Connect to the websocket
   *
   * @public
   * @method connect
   *
   * @param  {String} token
   * @param  {ID} uid
   *
   * @returns void
   */
  connect(token, uid) {
    if (environment !== 'test' || window.testNotifierManager) {
      this.socket = new Socket(`${websocketBaseURL}/socket`, { params: { token } });
      this.socket.connect();

      this.channel = this.socket.channel(`user:${uid}`);
      this.channel.join();

      this.channel.onMessage = (event, payload) => {
        this.trigger(event, payload);
        return payload;
      };
    }
  }

  reconnect(token, uid) {
    if (this.socket) {
      this.socket.disconnect();
    }
    this.connect(token, uid);
  }

  willDestroy() {
    super.willDestroy(...arguments);

    if (this.channel) {
      this.channel.leave();
    }

    if (this.socket) {
      this.socket.disconnect();
    }
  }

  /**
   * Wait till the socket eventEmitter receives a certain event
   * @param {String} event
   * @returns { Promise<{event: string; payload: any;}>}
   */
  waitForEventTask = task(async event => {
    let payload = await waitForEvent(this, event);
    return { event, payload };
  });
}
