import { EmbeddedRecordsMixin } from '@ember-data/serializer/rest';

import ApplicationSerializer from 'qonto/serializers/application';

export default class MandateSerializer extends ApplicationSerializer.extend(EmbeddedRecordsMixin) {
  attrs = {
    attachments: {
      serialize: 'ids',
      deserialize: 'ids',
    },
  };

  serialize(snapshot, options) {
    let json = super.serialize(snapshot, options);
    let activityTag = json.activity_tag;

    json.mandates_counterparty = {
      activity_tag: activityTag,
    };
    delete json.activity_tag;
    return json;
  }

  normalize(typeClass, payload, prop) {
    payload.activity_tag = payload.mandates_counterparty.activity_tag;

    delete payload.mandates_counterparty;

    return super.normalize(typeClass, payload, prop);
  }
}
