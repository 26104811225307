import EmberObject from '@ember/object';
import { camelize } from '@ember/string';

import transformKeys from 'qonto/utils/transform-keys';

export default function emberize(object, keyTransform = camelize, valueTransform = emberize) {
  let transformed = transformKeys(object, keyTransform, valueTransform);
  return transformed === object || Array.isArray(object)
    ? transformed
    : EmberObject.create(transformed);
}
