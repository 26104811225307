import { buildValidations, validator } from 'ember-cp-validations';

const CardValidations = buildValidations({
  pin: {
    validators: [validator('presence', true), validator('pin')],
  },
  pinConfirmation: {
    validators: [
      validator('presence', true),
      validator('confirmation', {
        on: 'pin',
        messageKey: 'validations.errors.pin_do_not_match',
      }),
      validator('pin'),
    ],
  },
});

export default CardValidations;
