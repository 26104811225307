import ApplicationSerializer from 'qonto/serializers/application';

const PAYLOAD_KEY = 'settings';

export default class extends ApplicationSerializer {
  modelNameFromPayloadKey(payloadKey) {
    if (payloadKey === PAYLOAD_KEY) {
      payloadKey = 'receipt-reminder';
    }
    return super.modelNameFromPayloadKey(payloadKey);
  }

  serialize(snapshot, options) {
    let json = super.serialize(snapshot, options);
    delete json.id;
    delete json.organization_id;

    return json;
  }

  normalizeResponse(store, primaryModelClass, payload, id, requestType) {
    let { settings } = payload;

    settings.id = id;

    return super.normalizeResponse(store, primaryModelClass, payload, id, requestType);
  }

  payloadKeyFromModelName() {
    return PAYLOAD_KEY;
  }
}
