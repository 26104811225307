import { EmbeddedRecordsMixin } from '@ember-data/serializer/rest';

import ApplicationSerializer from 'qonto/serializers/application';

export default class ApprovalWorkflowSerializer extends ApplicationSerializer.extend(
  EmbeddedRecordsMixin
) {
  attrs = {
    rulesets: { embedded: 'always' },
  };
}
