/* eslint-disable no-console */

const artwork =
  '                                                                                              \n' +
  "     'iqQ@@@@@@@@#5^                                                                          \n" +
  '   ~R@@@@WmziLcfKQ@@@@j`                                         _@@@;                        \n' +
  '  o@@@QT.         ~6@@@Q;                                        ^@@@?                        \n' +
  ' w@@@q`             ;@@@@^      jgQ@@@@QDj       *zz=`^nPXSz;`   ^@@@qzzzz`    qQ@@@@@&w*     \n' +
  "!@@@8                +@@@Q    |Q@@@@@@@@@@QL    g@@Q@@@@@@@@@a`  ^@@@@@@@@'  ~U@@@@@@@@@@@w'  \n" +
  "y@@@7                 Q@@@, ,#@@@d|~''~LR@@@#.  g@@@N<'``~y@@@Q' ^@@@x~~~~  y@@@Q{!,',^5@@@@? \n" +
  'f@@@t      `_=Y,     `@@@@, Q@@@=        !@@@g  g@@Q`      i@@@y ^@@@<     i@@@q`       .g@@@~\n' +
  ":@@@Q'     ~@@@Q'    }@@@Q ~@@@d          w@@@, g@@D       ~@@@X ^@@@<     g@@@,         ;@@@j\n" +
  " i@@@Q;     ;Q@@Q? `I@@@@~ .@@@Q`        `#@@@. g@@D       ~@@@X ^@@@<     X@@@>         7@@@'\n" +
  "  <Q@@@RL'   .b@@@Q@@@@D'   Y@@@Q^      +B@@@|  g@@D       ~@@@X '@@@X     ,@@@@{.     ,m@@@N`\n" +
  "   `zQ@@@@@QN8Q@@@@@@@@Xjz   ^B@@@@WKK#@@@@N!   g@@D       ~@@@X  |@@@@b6DT 'X@@@@QdKRQ@@@@s` \n" +
  "     ~zAQ@@@@@@Qj   %@@@@Q      jgQ@@@@@QDj     UQQE       _QQQy   ~aQ@@@@7   'TqQ@@@@@&w*`   \n" +
  '                                                                                              \n';

const sentence =
  'Join us to create the bank all businesses love! https://qonto.com/careers | jobs@qonto.com';

const artworkStyle = 'color: #9b81f6; font-family: monospace; font-size: 10px;';
const sentenceStyle = 'font-family: monospace; font-size: 12px;';

export function hiringMessage() {
  console.log(`%c ${artwork}`, artworkStyle);
  console.log(`%c ${sentence}`, sentenceStyle);
}
