import { EmbeddedRecordsMixin } from '@ember-data/serializer/rest';

import ApplicationSerializer from 'qonto/serializers/application';

export default class OrganizationSerializer extends ApplicationSerializer.extend(
  EmbeddedRecordsMixin
) {
  attrs = {
    address: { embedded: 'always' },
    documents: { embedded: 'always' },
    stakeholders: { embedded: 'always' },
    labelLists: { embedded: 'always' },
    labels: { embedded: 'always' },
  };

  serialize(snapshot, options) {
    let json = super.serialize(snapshot, options);
    delete json.vat_number;
    delete json.tax_number;
    delete json.district_court;
    delete json.commercial_register_number;
    delete json.company_leadership;
    return json;
  }

  normalizeFindRecordResponse(store, _, payload) {
    let memberships = store
      .peekAll('membership')
      .filter(m => m.organization.id === payload.organization.id);

    payload.organization.membership_ids = memberships.length
      ? memberships.map(m => m.id)
      : undefined;

    return super.normalizeFindRecordResponse(...arguments);
  }
}
