import Service, { service } from '@ember/service';

import { task, timeout } from 'ember-concurrency';

import { apiBaseURL, attachmentNamespace, requestsNamespace } from 'qonto/constants/hosts';

/**
 * Uploader manager
 *
 * @class UploaderManager
 * @module qonto/services/uploader-manager
 * @extends Ember.Service
 */
export default class UploaderManager extends Service {
  @service organizationManager;
  @service networkManager;

  /**
   * get the payload for an upload
   *
   * @public
   * @method getUploadPayload
   *
   * @param  {String} [url=`${apiBaseURL}/${attachmentNamespace}/attachments`]
   * @param  {String} [payloadName='attachment']
   *
   * @returns {Object} Generated payload
   */
  getUploadPayload(
    url = `${apiBaseURL}/${attachmentNamespace}/attachments`,
    payloadName = 'attachment'
  ) {
    let headers = Object.assign({}, this.networkManager.requestXHeaders);

    let data = [];
    let { organization, currentAccount } = this.organizationManager;

    data[`${payloadName}[organization_id]`] = organization.id;
    data[`${payloadName}[bank_account_id]`] = currentAccount.id;

    let fileKey =
      payloadName === 'attachment' ? `${payloadName}[file]` : `${payloadName}[file][file]`;
    return {
      url,
      fileKey,
      data,
      headers,
      withCredentials: true,
    };
  }

  /**
   * Upload a file
   *
   * @public
   * @method uploadTask
   *
   * @param  {Object} file
   * @param  {String} url
   * @param  {String} payloadName
   * @return {Promise}
   */
  uploadTask = task({ maxConcurrency: 3, enqueue: true }, async (file, url, payloadName) => {
    await timeout(15);
    return await file.upload(this.getUploadPayload(url, payloadName));
  });

  /**
   * get the payload for an upload
   *
   * @public
   * @method getMultiTransferUploadPayload
   *
   * @param  {String} [url=`${apiBaseURL}/${requestsNamespace}/requests/multi_transfers/upload`]
   *
   * @returns {Object} Generated payload
   */
  getMultiTransferUploadPayload(
    url = `${apiBaseURL}/${requestsNamespace}/requests/multi_transfers/upload`
  ) {
    let headers = Object.assign({}, this.networkManager.requestXHeaders);

    let data = [];
    let { organization } = this.organizationManager;

    data[`organization_id`] = organization.id;
    let fileKey = 'file';

    return {
      url,
      fileKey,
      data,
      headers,
      withCredentials: true,
    };
  }

  /**
   * Upload a file
   *
   * @public
   * @method uploadTask
   *
   * @param  {Object} file
   * @param  {String} url
   * @param  {String} payloadName
   * @return {Promise}
   */
  uploadMultiTransferTask = task({ maxConcurrency: 3, enqueue: true }, async (file, url) => {
    await timeout(15);
    return await file.upload(this.getMultiTransferUploadPayload(url));
  });
}
