// @ts-nocheck
import { action } from '@ember/object';
import { next } from '@ember/runloop';
import Service, { service } from '@ember/service';

import window from 'ember-window-mock';

import { SEGMENT_READY_EVENT } from 'qonto/metrics-adapters/local-segment';
import EventsQueue from 'qonto/utils/events-queue';
import removeKey from 'qonto/utils/remove-key';

/**
 * Segment manager
 *
 * @class Segment Manager
 * @module qonto/services/segment
 * @extends Ember.Service
 */
export default class Segment extends Service {
  @service metrics;
  @service cookiesConsentManager;

  eventsQueue = new EventsQueue();

  constructor() {
    super(...arguments);
    window.addEventListener(SEGMENT_READY_EVENT, this.segmentReady);
  }

  willDestroy() {
    super.willDestroy(...arguments);
    window.removeEventListener(SEGMENT_READY_EVENT, this.segmentReady);
  }

  @action
  segmentReady() {
    // we need this in case when user doesn't do anything, example:
    // 1. user enters the page
    // 2. segment not loaded yet
    // 3. events go to the buffer
    // 4. cookie consent exists (accepted on qonto.com)
    // 5. segment was loaded
    // 6. users leaves the page (no actions by user) -> events lost
    //
    // in this example we will lose users that didn't go through the flow
    // we still need to send events if we are allowed to
    this.eventsQueue.flush();
  }

  /**
   * Identify the user for segment
   *
   * @public
   * @method identify
   *
   * @param  {Object} params
   * @returns void
   */
  identify(params) {
    this.sendOrSave({ callback: this._identify, args: [params] });
  }

  _identify(params) {
    next(this.metrics, 'identify', 'LocalSegment', { ...params, ...this.integrations });
  }

  /**
   * Send a custom event to Segment
   *
   * @public
   * @method track
   *
   * @param  {String} eventName Name of the event sent to segment
   * @param  {Object} [params={}]
   *
   * @returns void
   */
  track(eventName, params = {}) {
    let safeParams = removeKey(params, 'iban');
    this.sendOrSave({ callback: this._track, args: [eventName, safeParams] });
  }

  _track(eventName, params = {}) {
    next(this.metrics, 'trackEvent', 'LocalSegment', {
      event: eventName,
      ...params,
      ...this.integrations,
    });
  }

  /**
   * Attach a user to a group
   *
   * @public
   * @method group
   * @param  {Object} params
   *
   * @returns void
   */
  group(params) {
    this.sendOrSave({ callback: this._group, args: [params] });
  }

  _group(params) {
    let canUseDOM = Boolean(
      typeof window !== 'undefined' && window.document && window.document.createElement
    );
    let { groupId } = params;
    delete params.groupId;

    next(() => {
      if (canUseDOM && window.analytics) {
        window.analytics.group(groupId, params);
      }
    });
  }

  /**
   * Reset
   *
   * @public
   * @method reset
   * @returns void
   *
   * WARNING. This method destroys Segment session and might affect
   * many metrics tools used by the Growth Team. Double check before using it:
   * https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/#reset-or-logout
   */
  reset() {
    this.sendOrSave({ callback: this._reset, args: [] });
  }
  _reset() {
    let canUseDOM = Boolean(
      typeof window !== 'undefined' && window.document && window.document.createElement
    );

    next(() => {
      if (canUseDOM && window.analytics) {
        window.analytics.reset();
      }
    });
  }

  get isSegmentReady() {
    return Boolean(window.analytics);
  }

  get isSegmentBlocked() {
    return Boolean(window.analyticsFailedToLoad);
  }

  sendOrSave({ callback, args }) {
    if (this.isSegmentReady) {
      this.eventsQueue.flush();
      return callback.apply(this, args);
    }

    if (this.isSegmentBlocked) {
      // eslint-disable-next-line ember/no-array-prototype-extensions
      this.eventsQueue.clear();
    } else {
      this.eventsQueue.push(() => callback.apply(this, args));
    }
  }

  get integrations() {
    return { integrations: this.cookiesConsentManager.getSegmentIntegrations() };
  }
}

declare module '@ember/service' {
  interface Registry {
    segment: Segment;
  }
}
