import {
  AeadId,
  CipherSuite,
  KdfId,
  KemId,
  type KemInterface,
  type RecipientContext,
  type SenderContext,
} from 'hpke-js';

interface DecryptParams {
  enc: ArrayBuffer;
  encryptedData: ArrayBuffer;
  plainTextAad: string;
  plainTextInfo: string;
  recipientKey: CryptoKey;
  senderPublicKey: CryptoKey;
  suite: CipherSuite;
}

interface EncryptionData {
  enc: ArrayBuffer;
  encryptedData: ArrayBuffer;
}

interface EncryptParams {
  data: string;
  plainTextAad: string;
  plainTextInfo: string;
  recipientPublicKey: CryptoKey;
  senderKey: CryptoKeyPair;
  suite: CipherSuite;
}

export function arrayBufferToB64(input: Uint8Array): string {
  return btoa(String.fromCodePoint(...new Uint8Array(input)));
}

export function b64ToArrayBuffer(input: string): Uint8Array {
  return Uint8Array.from(atob(input), (b: string) => b.charCodeAt(0));
}

export async function decrypt({
  enc,
  encryptedData,
  plainTextAad,
  plainTextInfo,
  recipientKey,
  senderPublicKey,
  suite,
}: DecryptParams): Promise<string> {
  let rc: RecipientContext = await suite.createRecipientContext({
    enc,
    info: new TextEncoder().encode(plainTextInfo),
    recipientKey,
    senderPublicKey,
  });

  let aad = new TextEncoder().encode(plainTextAad);
  let decryptedData = await rc.open(encryptedData, aad);

  return new TextDecoder().decode(decryptedData);
}

export async function deserializeB64PublicKey(
  b64Pk: string,
  kem: KemInterface
): Promise<CryptoKey> {
  return await kem.deserializePublicKey(b64ToArrayBuffer(b64Pk));
}

export async function encrypt({
  data,
  plainTextAad,
  plainTextInfo,
  recipientPublicKey,
  senderKey,
  suite,
}: EncryptParams): Promise<EncryptionData> {
  let sc: SenderContext = await suite.createSenderContext({
    info: new TextEncoder().encode(plainTextInfo),
    recipientPublicKey,
    senderKey,
  });

  let aad: ArrayBuffer = new TextEncoder().encode(plainTextAad);
  let encryptedData: ArrayBuffer = await sc.seal(new TextEncoder().encode(data), aad);

  return { enc: sc.enc, encryptedData };
}

export function initCipherSuite(): CipherSuite {
  return new CipherSuite({
    kem: KemId.DhkemP384HkdfSha384,
    kdf: KdfId.HkdfSha384,
    aead: AeadId.Aes256Gcm,
  });
}

export async function serializePublicKeyToB64(
  publicKey: CryptoKey,
  kem: KemInterface
): Promise<string> {
  let serializedPkR: ArrayBuffer = await kem.serializePublicKey(publicKey);
  return arrayBufferToB64(new Uint8Array(serializedPkR));
}
