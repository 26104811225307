/**
 * Determines if a user is of interest by checking one of its beneficiaries.
 * Based on their BIC (Bank Identifier Code), we can detect if the user corresponds to an account
 * on our outgoing international transfers provider.
 *
 * @param {Object} param - An object (e.g. beneficiary entity) that contains the BIC to be checked.
 * @param {string | null} param.bic - The BIC to evaluate. Can be undefined, a string or null.
 * @returns {boolean} Returns true if the BIC starts with "TRWI", otherwise returns false.
 */
export function isUserOfInterest({ bic }: { bic?: string | null }): boolean {
  return bic?.startsWith('TRWI') ?? false;
}
