import ApplicationSerializer from 'qonto/serializers/application';

export default ApplicationSerializer.extend({
  payloadKeyFromModelName() {
    return 'user';
  },

  modelNameFromPayloadKey() {
    return 'users/password';
  },
});
