import { buildValidations, validator } from 'ember-cp-validations';

const createPayLaterTransferValidations = (
  minPayLaterAmount,
  availableCreditAmount,
  getValidationMessage
) => {
  return buildValidations({
    amount: [
      validator('ds-error'),
      validator('presence', {
        presence: true,
        messageKey: 'validations.errors.transfer.presence',
      }),
      validator('number', {
        allowString: true,
        gte: minPayLaterAmount.value,
        lte: availableCreditAmount.value,
        message: getValidationMessage(minPayLaterAmount, availableCreditAmount),
      }),
    ],
    reference: [
      validator('ds-error'),
      validator('presence', {
        presence: true,
        messageKey: 'transfers.errors.reference-required',
      }),
      validator('length', {
        max: 135,
      }),
    ],
  });
};

export default createPayLaterTransferValidations;
