import { EmbeddedRecordsMixin } from '@ember-data/serializer/rest';
import { camelize } from '@ember/string';

import dayjs from 'dayjs';

import ApplicationSerializer from './application';

export default class F24OrderSerializer extends ApplicationSerializer.extend(EmbeddedRecordsMixin) {
  attrs = {
    payer: { embedded: 'always' },
    taxes: { embedded: 'always' },
    payerAgent: { embedded: 'always' },
  };

  extractErrors(errors) {
    return errors?.map(error => {
      let pointer = error.source?.pointer;
      if (pointer) {
        pointer = pointer
          .replace(
            /^\/f24_order\/taxes\/other_institutions\/inail$/,
            '/f24_order/taxes/other_institutions/inail/tax_list'
          )
          .replace(/^\/f24_order\/taxes\/regioni$/, '/f24_order/taxes/regioni/tax_list')
          .replace(/^\/f24_order\/taxes\/inps$/, '/f24_order/taxes/inps/tax_list')
          .replace('/f24_order/', '')
          .replace('/regioni/tax_list/', '/regioni_tax_list/')
          .replace('/inps/tax_list/', '/inps_tax_list/')
          .replace('/other_institutions/inail/tax_list/', '/inail_tax_list/')
          .replace('/other_institutions/others/', '/others/');
        error.source.pointer = camelize(pointer);
      }
      return error;
    });
  }

  serialize(snapshot, options) {
    let json = super.serialize(snapshot, options);

    if (json.expiration_date) {
      let selectedDate = json.expiration_date;
      let dateWithoutTime = dayjs(selectedDate).tz('Europe/Rome').format('YYYY-MM-DD');
      json.expiration_date = dateWithoutTime;
    }

    let taxes = json.taxes;
    if (!taxes) {
      return json;
    }

    let newErarioTaxList = this._convertTaxObjectAmountsToString(taxes.erario);
    let newDeductionAmount = this._convertAmountToString(taxes.imu?.deduction_amount);
    let newImuTaxList = this._convertTaxObjectAmountsToString(taxes.imu);
    let newInpsTaxList = this._convertTaxObjectAmountsToString(taxes.inps);
    let newInailTaxList = this._convertTaxObjectAmountsToString(taxes.other_institutions.inail);
    let newOthersTaxList = this._convertTaxObjectAmountsToString(taxes.other_institutions.others);
    let newRegioniTaxList = this._convertTaxObjectAmountsToString(taxes.regioni);

    return {
      ...json,
      taxes: {
        erario: taxes.erario
          ? {
              ...taxes.erario,
              tax_list: newErarioTaxList,
            }
          : null,
        imu: taxes.imu
          ? {
              ...taxes.imu,
              deduction_amount: newDeductionAmount,
              tax_list: newImuTaxList,
            }
          : null,
        inps:
          taxes.inps.tax_list.length > 0
            ? {
                ...taxes.inps,
                tax_list: newInpsTaxList,
              }
            : null,
        other_institutions: {
          ...taxes.other_institutions,
          inail:
            taxes.other_institutions.inail.tax_list.length > 0
              ? {
                  ...taxes.other_institutions.inail,
                  tax_list: newInailTaxList,
                }
              : null,
          others: taxes.other_institutions.others
            ? {
                ...taxes.other_institutions.others,
                tax_list: newOthersTaxList,
              }
            : null,
        },
        regioni:
          taxes.regioni.tax_list.length > 0
            ? {
                ...taxes.regioni,
                tax_list: newRegioniTaxList,
              }
            : null,
      },
    };
  }

  _convertAmountToString(amount) {
    return amount?.toFixed(2) ?? null;
  }

  _convertTaxObjectAmountsToString(taxObject) {
    return taxObject?.tax_list.map(item => ({
      ...item,
      compensation_amount: this._convertAmountToString(item.compensation_amount),
      tax_amount: this._convertAmountToString(item.tax_amount),
    }));
  }
}
