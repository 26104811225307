import { buildValidations, validator } from 'ember-cp-validations';

const LabelValidations = buildValidations(
  {
    name: {
      validators: [
        validator('presence', {
          presence: true,
          ignoreBlank: true,
        }),
        validator('length', { min: 1, max: 20 }),
      ],
    },
  },
  {
    debounce: 100,
  }
);

export default LabelValidations;
