import { NRC_IBAN, NRC_NAMES } from 'qonto/constants/nrc';

function getNRCFullName(taxBeneficiaryName) {
  if (taxBeneficiaryName === NRC_NAMES.declarando) {
    return 'Declarando Asesores 3.0. S.L';
  }

  return taxBeneficiaryName;
}

function getNRCIban(taxBeneficiaryName) {
  if (taxBeneficiaryName === NRC_NAMES.contasult) {
    return NRC_IBAN.contasult;
  }

  if (taxBeneficiaryName === NRC_NAMES.declarando) {
    return NRC_IBAN.declarando;
  }
}

export { getNRCFullName, getNRCIban };
