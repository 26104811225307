import JSONAPISerializer from 'qonto/serializers/-json-api';

import extractErrors from './-standard-extract-errors';

export default class ClientHubSerializer extends JSONAPISerializer {
  extractErrors = extractErrors;

  modelNameFromPayloadKey(payloadKey) {
    if (payloadKey === 'clients') {
      return 'client-hub';
    }
    return super.modelNameFromPayloadKey(payloadKey);
  }

  payloadKeyFromModelName() {
    return 'clients';
  }

  serialize(snapshot, options) {
    let json = super.serialize(snapshot, options);

    let countryCode = json.data.attributes.billing_address.country_code;
    if (countryCode !== 'IT') {
      delete json.data.attributes.recipient_code;
      delete json.data.attributes.province_code;
    }

    let kind = json.data.attributes.kind;
    if (kind === 'individual') {
      delete json.data.attributes.vat_number;
    }

    let { first_name, last_name, name } = json.data.attributes;
    if (!name && first_name && last_name) {
      json.data.attributes.name = `${first_name} ${last_name}`;
    }

    return json;
  }
}
