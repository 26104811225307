export default function normalize(text = '') {
  if (typeof text !== 'string') {
    throw new TypeError(`Expected a string, got a ${typeof text}`);
  }

  return text
    .normalize('NFD')
    .replace(/\p{Diacritic}/gu, '')
    .toLowerCase();
}
