import ApplicationSerializer from 'qonto/serializers/application';

export default class SubscriptionProductSerializer extends ApplicationSerializer {
  modelNameFromPayloadKey() {
    return 'subscription-product';
  }

  normalizeResponse(store, primaryModelClass, payload, id, requestType) {
    if (payload.products) {
      let addons = [];
      payload.products = payload.products.map(product => {
        product.product_type = product.type;
        product.traits = payload.traits;
        delete product.type;
        if (product.addons) {
          product.addons_ids = product.addons?.map(addon => addon.id);
          addons.push(...product.addons);
        }
        return product;
      });

      delete payload.traits;
      payload.products.push(...addons);

      return super.normalizeResponse(
        store,
        primaryModelClass,
        { products: payload.products },
        id,
        requestType
      );
    } else {
      payload.product_type = payload.type;
      delete payload.type;
      let addons = [];
      if (payload.addons) {
        payload.addons_ids = payload.addons?.map(addon => addon.id);
        addons.push(...payload.addons);
      }

      return super.normalizeResponse(
        store,
        primaryModelClass,
        { product: payload, addons },
        id,
        requestType
      );
    }
  }
}
