/* eslint-disable require-await */
import Service, { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

import window from 'ember-window-mock';
import fetch from 'fetch';

import ENV from 'qonto/config/environment';
import { apiBaseURL } from 'qonto/constants/hosts';

export class CheckoutError extends Error {
  constructor({ url, status, responseJSON, responseText, headers, method }) {
    super(`failed to fetch ${url}`);
    this.name = 'CheckoutError';

    this.url = url;
    this.status = status;
    this.headers = headers;
    this.method = method;
    this.responseJSON = responseJSON;
    this.responseText = responseText;
  }
}

export default class PaymentService extends Service {
  @service networkManager;

  @tracked
  amount = null;
  @tracked
  provider = null;

  /**
   * Used as a state property for the current browser session
   * to prevent the redirection from the organization.show route
   * to the onboarding.card page if the onboarding.card has been skipped.
   */
  @tracked
  onboardingCardSkipped = false;

  async triggerPayment({ payload, successURLPath, failureURLPath, source }) {
    let apiUrl = `${apiBaseURL}/v4/topups/card_payments/request`;

    let path = window.location.href.split('/');
    path.pop();
    let url = path.join('/');

    return this.networkManager.request(apiUrl, {
      method: 'POST',
      data: JSON.stringify({
        card_payment: {
          ...payload,
          success_url: `${url}/${successURLPath}`,
          failure_url: `${url}/${failureURLPath}`,
        },
        ...(source ? { source } : {}), // Include source only if it's defined
      }),
    });
  }

  async requestCheckoutToken(payload) {
    let url = `${ENV.checkout.apiBaseUrl}/tokens`;
    let options = {
      method: 'POST',
      headers: {
        Authorization: ENV.checkout.publicKey,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    };

    let resp = await fetch(url, options);

    if (resp.ok) {
      return resp.json();
    } else {
      let text = await resp.text();
      let responseJSON;
      try {
        responseJSON = JSON.parse(text);
      } catch {
        responseJSON = {};
      }

      throw new CheckoutError({
        url,
        status: resp.status,
        headers: options.headers,
        method: options.method,
        responseJSON,
        responseText: text,
      });
    }
  }

  async handleGooglePayToken(paymentData) {
    let payload = {
      type: 'googlepay',
      token_data: JSON.parse(paymentData.paymentMethodData.tokenizationData.token),
    };

    return await this.requestCheckoutToken(payload);
  }
}
