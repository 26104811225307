/* eslint-disable @qonto/no-import-roles-constants */
import Service, { service } from '@ember/service';

import { didCancel, dropTask, task } from 'ember-concurrency';

import { apiBaseURL, requestsNamespace } from 'qonto/constants/hosts';
import { ROLES } from 'qonto/constants/membership';
import { REQUEST_TYPES, SAME_IBAN_LEGACY_ERROR_MESSAGES } from 'qonto/constants/requests';
import cloneProperties from 'qonto/utils/clone-properties';
import { ErrorInfo } from 'qonto/utils/error-info';
import { hasMFAError } from 'qonto/utils/mfa-error';
import {
  convertTransferRequestToTransfer,
  getQuickTransferApproveErrorMessage,
  getQuickTransferApproveSuccessMessage,
  getQuickTransferApproveWarningMessage,
} from 'qonto/utils/transfer-requests';

export default class RequestsManager extends Service {
  @service abilities;
  @service errors;
  @service toastFlashMessages;
  @service intl;
  @service modals;
  @service networkManager;
  @service organizationManager;
  @service sentry;
  @service store;
  @service sensitiveActions;
  @service segment;

  get isManager() {
    return this.organizationManager.membership.role === ROLES.MANAGER;
  }

  handleKycError(error) {
    if (error.errors?.[0]?.detail?.code === 'kyc_not_accepted') {
      let { kycSubmitted } = this.organizationManager.membership;

      if (!kycSubmitted) {
        return this.toastFlashMessages.toastError(
          this.intl.t('tasks.kyc.sensitive-actions.toast.kyc-unsubmitted')
        );
      } else {
        return this.toastFlashMessages.toastError(
          this.intl.t('tasks.kyc.sensitive-actions.toast.kyc-pending')
        );
      }
    }
  }

  getConfirmTaskByRequestType(requestType) {
    switch (requestType) {
      case REQUEST_TYPES.MULTI_TRANSFER:
        return this.confirmMultiTransferRequestTask;
      case REQUEST_TYPES.TRANSFER:
        return this.confirmSingleTransferRequestTask;
      case REQUEST_TYPES.EXPENSE_REPORT:
      case REQUEST_TYPES.MILEAGE:
        return this.rawConfirmReimbursementTask;
    }
  }

  confirmRequestTask = dropTask(async request => {
    if (!request.lastStep) return;
    let task = this.getConfirmTaskByRequestType(request.requestType);
    return await task?.perform(request);
  });

  confirmMultiTransferRequestTask = task(async multiTransferRequest => {
    let { id: multiTransferRequestId } = multiTransferRequest;
    let multiTransfer = this.store.peekRecord('multi-transfer', multiTransferRequestId);

    if (!multiTransfer) {
      let associatedTransferRequests = await this.store.query('request-multi-transfer-transfer', {
        includes: ['attachments'],
        request_multi_transfer_id: multiTransferRequestId,
        per_page: 200,
      });

      multiTransfer = this.store.createRecord('multi-transfer', {
        id: multiTransferRequestId,
        bankAccount: multiTransferRequest.bankAccount,
        organization: multiTransferRequest.organization,
        transfers: associatedTransferRequests.map(request => {
          return this.store.createRecord('bulk-transfer', {
            activityTag: request.activityTag,
            amount: request.amount,
            amountCurrency: request.amountCurrency,
            iban: request.beneficiaryIban,
            name: request.beneficiaryName,
            operationType: request.operationType,
            reference: request.reference,
            scheduledDate: request.scheduledDate,
          });
        }),
      });
    }

    return await multiTransfer.confirm();
  });

  confirmSingleTransferRequestTask = task(async singleTransferRequest => {
    let transfer = await convertTransferRequestToTransfer({
      dataStore: this.store,
      transferRequest: singleTransferRequest,
      bankAccount: this.organizationManager.currentAccount,
    });

    // `POST /transfers/confirm` endpoint does not support `scheduled_later` operation type (but only `scheduled`)
    transfer.operationType =
      transfer.operationType === 'scheduled_later' ? 'scheduled' : transfer.operationType;

    try {
      return await transfer.confirm();
    } catch (error) {
      if (error.status === 422 && error?.errors?.iban?.length) {
        let hasSEPAError = error.errors.iban.some(error => error.includes('SEPA'));
        if (hasSEPAError) {
          return {
            errors: ['iban_sepa_error'],
          };
        }
      }

      if (error.status === 422 && error?.errors?.length) {
        let hasSameIBANError = error.errors.some(
          error =>
            SAME_IBAN_LEGACY_ERROR_MESSAGES.includes(error.detail) &&
            error.source?.pointer === '/data/attributes/iban' &&
            error.title?.toLowerCase() === 'invalid attribute'
        );
        if (hasSameIBANError) {
          return {
            errors: ['same_iban_error'],
          };
        }
      }

      throw error;
    }
  });

  confirmTransferRequestTask = task(async request => {
    if (!request.lastStep) return;
    let confirmationResult;

    if (request.requestType === 'transfer') {
      confirmationResult = await this.confirmSingleTransferRequestTask.perform(request);
    }

    if (request.requestType === 'multi_transfer') {
      let { bankAccount, totalTransfersAmount } = request;
      if (bankAccount.authorizedBalance < totalTransfersAmount) {
        let insufficientFundsMessage = this.isManager
          ? this.intl.t('transfers.errors.manager.multi_insufficient_funds')
          : this.intl.t('transfers.errors.multi_insufficient_funds');
        return insufficientFundsMessage;
      }

      confirmationResult = await this.confirmMultiTransferRequestTask.perform(request);
    }

    if (confirmationResult) {
      return this.processTransferConfirmationResult(confirmationResult);
    }
  });

  processTransferConfirmationResult(confirmationResult) {
    let { errors, spendLimits, spend_limits, warnings } = confirmationResult;

    if (errors?.length) {
      return getQuickTransferApproveErrorMessage(this.intl, errors, {
        hasMultipleActiveAccounts:
          this.organizationManager.organization.hasMultipleActiveCurrentRemuneratedAccounts,
      });
    }

    if (warnings?.length) {
      let warningMessage = getQuickTransferApproveWarningMessage(this.intl, warnings, {
        hasMultipleActiveAccounts:
          this.organizationManager.organization.hasMultipleActiveCurrentRemuneratedAccounts,
        spendLimits: spendLimits || spend_limits,
      });

      if (warningMessage) {
        return warningMessage;
      }
    }
  }

  quickApproveTransferRequestTask = task(async request => {
    try {
      let confirmationMessage = await this.confirmTransferRequestTask.perform(request);

      if (confirmationMessage) {
        this.toastFlashMessages.toastError(confirmationMessage);
        return;
      }
    } catch (error) {
      this.errors.handleError(error);
      return;
    }

    await this.sensitiveActions.runTaskNoDrop.perform(this.approveTransferRequestTask, request);
  });

  approveTransferRequestTask = task(async transferRequest => {
    try {
      let successMessage = getQuickTransferApproveSuccessMessage(this.intl, transferRequest);
      await transferRequest.approveRequest();
      this.toastFlashMessages.toastSuccess(successMessage);
    } catch (error) {
      if (hasMFAError(error?.errors)) {
        throw error;
      }

      if (error.errors?.[0]?.detail?.code === 'request_not_pending') {
        return this.toastFlashMessages.toastError(
          this.intl.t('transfers.toasts.quick-approve.warning.request-expired')
        );
      }

      this.handleKycError(error);

      this.errors.handleError(error);
    }
  });

  fetchAllocatedBudgetTask = dropTask(async request => {
    if (
      !request ||
      this.abilities.cannot('read budget') ||
      [REQUEST_TYPES.MILEAGE].includes(request.requestType) ||
      !request.pending
    ) {
      return;
    }

    let shouldRequestPeriodAmounts = false;
    let initiatorId = null;
    let scheduledDate = new Date();

    switch (request.requestType) {
      case REQUEST_TYPES.TRANSFER: {
        shouldRequestPeriodAmounts = this.abilities.can('review transfer request');
        initiatorId = request.initiator.get('id');
        scheduledDate = request.scheduledDate;
        break;
      }
      case REQUEST_TYPES.FLASH_CARD:
      case REQUEST_TYPES.VIRTUAL_CARD: {
        shouldRequestPeriodAmounts = this.abilities.can('review card request');
        initiatorId = request.holder.get('id');
        break;
      }
      case REQUEST_TYPES.EXPENSE_REPORT: {
        shouldRequestPeriodAmounts = this.abilities.can('review expense report request');
        initiatorId = request.initiator.get('id');
        break;
      }
    }

    shouldRequestPeriodAmounts =
      shouldRequestPeriodAmounts && this.organizationManager.membership.id !== initiatorId;

    try {
      let results = await this.store.adapterFor('budget').search({
        initiatorId,
        scheduledDate,
        includes: shouldRequestPeriodAmounts ? ['period_amounts'] : [],
      });
      return results[0];
    } catch (error) {
      if (didCancel(error)) return;
      this.errors.handleError(error);
    }
  });

  quickDeclineCardRequestTask = task(async request => {
    await this.modals.open('request/sidebar/modals/decline-request', {
      request,
      confirmTask: this.quickDeclineCard,
    });
  });

  quickDeclineCard = dropTask(async (closeDeclineModal, request) => {
    let { initiator } = request;

    try {
      await request.declineRequest();
      this.toastFlashMessages.toastInfo(
        this.intl.t('cards.info.request-rejected', {
          requesterName: initiator.get('fullName'),
        })
      );

      this.segment.track('request_declined', {
        request_type: this.args.request.requestType,
        request_id: this.args.request.id,
        role: this.organizationManager.membership.role,
      });
    } catch (error) {
      if (error.errors?.[0]?.detail?.code === 'request_not_pending') {
        return this.toastFlashMessages.toastError(
          this.intl.t('cards.requests.warnings.request-expired')
        );
      } else {
        this.errors.handleError(error);
      }
    } finally {
      closeDeclineModal();
    }
  });

  quickRejectTransferRequestTask = task(async request => {
    let isOwnRequest = request.initiator.get('id') === this.organizationManager.membership.id;

    if (isOwnRequest) {
      await this.modals.open('request/transfer/modals/cancel', { request });
    } else {
      await this.modals.open('request/sidebar/modals/decline-request', {
        request,
        confirmTask: this.quickDeclineTransferRequestTask,
      });
    }
  });

  quickDeclineTransferRequestTask = task(async (closeDeclineModal, request) => {
    try {
      await request.declineRequest();
      this.toastFlashMessages.toastInfo(this.intl.t('transfers.toasts.info.quick-reject-request'));
    } catch (error) {
      if (error.errors?.[0]?.detail?.code === 'request_not_pending') {
        return this.toastFlashMessages.toastError(
          this.intl.t('transfers.toasts.quick-approve.warning.request-expired')
        );
      }

      this.handleKycError(error);

      this.errors.handleError(error);
    } finally {
      closeDeclineModal();
    }
  });

  quickRejectReimbursementRequestTask = task(async request => {
    let isOwnRequest = request.initiator.get('id') === this.organizationManager.membership.id;

    if (isOwnRequest) {
      await this.modals.open('reimbursements/modals/cancel', {
        request,
      });
    } else {
      await this.modals.open('request/sidebar/modals/decline-request', {
        request,
        confirmTask: this.quickDeclineReimbursementRequestTask,
      });
    }
  });

  quickDeclineReimbursementRequestTask = task(async (closeDeclineModal, request) => {
    try {
      await request.declineRequest();
      this.toastFlashMessages.toastInfo(this.intl.t('reimbursements.toast.reject'));
    } catch (error) {
      this.handleKycError(error);
      this.errors.handleError(error);
    } finally {
      closeDeclineModal();
    }
  });

  confirmReimbursementTask = task(async request => {
    if (!request.lastStep) return;
    try {
      let response = await this.rawConfirmReimbursementTask.perform(request);
      return this.processTransferConfirmationResult(response);
    } catch (error) {
      return this.processTransferConfirmationResult(error);
    }
  });

  rawConfirmReimbursementTask = task(async request => {
    let bankAccount = this.organizationManager.currentAccount;
    let transfer = this.store.createRecord('transfer', { bankAccount });

    await cloneProperties(request, transfer);
    transfer.email = request.initiator.get('email');
    transfer.amount = request.amount.value;
    transfer.currency = request.amount.currency;

    let transferNamespace = this.store.adapterFor('transfer').namespace;
    let url = `${apiBaseURL}/${transferNamespace}/transfers/confirm`;
    // Patch : End point confirm not support operation type's scheduled_later only scheduled
    let data = transfer.serialize();
    data.operation_type =
      data.operation_type === 'scheduled_later' ? 'scheduled' : data.operationType;
    data.name = request.initiator.get('fullName');
    data.activity_tag = 'other_service';
    data = JSON.stringify({ transfer: data });

    try {
      return this.networkManager.request(url, {
        method: 'POST',
        data,
      });
    } catch (error) {
      if (error.status === 422 && error?.errors?.iban?.length) {
        let hasSEPAError = error.errors.iban.some(error => error.includes('SEPA'));
        if (hasSEPAError) {
          return {
            errors: ['iban_sepa_error'],
          };
        }
      }

      throw error;
    }
  });

  approveReimbursementTask = task(async request => {
    try {
      let successMessage = request.lastStep
        ? this.intl.t('reimbursements.toast.success', {
            amount: this.intl.formatMoney(request.amount.value, {
              currency: request.amount.currency,
            }),
            requester: request.get('initiator').get('fullName'),
          })
        : this.intl.t('approval-workflows.requests.approved.toast.success');
      await request.approveRequest();
      this.toastFlashMessages.toastSuccess(successMessage);
    } catch (error) {
      if (hasMFAError(error?.errors)) {
        throw error;
      }

      this.handleKycError(error);

      this.errors.handleError(error);
    }
  });

  quickApproveReimbursementRequestTask = task(async request => {
    try {
      let confirmationResult = await this.confirmReimbursementTask.perform(request);

      if (confirmationResult) {
        this.toastFlashMessages.toastError(confirmationResult);
        return;
      }
    } catch (error) {
      this.errors.handleError(error);
      return;
    }

    await this.sensitiveActions.runTaskNoDrop.perform(this.approveReimbursementTask, request);
  });

  bulkApproveTask = task(async requests => {
    try {
      let response = await this.networkManager.request(
        `${apiBaseURL}/${requestsNamespace}/requests/bulk/approve`,
        { method: 'POST', data: { request_ids: requests.map(request => request.requestId) } }
      );

      requests.forEach(request => this.store.unloadRecord(request));

      let message =
        response.warnings.length > 0
          ? this.intl.t('requests.reimbursements.bulk.toasts.reimbursements-approved-partial', {
              count: response.warnings.length,
            })
          : this.intl.t('requests.reimbursements.bulk.toasts.requests-approved', {
              count: requests.length,
            });

      this.toastFlashMessages.toastInfo(message);
    } catch (error) {
      if (hasMFAError(error?.errors)) {
        throw error;
      }

      this.handleKycError(error);
      this.errors.handleError(error);
    }
  });

  bulkPayTask = task(async (requests, bankAccount) => {
    try {
      let { id: bankAccountId } = bankAccount;

      let response = await this.networkManager.request(
        `${apiBaseURL}/${requestsNamespace}/requests/bulk/pay`,
        {
          method: 'POST',
          data: {
            request_ids: requests.map(request => request.requestId),
            bank_account_id: bankAccountId,
          },
        }
      );

      requests.forEach(request => this.store.unloadRecord(request));

      let message =
        response.warnings.length > 0
          ? this.intl.t('requests.reimbursements.bulk.toasts.reimbursements-paid-partial', {
              count: response.warnings.length,
            })
          : this.intl.t('requests.reimbursements.bulk.toasts.reimbursements-paid', {
              count: requests.length,
            });

      this.toastFlashMessages.toastInfo(message);
    } catch (error) {
      if (hasMFAError(error?.errors)) {
        throw error;
      }

      this.bulkErrorHandler(error, requests);
    }
  });

  bulkErrorHandler = async (error, requests) => {
    let { errors } = error;

    let message;
    let {
      membership,
      organization: { hasMultipleActiveAccounts },
    } = this.organizationManager;
    let spendLimitsErrorKeys = ['insufficient_per_transfer_limits', 'insufficient_monthly_limits'];

    if (spendLimitsErrorKeys.includes(errors[0]?.code)) {
      await membership.getSpendLimits();
    }

    switch (errors[0]?.code) {
      case 'kyc_not_accepted':
        message = this.intl.t('requests.reimbursements.bulk.toasts.error.kyc');
        break;
      case 'insufficient_funds':
        message = this.intl.t('requests.reimbursements.bulk.toasts.error.insufficient-funds', {
          count: requests?.length,
        });
        if (hasMultipleActiveAccounts) {
          message = this.intl.t(
            'transfers.toasts.quick-approve.error.insufficient-funds-multiple-accounts'
          );
        }
        break;
      case 'insufficient_per_transfer_limits':
        message = this.intl.t('requests.transfers.sidebar.disclaimers.per-transfer-limit', {
          per_transfer_limit: membership.perTransferLimit.value,
        });
        break;
      case 'insufficient_monthly_limits':
        message = this.intl.t('requests.transfers.sidebar.disclaimers.monthly-transfer-limit', {
          balance_monthly_transfer_limit: this.intl.formatNumber(
            membership.monthlyTransferLimit.value - membership.currentMonthSpendings.value,
            { maximumFractionDigits: 2, minimumFractionDigits: 0 }
          ),
        });
        break;
      default:
        message = this.intl.t('toasts.errors.generic');
    }

    this.toastFlashMessages.toastError(message);
  };

  quickApproveCardRequestTask = dropTask(async request => {
    let { initiator } = request;
    let requesterName = initiator.get('fullName');

    if (initiator.get('revoked')) {
      return this.toastFlashMessages.toastError(
        this.intl.t('cards.requests.warnings.revoked', { requesterName })
      );
    }

    if (request.isExpired) {
      return this.toastFlashMessages.toastError(
        this.intl.t('cards.requests.warnings.card-expired', { requesterName })
      );
    }

    await this.sensitiveActions.runTask.perform(this.quickApproveCardRequest, request);
  });

  quickApproveCardRequest = task(async request => {
    let requesterName = request.initiator.get('fullName');

    try {
      await request.approveRequest();

      this.toastFlashMessages.toastSuccess(
        this.intl.t('toasts.card-requests.approved', { requesterName })
      );

      this.segment.track('request_approved', {
        request_type: request.requestType,
        request_id: request.id,
        role: this.organizationManager.membership.role,
      });
    } catch (error) {
      if (hasMFAError(error?.errors)) {
        throw error;
      }

      if (error.errors?.[0]?.detail?.code === 'request_not_pending') {
        return this.toastFlashMessages.toastError(
          this.intl.t('cards.requests.warnings.request-expired')
        );
      } else {
        this.errors.handleError(error);
      }
    }
  });

  approveMultiRequestDirectDebitCollectionTask = task(async request => {
    try {
      await request.approveRequest();

      this.toastFlashMessages.toastSuccess(
        this.intl.t('requests.sdd-collections.review.approve.success')
      );

      return 'success';
    } catch (error) {
      if (hasMFAError(error)) {
        throw error;
      }

      let errorCode = error.errors?.[0]?.detail?.code;

      if (errorCode === 'request_not_pending') {
        this.toastFlashMessages.toastError(
          this.intl.t('requests.sdd-collections.errors.already-reviewed')
        );
        return error;
      }

      if (errorCode === 'cid_mismatch') {
        this.toastFlashMessages.toastError(
          this.intl.t('sdd-collections.requests.errors.cid-mismatch')
        );
        return error;
      }

      if (errorCode === 'eligibility_lost') {
        this.toastFlashMessages.toastError(
          this.intl.t('sdd-collections.requests.errors.eligibility-lost')
        );
        return error;
      }

      if (errorCode === 'kyc_waiting') {
        this.toastFlashMessages.toastError(
          this.intl.t('sdd-collections.requests.errors.user-not-kyc')
        );
        return error;
      }

      this.toastFlashMessages.toastError(this.intl.t('toasts.errors.generic'));

      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }

      return error;
    }
  });

  rejectMultiRequestDirectDebitCollectionTask = task(async request => {
    try {
      await request.declineRequest();

      this.toastFlashMessages.toastInfo(
        this.intl.t('requests.sdd-collections.review.reject.success')
      );

      return 'success';
    } catch (error) {
      let errorCode = error.errors?.[0]?.detail?.code;

      if (errorCode === 'request_not_pending') {
        this.toastFlashMessages.toastError(
          this.intl.t('requests.sdd-collections.errors.already-reviewed')
        );
        return error;
      }

      if (errorCode === 'kyc_waiting') {
        this.toastFlashMessages.toastError(
          this.intl.t('sdd-collections.requests.errors.user-not-kyc')
        );
        return error;
      }

      this.toastFlashMessages.toastError(this.intl.t('toasts.errors.generic'));

      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }

      return error;
    }
  });
}
