import { buildValidations, validator } from 'ember-cp-validations';

const StakeholderValidations = buildValidations(
  {
    firstName: [validator('ds-error'), validator('presence', true)],
    lastName: [validator('ds-error'), validator('presence', true)],
    email: [
      validator('presence', true),
      validator('format', {
        type: 'email',
        minTldLength: 2,
      }),
    ],
    nationality: [validator('ds-error'), validator('presence', true)],
    birthdate: [
      validator('ds-error'),
      validator('presence', true),
      validator('date', {
        after: '1/1/1900',
        before: new Date(),
        format: 'DD/MM/YYYY',
      }),
    ],
    birthCity: [validator('ds-error'), validator('presence', true)],
    birthZipcode: [validator('ds-error'), validator('presence', true)],
    birthCountry: [validator('ds-error'), validator('presence', true)],
    capitalAmount: [
      validator('ds-error'),
      validator('presence', {
        presence: true,
        messageKey: 'validations.errors.stakeholder.capital-amount',
      }),
      validator('number', {
        messageKey: 'validations.errors.stakeholder.capital-amount',
        lazy: true,
        allowString: true,
        gte: 1,
      }),
    ],
    'address.country': [validator('ds-error'), validator('presence', true)],
    proofOfIdentity: {
      validators: [
        validator('ds-error'),
        validator('presence', {
          presence: true,
          messageKey: 'validations.errors.upload_id_error',
          value({ proofOfIdentity }) {
            // There should be at least one file in the POI document
            return proofOfIdentity?.files?.[0];
          },
        }),
      ],
    },
    proofOfIdentitySubtype: {
      validators: [validator('ds-error'), validator('presence', true)],
    },
  },
  {
    debounce: 100,
  }
);

export default StakeholderValidations;
