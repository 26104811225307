import {
  extractBeneficiaryWarnings,
  extractConfirmationErrors,
  extractConfirmationWarnings,
  extractSpendLimitsWarning,
} from 'qonto/utils/extract-confirmation-response';

export default function parseConfirmResponse(response, intl, options) {
  let beneficiaryWarning = extractBeneficiaryWarnings(response);
  let confirmWarnings = extractConfirmationWarnings(intl, 'transfer', response, options);
  let confirmErrors = extractConfirmationErrors(response);
  let spendLimitsWarning = extractSpendLimitsWarning(response);
  let spendLimits = response.spend_limits || response.spendLimits;

  return {
    confirmWarnings,
    confirmErrors,
    beneficiaryWarning,
    spendLimitsWarning,
    spendLimits,
  };
}
