import { getSupportedUnits } from './receivable-invoicing';

export const mapInvoicingItemToProduct = item => {
  let product = {};
  item.eachAttribute(name => {
    if (name === 'type') {
      product[name] = getServiceTypeFromUnitCode(item.unit);
    } else if (name === 'unitPrice') {
      product[name] = { value: item.unitPrice, currency: item.currency };
    } else {
      product[name] = item[name];
    }
  });
  return product;
};

export const getServiceTypeFromUnitCode = unitCode => {
  let intlNoop = { t: () => {} };
  let serviceUnitCodes = getSupportedUnits(intlNoop)
    .filter(({ isService }) => isService)
    .map(({ unitCode }) => unitCode);

  return serviceUnitCodes.includes(unitCode) ? 'service' : 'good';
};
