import { EmbeddedRecordsMixin } from '@ember-data/serializer/rest';

import ApplicationSerializer from 'qonto/serializers/application';
import {
  removeNationalityFromArray,
  serializeNationalities,
} from 'qonto/utils/update-nationalities';

export default class StakeholderSerializer extends ApplicationSerializer.extend(
  EmbeddedRecordsMixin
) {
  attrs = {
    address: { embedded: 'always' },
    bankAccount: { embedded: 'always' },
  };

  normalize(model, hash, prop) {
    // Handle the nationality and nationalities logic by ensuring
    // that nationality is removed from nationalities for the view layer.
    let nationality = hash.nationality;
    let nationalities = hash.nationalities || [];

    if (nationality && nationalities.includes(nationality)) {
      hash.nationalities = removeNationalityFromArray(
        nationalities,
        nationalities.indexOf(nationality)
      );
    }

    // the API returns `bank_account: {}` if there are no deposit accounts
    // but to Ember Data that means: a relationship to a resource without an ID
    // which is invalid. if we detect an empty object for `bank_account` we
    // set it to `null` which tells Ember Data that there is no `bank_account`
    // relationship for this `stakeholder` resource.
    if (typeof hash.bank_account === 'object' && hash.bank_account !== null) {
      let keys = Object.keys(hash.bank_account);
      let isEmpty = keys.length === 0;
      if (isEmpty) {
        hash.bank_account = null;
      }
    }

    return super.normalize(model, hash, prop);
  }

  serialize(snapshot) {
    let json = super.serialize(...arguments);

    // Handle adding nationality back to nationalities before sending to the server.
    let nationality = snapshot.attr('nationality');
    let nationalities = snapshot.attr('nationalities');

    json.nationalities = serializeNationalities(nationalities, nationality);

    return json;
  }
}
