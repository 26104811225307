import { buildValidations, validator } from 'ember-cp-validations';

import { DEBOUNCE_MS } from 'qonto/constants/timers';

const MandateValidations = buildValidations(
  {
    creditorName: [validator('ds-error'), validator('presence', true)],

    umr: [
      validator('ds-error'),
      validator('presence', true),
      validator('length', {
        max: 35,
        getMessageFor() {
          return this.intl.t('validations.errors.tooLong', { max: 35 });
        },
      }),
      validator('no-empty-space', {
        messageKey: 'validations.errors.b2b_mandate_ref_spaces_forbidden',
      }),
    ],

    activityTag: [validator('ds-error'), validator('presence', true)],

    creditorIdentifier: [
      validator('ds-error'),
      validator('presence', true),
      validator('length', {
        min: 8,
        getMessageFor() {
          return this.intl.t('validations.errors.tooShort', {
            min: 8,
          });
        },
      }),
      validator('length', {
        max: 35,
        getMessageFor() {
          return this.intl.t('validations.errors.tooShort', {
            max: 35,
          });
        },
      }),
      validator('no-empty-space', {
        messageKey: 'validations.errors.b2b_creditor_id_spaces_forbidden',
      }),
    ],

    recurrent: [validator('ds-error'), validator('presence', true)],

    attachments: {
      validators: [
        validator('ds-error'),
        validator('presence', {
          presence: true,
          messageKey: 'validations.errors.mandates.uploaded-attachment',
        }),
      ],
    },
  },
  {
    debounce: DEBOUNCE_MS,
  }
);

export default MandateValidations;
