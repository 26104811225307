import Service, { service } from '@ember/service';

export default class LoginPerformanceMetrics extends Service {
  @service segment;
  @service organizationManager;

  startTime = null;
  hasTrackedLoginPerformance = false;

  trackLoginPerformance({ destination = null } = {}) {
    if (this.hasTrackedLoginPerformance) {
      return;
    }

    this.segment.track('login_performance', {
      time_to_login: (Date.now() - this.startTime) / 1000,
      number_of_organizations: this.organizationManager.organizations.length,
      ...(destination ? { destination } : {}),
    });

    this.hasTrackedLoginPerformance = true;
  }
}
