import { buildValidations, validator } from 'ember-cp-validations';

const GeneratedLogoValidations = buildValidations({
  userInput: {
    validators: [
      validator('ds-error'),
      validator('presence', {
        presence: true,
        messageKey: 'generated-logo.modal.details.errors.required',
      }),
    ],
  },
});

export default GeneratedLogoValidations;
