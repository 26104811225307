import { service } from '@ember/service';
import { isEmpty } from '@ember/utils';

import BaseValidator from 'ember-cp-validations/validators/base';

const Amount = BaseValidator.extend({
  intl: service(),

  validate(amount, { messageKey = 'validations.errors.amount' } = {}) {
    if (amount) {
      if (isEmpty(amount.value) || isEmpty(amount.currency) || amount.currency.length !== 3) {
        return this.intl.t(messageKey);
      }
    }
    return true;
  },
});

export default Amount;
