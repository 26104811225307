import { buildValidations, validator } from 'ember-cp-validations';

import { DEBOUNCE_MS } from 'qonto/constants/timers';

const ReferralsValidations = buildValidations(
  {
    code: [validator('ds-error'), validator('presence', true)],
    status: [validator('ds-error'), validator('presence', true)],
    email: [
      validator('ds-error'),
      validator('presence', true),
      validator('format', {
        type: 'email',
        minTldLength: 2,
      }),
    ],
    registeredAt: [
      validator('ds-error'),
      validator('date', {
        after: '1/1/1900',
        before: new Date(),
        format: 'DD/MM/YYYY',
      }),
      validator('presence', true),
    ],
    rewardedAt: [
      validator('ds-error'),
      validator('date', {
        after: '1/1/1900',
        before: new Date(),
        format: 'DD/MM/YYYY',
      }),
      validator('presence', true),
    ],
    createdAt: [
      validator('ds-error'),
      validator('date', {
        after: '1/1/1900',
        before: new Date(),
        format: 'DD/MM/YYYY',
      }),
      validator('presence', true),
    ],
  },
  {
    debounce: DEBOUNCE_MS,
  }
);

export default ReferralsValidations;
