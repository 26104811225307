/* eslint-disable ember/require-computed-property-dependencies */
import { computed } from '@ember/object';
import { not } from '@ember/object/computed';

import { buildValidations, validator } from 'ember-cp-validations';

import { ACCOUNT_TYPES } from 'qonto/constants/beneficiaries';

const BeneficiaryValidations = buildValidations({
  activityTag: [
    validator('ds-error'),
    validator('presence', {
      presence: true,
      messageKey: 'validations.errors.blank',
    }),
  ],
  email: [
    validator('ds-error'),
    validator('format', {
      type: 'email',
      disabled: computed('model.email', function () {
        return !this.model.email || !this.model.email.length;
      }),
      minTldLength: 2,
      messageKey: 'validations.errors.email',
    }),
  ],
  iban: [
    validator('ds-error'),
    validator('presence', {
      presence: true,
      disabled: computed('model.{accountType,creditBankAccountId}', function () {
        return (
          this.model.accountType !== ACCOUNT_TYPES.IBAN || Boolean(this.model.creditBankAccountId)
        );
      }),
      messageKey: 'validations.errors.blank',
    }),
  ],
  name: [
    validator('ds-error'),
    validator('presence', {
      presence: true,
      messageKey: 'validations.errors.blank',
    }),
  ],
  accountNumber: [
    validator('ds-error'),
    validator('presence', {
      presence: true,
      disabled: computed('model.accountType', function () {
        return ![ACCOUNT_TYPES.ABA, ACCOUNT_TYPES.BIC_SWIFT].includes(this.model.accountType);
      }),
      messageKey: 'validations.errors.blank',
    }),
  ],
  bic: [
    validator('ds-error'),
    validator('presence', {
      presence: true,
      disabled: computed('model.accountType', function () {
        return this.model.accountType !== ACCOUNT_TYPES.BIC_SWIFT;
      }),
      messageKey: 'validations.errors.blank',
    }),
  ],
  routingNumber: [
    validator('ds-error'),
    validator('presence', {
      presence: true,
      disabled: computed('model.accountType', function () {
        return this.model.accountType !== ACCOUNT_TYPES.ABA;
      }),
      messageKey: 'validations.errors.blank',
    }),
  ],

  'address.firstLine': [
    validator('ds-error'),
    validator('presence', {
      presence: true,
      disabled: not('model.isAddressRequired'),
      messageKey: 'validations.errors.beneficiary.address-required',
    }),
  ],

  'address.postCode': [
    validator('ds-error'),
    validator('presence', {
      presence: true,
      disabled: not('model.isAddressRequired'),
      messageKey: 'validations.errors.beneficiary.zip-code-required',
    }),
  ],
  'address.city': [
    validator('ds-error'),
    validator('presence', {
      presence: true,
      disabled: not('model.isAddressRequired'),
      messageKey: 'validations.errors.beneficiary.city-required',
    }),
  ],
});

export default BeneficiaryValidations;
