import ApplicationSerializer from './application';

export default class PeriodComputedAmountsSerializer extends ApplicationSerializer {
  /**
    turn
    { period_id: string, amount_settled: { value: string, currency: string }}

    into active-model-adapter shaped payload
    { period_computed_amounts : { id: string, period_id: string, amount_settled: { value: string, currency: string }}}
  **/
  normalizeResponse(store, primaryModelClass, payload, id, requestType) {
    payload = {
      period_computed_amounts: {
        id,
        ...payload.period_amounts,
      },
    };
    return super.normalizeResponse(store, primaryModelClass, payload, id, requestType);
  }
}
