export function copyAttributes(from, to) {
  let setAttribute = name => to.set(name, from.get(name));
  from.eachAttribute(setAttribute);
}

export function copyRelationships(from, to) {
  return Promise.all(
    Array.from(to.constructor.relationshipsByName.keys()).map(async name => {
      // ensure async relationship resolved before assigning to model clone
      let fromRel = await from.get(name);

      // Ember Data 3.28 started throwing errors when assigning a
      // `PromiseManyArray` directly to a `hasMany` relationship.
      // If we detect a `hasMany` relationship we use the `toArray()` method
      // to first convert it to a raw array before assigning it to
      // the relationship.
      if (fromRel?.toArray) {
        fromRel = [...fromRel];
      }
      return to.set(name, fromRel);
    })
  );
}

export default async function cloneProperties(from, to) {
  copyAttributes(from, to);
  await copyRelationships(from, to);
}
