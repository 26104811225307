export const getVehicleTypes = ({ intl, legalCountry }) => {
  let vehiclesTypes = {
    FR: {
      car: {
        label: intl.t('requests.mileage.steps.vehicle-details.type.car'),
        powerOptions: [
          {
            label: intl.t('requests.mileage.steps.vehicle-details.fiscal-power.lte-three'),
            value: 'lte_three_HP',
          },
          {
            label: intl.t('requests.mileage.steps.vehicle-details.fiscal-power.four'),
            value: 'four_HP',
          },
          {
            label: intl.t('requests.mileage.steps.vehicle-details.fiscal-power.five'),
            value: 'five_HP',
          },
          {
            label: intl.t('requests.mileage.steps.vehicle-details.fiscal-power.six'),
            value: 'six_HP',
          },
          {
            label: intl.t('requests.mileage.steps.vehicle-details.fiscal-power.gte-seven'),
            value: 'gte_seven_HP',
          },
        ],
      },
      moto: {
        label: intl.t('requests.mileage.steps.vehicle-details.type.motorbike'),
        powerOptions: [
          {
            label: intl.t('requests.mileage.steps.vehicle-details.fiscal-power.one-or-two'),
            value: 'one_to_two_HP',
          },
          {
            label: intl.t('requests.mileage.steps.vehicle-details.fiscal-power.three-to-five'),
            value: 'three_to_five_HP',
          },
          {
            label: intl.t('requests.mileage.steps.vehicle-details.fiscal-power.gte-five'),
            value: 'gt_five_HP',
          },
        ],
      },
      scooter: {
        label: intl.t('requests.mileage.steps.vehicle-details.type.scooter'),
      },
    },
    DE: {
      car: { label: intl.t('requests.mileage.steps.vehicle-details.type.car') },
      two_wheeler: { label: intl.t('requests.mileage.steps.vehicle-details.type.two-wheeler') },
    },
  };
  return vehiclesTypes[legalCountry] ?? {};
};
