import { service } from '@ember/service';
import { buildWaiter } from '@ember/test-waiters';

import { enqueueTask } from 'ember-concurrency';

import { apiBaseURL } from 'qonto/constants/hosts';
import { ATMA_WEBSOCKET_TIMEOUT_MS } from 'qonto/constants/receivable-invoice';
import BaseInvoicesUploadManager from 'qonto/services/invoices-upload-manager';
import { ErrorInfo } from 'qonto/utils/error-info';
import pushPayload from 'qonto/utils/store-push-payload';

let waiter = buildWaiter('receivable-invoices-upload-manager');
const QUEUE_NAME = 'receivable-invoices';
const IMPORT_WEBSOCKET = 'receivable_invoices.import_processed';

export default class ReceivableInvoicesUploadManager extends BaseInvoicesUploadManager {
  @service notifierManager;

  uploadUrl = `${apiBaseURL}/v4/receivable_invoices/invoices/import`;
  queueName = QUEUE_NAME;

  constructor() {
    super(...arguments, {
      queueName: QUEUE_NAME,
    });

    this.notifierManager.on(IMPORT_WEBSOCKET, this, '_updateStateAndRefresh');
  }

  willDestroy() {
    this.notifierManager.off(IMPORT_WEBSOCKET, this, '_updateStateAndRefresh');
  }

  uploadTask = enqueueTask({ maxConcurrency: 3 }, async fileUploadState => {
    let token = waiter.beginAsync();
    let file = fileUploadState.uploadFile;
    try {
      let body = new FormData();
      body.append('receivable_invoices[][file]', file);

      let newFileResponse = await file.upload(this.uploadUrl, {
        withCredentials: true,
        fileKey: 'receivable_invoices[][file]',
        source: this.queueName.replace(/-/g, '_'),
        headers: {
          'X-Qonto-Organization-ID': this.organizationManager.organization.id,
        },
      });
      let payload = await newFileResponse.json();
      let { errors, data } = payload;

      if (data?.length) {
        let receivableInvoices = pushPayload(this.store, 'receivable-invoice', {
          data,
        });
        if (receivableInvoices.length) {
          fileUploadState.startProcessing();
          fileUploadState.timeoutId = setTimeout(() => {
            fileUploadState.finishProcessing(
              this.intl.t('receivable-invoices.importing-modal.status.pending-tab')
            );
            this.onUploadFinished?.();
          }, ATMA_WEBSOCKET_TIMEOUT_MS);

          let receivableInvoice = receivableInvoices[0];
          fileUploadState.attachment = await this.store.findRecord(
            'attachment',
            receivableInvoice.belongsTo('attachment').id()
          );
          // We need the invoice id to link to the details page
          fileUploadState.invoiceId = receivableInvoice.id;
        }
      }

      if (errors?.length) {
        let translatedErrors = [];

        for (let error of errors) {
          // 'invalid' is an internal Server error, we only log other errors to Sentry.
          if (error.code !== 'invalid') {
            this.sentry.captureMessage(error.detail, { cft: 'invoices' });
          }

          // There is no support yet for the embedded error messages the server can send back, so we show a generic error.
          translatedErrors.push(this.intl.t('supplier-invoices.importing-modal.error.generic'));
        }

        fileUploadState.errors = [...fileUploadState.errors, ...translatedErrors];
      }
    } catch (error) {
      let errorInfo = ErrorInfo.for(error);

      if (errorInfo.shouldSendToSentry) {
        this.sentry.captureException(error, { cft: 'invoices' });
      }
      fileUploadState.errors = [this.intl.t('supplier-invoices.importing-modal.error.generic')];
      file.queue?.remove(file);
      this.onUploadErrors?.();
    } finally {
      waiter.endAsync(token);
    }
  });

  get importingPopoverVisible() {
    return Boolean(this.files.length);
  }

  _updateStateAndRefresh(event) {
    let fileUploadState = this.files.find(file => file.invoiceId === event.object_id);
    clearTimeout(fileUploadState.timeoutId);
    let statusText =
      event.object.status === 'paid'
        ? this.intl.t('receivable-invoices.importing-modal.status.complete-tab')
        : this.intl.t('receivable-invoices.importing-modal.status.pending-tab');

    fileUploadState.finishProcessing(statusText);

    this.onUploadFinished?.();
  }
}
