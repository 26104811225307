import { NotFoundError } from '@ember-data/adapter/error';

import { didCancel } from 'ember-concurrency';

/**
 * Ignore `TaskCancelation` errors and rethrow everything else.
 *
 * Example:
 *
 * ```js
 * this.updateTask.perform().catch(ignoreCancelation)
 * ```
 */
export function ignoreCancelation(error) {
  if (!didCancel(error)) {
    throw error;
  }
}

export function ignore404(error) {
  if (!(error instanceof NotFoundError)) {
    throw error;
  }
}

/**
 * returns wether an error is a TaskCancelation
 * (and so could be ignored)
 */
export const isTaskCancelation = didCancel;
