import { buildValidations, validator } from 'ember-cp-validations';

import { DEBOUNCE_MS } from 'qonto/constants/timers';

const RequestMultiTransferValidations = buildValidations(
  {
    note: [
      validator('ds-error'),
      validator('presence', {
        presence: true,
        messageKey: 'validations.errors.presence',
      }),
    ],
  },
  {
    debounce: DEBOUNCE_MS,
  }
);

export default RequestMultiTransferValidations;
