import ApplicationSerializer from './application';

export default class HubNotificationRuleSerializer extends ApplicationSerializer {
  modelNameFromPayloadKey() {
    return 'hub-notification-rule';
  }

  payloadKeyFromModelName() {
    return 'rule';
  }

  serialize(snapshot, options) {
    let json = super.serialize(snapshot, options);

    json.condition = {
      field: json.field,
      filter: json.filter,
      value: json.value,
    };

    json.target = {
      channel_id: json.channel_id,
      channel_name: json.channel_name,
    };

    delete json.field;
    delete json.filter;
    delete json.value;
    delete json.channel_id;
    delete json.channel_name;

    return json;
  }

  normalize(typeClass, payload, prop) {
    if (payload.condition) {
      payload.field = payload.condition.field;
      payload.filter = payload.condition.filter;
      payload.value = payload.condition.value;
    }

    if (payload.target) {
      payload.channel_id = payload.target.channel_id;
      payload.channel_name = payload.target.channel_name;
    }

    delete payload.condition;
    delete payload.target;

    return super.normalize(typeClass, payload, prop);
  }
}
