import Service from '@ember/service';
import { tracked } from '@glimmer/tracking';

import window from 'ember-window-mock';

/**
 * Service encapsulating all the logic for the Webview communication
 * @class WebviewManager
 */
export default class WebviewManager extends Service {
  @tracked isWebview = false;
  @tracked _androidBridge = window.nativeBridge;
  @tracked _iosBridge = window.webkit?.messageHandlers?.nativeBridge;

  constructor() {
    super();
    if (this._isNativeBridgeOpen) {
      this.isWebview = true;
    }
  }

  get _bridge() {
    return this._androidBridge || this._iosBridge;
  }

  /**
   * Returns true is the application find either bridge in the global scope
   * @private
   * @default false
   * @returns boolean
   */
  get _isNativeBridgeOpen() {
    return Boolean(this._bridge);
  }

  /**
   * Execute the method with the appropriate bridge
   * @param {*} methodName Name of the method
   * @param {*} options Options to pass to the method (type: any)
   * @returns void
   */
  _callBridgeMethod(methodName, options) {
    if (this._androidBridge) {
      // /!\ If methodName is not handled on the Mobile APP
      // /!\ It will crash, so we use optional chaining here to make it retro-compatible.
      return this._bridge[methodName]?.(options);
    }

    if (this._iosBridge) {
      return this._bridge.postMessage({ action: methodName, options });
    }
  }

  /**
   * This function triggers the onJoinTeamSucceeded event to notify the
   * mobile application that the user has completed the join team flow
   */
  onJoinTeamSucceeded() {
    this._callBridgeMethod('onJoinTeamSucceeded');
  }
}
