/**
 * loadSmoothScrollPolyfill
 * Load polyfill to enable the smooth scroll behavior on browsers that does not support it.
 */
export async function loadSmoothScrollPolyfill() {
  if (!('scrollBehavior' in document.documentElement.style)) {
    try {
      // Asynchronously import polyfill
      let smoothscroll = await import('smoothscroll-polyfill');
      // Kick-off polyfill
      smoothscroll.polyfill();
    } catch {
      /* the library might not be well loaded
       * as it is a edge case
       * we will simply not enable the smooth scroll behavior
       * (instead of try to recover / inform the user)
       */
    }
  }
}
