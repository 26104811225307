const isEmptyArray = arr => {
  return Array.isArray(arr) && arr.length === 0;
};

const isEmptyObject = obj => {
  return obj.constructor === Object && Object.keys(obj).length === 0;
};

export function filterParams(params) {
  let filterEmptyKeys = k => {
    return params[k] && !isEmptyArray(params[k]) && !isEmptyObject(params[k]);
  };

  let keys = Object.keys(params).filter(filterEmptyKeys).sort();

  return keys.reduce((acc, k) => {
    let object = acc;
    object[k] = params[k];
    return object;
  }, {});
}

export function sortParams(params) {
  let keys = Object.keys(params).sort();

  return keys.reduce((acc, k) => {
    let object = acc;
    object[k] = params[k];
    return object;
  }, {});
}
