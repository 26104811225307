/* eslint-disable ember/require-computed-property-dependencies */
import { computed } from '@ember/object';

const BICS = {
  FRANCE: 'QNTOFRP1XXX',
  GERMANY: 'QNTODEB2XXX',
  ITALY: 'QNTOITM2XXX',
  TREEZOR: 'TRZOFR21XXX',
  SPAIN: 'QNTOESB2XXX',
};

export default function computedDomiciliation() {
  return computed('intl.locale', 'bankAccount.bic', function () {
    let bic = this.args.bankAccount.bic;
    if (bic === BICS.FRANCE) {
      return this.intl.t('bank_accounts.domiciliation.france');
    }
    if (bic === BICS.GERMANY) {
      return this.intl.t('bank_accounts.domiciliation.germany');
    }
    if (bic === BICS.ITALY) {
      return this.intl.t('bank_accounts.domiciliation.italy');
    }
    if (bic === BICS.TREEZOR) {
      return this.intl.t('bank_accounts.domiciliation.treezor');
    }
    if (bic === BICS.SPAIN) {
      return this.intl.t('bank_accounts.domiciliation.spain');
    }
  });
}
