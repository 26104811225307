import { service } from '@ember/service';

import BaseValidator from 'ember-cp-validations/validators/base';

const TinFormatOrganization = BaseValidator.extend({
  intl: service(),

  validate(value, options = {}, model) {
    if (!model.needsTaxData || value) {
      return true;
    }

    return this.intl.t(options.messageKey);
  },
});

export default TinFormatOrganization;
