import JSONAPISerializer from 'qonto/serializers/-json-api';

export default class IdentitiesKycSerializer extends JSONAPISerializer {
  modelNameFromPayloadKey(key) {
    switch (key) {
      case 'kyc':
        return 'identities-kyc';
      default:
        return super.modelNameFromPayloadKey(key);
    }
  }
}
