import { computed } from '@ember/object';

import { validator } from 'ember-cp-validations';

import maybeT from 'qonto/utils/maybe-t';

const BASE_ERROR_KEY_PREFIX = 'international-out.dynamic-form.errors';

const INVALID_ERROR_KEY_PREFIX = `${BASE_ERROR_KEY_PREFIX}.invalid`;
const MAX_LENGTH_ERROR_KEY_PREFIX = `${BASE_ERROR_KEY_PREFIX}.too-long`;
const MIN_LENGTH_ERROR_KEY_PREFIX = `${BASE_ERROR_KEY_PREFIX}.too-short`;
const PRESENCE_ERROR_KEY_PREFIX = `${BASE_ERROR_KEY_PREFIX}.presence`;

/**
 * Extracts custom validators associated to a field from a validations configuration object.
 *
 * @param {Object<string, {predicate: Function, translationKey: string}>} validations - Object containing validation rules for each field.
 * @param {Function} validations[fieldKey].predicate - Predicate function for the field's validation.
 * @param {string} validations[fieldKey].translationKey - Translation key for the validation error message.
 *
 * @returns {Object} An object with field keys as properties, each containing an object with ember-cp-validations validator objects.
 *
 * @example
 *
 * const validations = {
 *   username: {
 *     predicate: (value) => value.length <= 20,
 *     translationKey: 'username.tooLong'
 *   },
 * };
 *
 * const validators = extractCustomValidators(validations);
 * // validators:
 * // {
 * //   username: { validators: ... },
 * // }
 */
export function extractCustomValidators(validations) {
  return Object.entries(validations).reduce(
    (validators, [fieldKey, { predicate, translationKey = null }]) => {
      validators[fieldKey] = {
        validators: [
          validator('inline', {
            validate(value, _, { intl, localeManager }) {
              return predicate(value)
                ? true
                : maybeT(
                    {
                      key: translationKey,
                      fallback: intl.t('validations.errors.invalid'),
                    },
                    intl,
                    localeManager.locale
                  );
            },
          }),
        ],
      };
      return validators;
    },
    {}
  );
}

/**
 * Extracts validation rules from an array of fields and create an object with a set of `ember-cp-validations` validator objects for each field.
 *
 * @param {Array} fields - An array of fields.
 * @param {string} fields[].key - The unique key for each field.
 * @param {boolean} fields[].required - Indicates whether the field is required.
 * @param {number|null} [fields[].minLength] - The minimum length allowed for the field.
 * @param {number|null} [fields[].maxLength] - The maximum length allowed for the field.
 * @param {string|null} [fields[].validationRegexp] - Regular expression to validate the field format.
 *
 * @returns {Object} An object with field keys as properties, each containing an object with ember-cp-validations validator objects.
 *
 * @example
 *
 * const fields = [
 *   { key: 'username', maxLength: 20, required: true },
 *   { key: 'email', required: true, validationRegexp: '^\\S+@\\S+\\.\\S+$' },
 * ]
 *
 * const validators = extractFieldsValidators(fields);
 * // validators:
 * // {
 * //   username: { validators: ... },
 * //   email: { validators: ... },
 * // }
 */
export function extractFieldsValidators(fields) {
  return fields.reduce(
    (validators, field) => ({
      ...validators,
      [field.key]: { validators: extractSingleFieldValidators(field) },
    }),
    {}
  );
}

/**
 * Extracts validation rules from a single form field description and creates a set of `ember-cp-validations` validator objects.
 *
 * @param {Object} field - The field object containing validation criteria.
 * @param {boolean} [field.required] - Indicates whether the field is required.
 * @param {number|null} [field.minLength] - The minimum length allowed for the field.
 * @param {number|null} [field.maxLength] - The maximum length allowed for the field.
 * @param {string|null} [field.validationRegexp] - Regular expression to validate the field format.
 *
 * @returns {Array} validators - An array of `ember-cp-validations` validator objects for the given field.
 *
 * @example
 *
 * const field = { required: true };
 *
 * const fieldValidators = extractSingleFieldValidators(field);
 * // fieldValidators:
 * // [{
 * //   options: {
 * //     presence: true,
 * //     message: 'This field is required',
 * //   },
 * //   _type: 'presence',
 * // }]
 */
export function extractSingleFieldValidators(field) {
  let validators = [];

  let { key, maxLength, minLength, required, validationRegexp } = field;

  let modelPath = `model.${key}`;

  let isValidatorDisabled = fieldValue => !required && !fieldValue;

  if (required) {
    validators.push(
      validator('presence', {
        presence: true,
        message: computed('model.{intl.locale,localeManager.locale}', function () {
          let { intl, localeManager } = this.model;
          return maybeT(
            {
              key: `${PRESENCE_ERROR_KEY_PREFIX}.${key}`,
              fallback: intl.t('validations.errors.blank'),
            },
            intl,
            localeManager.locale
          );
        }),
      })
    );
  }

  if (minLength) {
    validators.push(
      validator('length', {
        disabled: computed(modelPath, function () {
          return isValidatorDisabled(this.get(modelPath));
        }),
        min: minLength,
        message: computed('model.{intl.locale,localeManager.locale}', function () {
          let { intl, localeManager } = this.model;
          return maybeT(
            {
              key: `${MIN_LENGTH_ERROR_KEY_PREFIX}.${key}`,
              fallback: intl.t('validations.errors.tooShort', { min: minLength }),
            },
            intl,
            localeManager.locale
          );
        }),
      })
    );
  }

  if (maxLength) {
    validators.push(
      validator('length', {
        disabled: computed(modelPath, function () {
          return isValidatorDisabled(this.get(modelPath));
        }),
        max: maxLength,
        message: computed('model.{intl.locale,localeManager.locale}', function () {
          let { intl, localeManager } = this.model;
          return maybeT(
            {
              key: `${MAX_LENGTH_ERROR_KEY_PREFIX}.${key}`,
              fallback: intl.t('validations.errors.tooLong', { max: maxLength }),
            },
            intl,
            localeManager.locale
          );
        }),
      })
    );
  }

  if (validationRegexp) {
    validators.push(
      validator('format', {
        disabled: computed(modelPath, function () {
          return isValidatorDisabled(this.get(modelPath));
        }),
        regex: new RegExp(validationRegexp),
        message: computed('model.{intl.locale,localeManager.locale}', function () {
          let { intl, localeManager } = this.model;
          return maybeT(
            {
              key: `${INVALID_ERROR_KEY_PREFIX}.${key}`,
              fallback: intl.t('validations.errors.invalid'),
            },
            intl,
            localeManager.locale
          );
        }),
      })
    );
  }

  return validators;
}

/**
 * Merges two objects containing ember-cp-validations validator objects for different fields.
 *
 * @param {Object} firstValidatorsMap - The first validators map.
 * @param {Object} [secondValidatorsMap={}] - The second validators map. Defaults to an empty object.
 *
 * @returns {Object} The merged validators map.
 *
 * @example
 * const firstValidatorsMap = {
 *   username: {
 *     validators: [requiredValidator, validateUsernameLength]
 *   },
 *   email: {
 *     validators: [requiredValidator, validateEmailFormat]
 *   }
 * };
 *
 * const secondValidatorsMap = {
 *   username: {
 *     validators: [validateUsernameFormat]
 *   },
 * };
 *
 * const mergedValidators = mergeValidators(firstValidatorsMap, secondValidatorsMap);
 * // mergedValidators:
 * // {
 * //   username: { validators: [requiredValidator, validateUsernameLength, validateUsernameFormat] },
 * //   email: { validators: [requiredValidator, validateEmailFormat] },
 * // }
 */
export function mergeValidators(firstValidatorsMap, secondValidatorsMap = {}) {
  let mergedValidators = {};

  for (let fieldKey in firstValidatorsMap) {
    if (firstValidatorsMap.hasOwnProperty(fieldKey)) {
      mergedValidators[fieldKey] = {
        validators: [
          ...(firstValidatorsMap[fieldKey]?.validators || []),
          ...(secondValidatorsMap[fieldKey]?.validators || []),
        ],
      };
    }
  }

  for (let fieldKey in secondValidatorsMap) {
    if (secondValidatorsMap.hasOwnProperty(fieldKey)) {
      if (!(fieldKey in firstValidatorsMap)) {
        mergedValidators[fieldKey] = {
          validators: [...(secondValidatorsMap[fieldKey]?.validators || [])],
        };
      }
    }
  }

  return mergedValidators;
}
