import { service } from '@ember/service';

import BaseValidator from 'ember-cp-validations/validators/base';

const TinFormat = BaseValidator.extend({
  intl: service(),

  validate(value, options = {}, model) {
    let { taxCountryFormat, messageKey } = options;

    if (!value && model.needsTaxNumber && model.needsTaxData) {
      return this.intl.t(messageKey.fieldIsMissing);
    }

    if (
      !model.needsTaxNumber ||
      !model.needsTaxData ||
      !model.canModifyTin ||
      (model.needsTaxNumber && !taxCountryFormat) ||
      (model.needsTaxNumber && taxCountryFormat && value.match(taxCountryFormat))
    ) {
      return true;
    }

    return this.intl.t(messageKey.wrongFormat, {
      taxCountry: model.taxCountry,
    });
  },
});

export default TinFormat;
