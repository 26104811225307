import { EmbeddedRecordsMixin } from '@ember-data/serializer/rest';

import ActiveModelSerializer from 'active-model-adapter/active-model-serializer';

export default class MultiTransferSerializer extends ActiveModelSerializer.extend(
  EmbeddedRecordsMixin
) {
  attrs = {
    transfers: {
      deserialize: 'records',
      serialize: 'records',
    },
  };
}
