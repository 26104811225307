import Service, { service } from '@ember/service';

import ENV from 'qonto/config/environment';

export default class MetricsManager extends Service {
  @service metrics;
  @service cookiesConsentManager;

  activateMetricsAdapters() {
    if (this.canActivateMetricsAdapters) {
      this.metrics.activateAdapters([this.segmentMetricAdapter]);
    }
  }

  get segmentMetricAdapter() {
    let { config, environments } = ENV.metricsAdaptersConfig.Segment;
    let integrations = this.cookiesConsentManager.getSegmentIntegrations();

    return {
      name: 'LocalSegment',
      environments,
      config: { key: config.key, options: { integrations } },
    };
  }

  get canActivateMetricsAdapters() {
    return ENV.metricsAdaptersConfig?.Segment ?? false;
  }
}
