import { service } from '@ember/service';
import { isBlank } from '@ember/utils';

import ApplicationSerializer from 'qonto/serializers/application';

export default class UserSerializer extends ApplicationSerializer {
  @service localeManager;
  @service zendeskWidget;

  extractAttributes(modelClass, resourceHash) {
    let attributes = super.extractAttributes(modelClass, resourceHash);
    if (isBlank(attributes.locale)) {
      attributes.locale = this.localeManager.locale;
    }
    return attributes;
  }

  serializeAttribute(snapshot, json, key, attributes) {
    super.serializeAttribute(snapshot, json, key, attributes);
    if (key === 'locale' && isBlank(json[key])) {
      json[key] = this.localeManager.locale;
    }
  }

  normalize(model, hash, prop) {
    if (hash.zendesk?.messaging_widget_jwt && this.zendeskWidget?.isMounted) {
      this.zendeskWidget._authenticate(hash.zendesk.messaging_widget_jwt);
    }
    return super.normalize(model, hash, prop);
  }
}
