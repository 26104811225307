// https://gitlab.qonto.co/tech/divein/-/blob/master/text/2021/2021-02-01-DOCUMENTATION-apis-errors.md#our-needs
// 'code' should be used for app localization as described in linked standard
// https://api.emberjs.com/ember-data/4.1/classes/Model/properties/errors?anchor=errors
// ember data exposes errors property on model which contains message and attribute.
// ember data will use the detail property in the payload for the message
// we want to use the code property for the message which can then be localized

import JSONAPISerializer from '@ember-data/serializer/json-api';
import { camelize } from '@ember/string';

export default function extractErrors(store, typeClass, payload, id) {
  let errors = payload.errors.map(({ code, ...rest }) => {
    let error = { ...rest, detail: code };
    if (error.source?.pointer) {
      error.source.pointer = camelize(error.source.pointer);
    }
    return error;
  });
  return new JSONAPISerializer().extractErrors(store, typeClass, { errors }, id);
}
