import { assert } from '@ember/debug';

export function markHandledBySentry(error) {
  assert('You must pass an Error instance', error instanceof Error);
  error.HANDLED_BY_SENTRY = true;
  return error;
}

export function wasHandledBySentry(error) {
  assert('You must pass an Error instance', error instanceof Error);
  return Boolean(error.HANDLED_BY_SENTRY);
}
