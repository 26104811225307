import Service, { service } from '@ember/service';

import { dropTask, race, rawTimeout, restartableTask, waitForEvent } from 'ember-concurrency';

import { ignoreCancelation } from 'qonto/utils/ignore-error';

const USER_ACTIVITY_TIMEOUT = 25 * 60 * 1000; // 25 min
const MODAL_TIMEOUT = 5 * 60 * 1000; // 5 min

export default class RefreshSessionManager extends Service {
  @service intl;

  @service modals;

  @service sessionManager;

  @service('ember-user-activity@user-activity') userActivity;

  @service userManager;

  startActivityTracking() {
    // eslint-disable-next-line ember-concurrency/no-perform-without-catch
    this.activityTrackingTask.perform();
  }

  cancelActivityTracking() {
    this.activityTrackingTask.cancelAll();
  }

  activityTrackingTask = restartableTask(async () => {
    // eslint-disable-next-line no-constant-condition
    while (true) {
      // eslint-disable-next-line ember-concurrency/no-perform-without-catch
      this.scheduleModalTask.perform(USER_ACTIVITY_TIMEOUT);

      await waitForEvent(this.userActivity, 'userActive');
    }
  });

  scheduleModalTask = restartableTask(async timeUntilModal => {
    await rawTimeout(timeUntilModal);
    this.showModalTask.perform().catch(ignoreCancelation);
  });

  showModalTask = dropTask(async () => {
    if (!this.sessionManager.isAuthenticated) {
      return;
    }

    let modal = this.modals.open('popup/confirmation', {
      title: this.intl.t('logout.modal.title'),
      description: this.intl.t('logout.modal.content', { htmlSafe: true }),
      cancel: this.intl.t('logout.modal.close_btn'),
      confirm: this.intl.t('logout.modal.accept_btn'),
    });

    let timeout = rawTimeout(MODAL_TIMEOUT).then(() => 'timeout');

    let result = await race([modal, timeout]);

    if (result === 'timeout') {
      modal.close();

      this.userManager.redirectUponLogout = 'logout';
    }

    if (result === 'cancel' || result === 'close' || result === 'timeout') {
      this.sessionManager.invalidate();
    }
  });
}
