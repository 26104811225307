import filterEmptyGroups from './filter-empty-groups';

/**
 * This function filters out empty main menu items
 *
 * @param {Array} items - An array of objects, each representing a menu item. Each item object may contain a `groups` property, which is an array of group objects.
 * @returns {Array} - An array containing filtered menu item objects. Items without a label and with an empty or no `groups` array are filtered out, as are groups within items that are empty.
 */
export default function filterMenuItems(items) {
  return items
    .filter(Boolean)
    .map(({ groups, ...attributes }) => ({
      ...attributes,
      ...(groups && { groups: filterEmptyGroups(groups) }),
    }))
    .filter(({ groups, label }) => Boolean(groups?.length ?? label));
}
