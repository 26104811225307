import {
  USER_ACTIONS_CTA_TYPE,
  USER_ACTIONS_ILLUSTRATION_TYPE,
} from 'qonto/constants/user-actions';

export function requiredCards({ intl, organization }) {
  return {
    'kyc-kyb-periodic-update-action'() {
      return {
        showRequiredBadge: true,
        heading:
          organization.isItalian && organization.hasRestrictSensitiveActionsFeature
            ? intl.t('user-actions.required-cards.kyc-kyb-periodic-update-suspension-risk.title')
            : intl.t('user-actions.required-cards.kyc-kyb-periodic-update.title'),
        cta: {
          type: USER_ACTIONS_CTA_TYPE.LINK_TO,
          route: 'settings.company-profile',
          model: organization,
          text: intl.t('user-actions.required-cards.kyc-kyb-periodic-update.cta'),
        },
        illustration: {
          name: 'kyb-pending',
          type: USER_ACTIONS_ILLUSTRATION_TYPE.SVG,
        },
      };
    },
  };
}
