/**
 * Unflattens a flattened object with dot-separated keys.
 *
 * @param {Object} flattenedObject - The flattened data to be unflattened.
 * @returns {Object} - The unflattened object.
 *
 * @example
 * const flattenedData = {
 *   'user.name': 'John Doe',
 *   'user.age': 30,
 *   'user.address.city': 'New York',
 *   'user.address.zipCode': '10001'
 * };
 *
 * const unflattenedData = unflatten(flattenedData);
 *
 * console.log(unflattenedData);
 * // {
 * //   user: {
 * //     name: 'John Doe',
 * //     age: 30,
 * //     address: {
 * //       city: 'New York',
 * //       zipCode: '10001'
 * //     }
 * //   }
 * // }
 */
export function unflatten(flattenedObject) {
  let unflattenedObject = {};

  for (let key in flattenedObject) {
    let keys = key.split('.');
    keys.reduce((nestedObject, currentKey, index) => {
      if (nestedObject[currentKey] === undefined) {
        // Create an empty object or array based on the next key
        nestedObject[currentKey] = isNaN(Number(keys[index + 1]))
          ? keys.length - 1 === index
            ? flattenedObject[key]
            : {}
          : [];
      }
      return nestedObject[currentKey];
    }, unflattenedObject);
  }

  return unflattenedObject;
}
