import RestSerializer from '@ember-data/serializer/rest';
import { camelize, underscore } from '@ember/string';

function extractErrors(store, typeClass, payload, id) {
  let errors = payload.errors.map(({ code, ...rest }) => {
    let error = { ...rest, detail: code };
    if (error.source?.pointer) {
      error.source.pointer = camelize(error.source.pointer);
    }
    return error;
  });
  return new RestSerializer().extractErrors(store, typeClass, { errors }, id);
}

export default class SupplierSerializer extends RestSerializer {
  keyForAttribute(attr) {
    return underscore(attr);
  }

  extractErrors = extractErrors;
}
