import ApplicationSerializer from 'qonto/serializers/application';

export default class DocumentSerializer extends ApplicationSerializer {
  normalizeCreateRecordResponse(store, primaryModelClass, payload, id, requestType) {
    // the response from documents that were uploaded to the organization
    // subroute actually has a full organization as the root object. This needs
    // to be corrected before passing it to ember-data
    if (payload.organization) {
      return super.normalizeCreateRecordResponse(
        store,
        primaryModelClass,
        {
          document: {
            ...payload.organization.documents[0],
            organization_id: payload.organization.documents[0].subject_id,
          },
          organizations: [payload.organization],
        },
        id,
        requestType
      );
    }

    return super.normalizeCreateRecordResponse(...arguments);
  }

  // this workaround is required
  // since ember-data expects ids when (de)serializing relationships
  // here: organization <-> address and membership <-> address
  // and the backend sends:
  // {
  //   organizations: [
  //     {
  //       address: {
  //         `id` <- add random id here
  //         contact_name: 'contact name',
  //         ...
  //       },
  //       contract_status: 'signed'
  //     }
  //   ]
  // }
  normalize(model, hash, prop) {
    if (hash && !hash.id) {
      hash.id = crypto.randomUUID();
    }
    return super.normalize(model, hash, prop);
  }

  serializeIntoHash(data, type, snapshot, options) {
    if (snapshot.attr('subjectType') === 'Organization') {
      let newData = { merge_bylaw_annex: true };
      // this may seem like it's wrong but the RESTSerialiser actually mutates the
      // first object that is passed into it (and the active-serializer-adapter is
      // essentially an extension of the RESTAdapter). This seems strange but it
      // is correct
      if (!newData.documents) {
        super.serializeIntoHash(newData, type, snapshot, options);
        newData.documents = [newData.document];
        delete newData.document;
        data.organization = newData;
      }
    } else {
      super.serializeIntoHash(data, type, snapshot, options);
    }
  }
}
