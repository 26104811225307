import dynamicSegmentsFromRouteInfo from 'qonto/utils/dynamic-segments-from-route-info';

export default function urlFromRouteInfo(router, routeInfo) {
  if (!routeInfo) {
    return '/';
  }

  let args = dynamicSegmentsFromRouteInfo(routeInfo);

  if (routeInfo.queryParams && Object.keys(routeInfo.queryParams).length > 0) {
    args.push({ queryParams: routeInfo.queryParams });
  }

  try {
    return router.urlFor(routeInfo.name, ...args);
  } catch {
    return '/';
  }
}
