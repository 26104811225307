import { EmbeddedRecordsMixin } from '@ember-data/serializer/rest';

import ActiveModelSerializer from 'active-model-adapter/active-model-serializer';

export default ActiveModelSerializer.extend(EmbeddedRecordsMixin, {
  attrs: {
    beneficiaries: {
      deserialize: 'records',
      serialize: 'records',
    },
  },
});
