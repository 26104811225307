/**
 * Recursively merges properties from two objects, giving precedence to the source object.
 *
 * @param {Object} targetObject - The target object to merge into.
 * @param {Object} sourceObject - The source object whose properties will be merged into the target.
 * @returns {Object} - A new object representing the merged result of the two objects.
 */
export function deepMerge(targetObject, sourceObject) {
  return Object.entries(sourceObject).reduce(
    (mergedObject, [key, value]) => {
      if (value instanceof Object) {
        if (targetObject.hasOwnProperty(key) && targetObject[key] instanceof Object) {
          mergedObject[key] = deepMerge(targetObject[key], value);
        } else {
          mergedObject[key] = deepMerge({}, value); // This prevents side-effects because value here is a reference to some part of the source object.
        }
      } else {
        mergedObject[key] = value;
      }

      return mergedObject;
    },
    { ...targetObject }
  );
}
