import window from 'ember-window-mock';

export function debounce(executor) {
  let requestId = null;
  return function () {
    if (requestId) {
      window.cancelAnimationFrame(requestId);
    }

    requestId = window.requestAnimationFrame(() => {
      executor.apply(this, arguments);
      requestId = null;
    });
  };
}

export default { debounce };
