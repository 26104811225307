import ApplicationSerializer from './application';

export default class PeriodSerializer extends ApplicationSerializer {
  serialize(snapshot, options) {
    let serialized = super.serialize(snapshot, options);
    delete serialized.computed_amounts_id;
    delete serialized.exercise_id;
    delete serialized.amount_settled;
    if (serialized.amount_budgeted?.value === 0) {
      serialized.amount_budgeted.value = '0.00';
    }
    return serialized;
  }

  normalize(model, hash, prop) {
    let normalized = super.normalize(model, hash, prop);

    if (hash.lid) normalized.data.lid = hash.lid;

    if (normalized.data.attributes.amountBudgeted?.value === '0.00') {
      normalized.data.attributes.amountBudgeted.value = 0;
    }
    return normalized;
  }
}
