import Service, { service } from '@ember/service';
import { waitFor } from '@ember/test-waiters';

import { didCancel, dropTask, task } from 'ember-concurrency';
import window from 'ember-window-mock';

import ENV from 'qonto/config/environment';
import { ExternalScriptError, loadScript } from 'qonto/utils/load-script';

function _refiner() {
  window._refinerQueue.push(arguments);
}

export default class RefinerService extends Service {
  @service organizationManager;
  @service cookiesConsentManager;

  get isLoaded() {
    return Boolean(this.loadRefinerTask.lastSuccessful);
  }

  loadTask = dropTask(async () => {
    // Refiner script can only be loaded once
    // Meaning that all datas in identifyUser will not be udpated in any case
    if (this.isLoaded) return;

    try {
      await this.loadRefinerTask.perform();
    } catch (error) {
      if (!(didCancel(error) || error instanceof ExternalScriptError)) throw error;
    }
  });

  loadRefinerTask = task(
    waitFor(async () => {
      let projectId = ENV.refinerProjectId;

      await loadScript('https://js.refiner.io/2023-03-15-qonto/client.js');

      window._refinerQueue = window._refinerQueue || [];

      _refiner('setProject', projectId);
      _refiner('identifyUser', {
        id: this.organizationManager.membership.id,
        name: this.organizationManager.membership.fullName,
        role: this.organizationManager.membership.role,
        account: {
          id: this.organizationManager.organization.id,
          name: this.organizationManager.organization.legalName,
          country: this.organizationManager.organization.legalCountry,
        },
      });
    })
  );
}
