/**
 * Normalizes the payload by removing the `bank_account_id` field if the corresponding bank account
 * does not exist in the store. This is possible when managers receive a payload which has a bank
 * account relationship to a bank account they don't have access to.
 *
 * @param {DS.Store} store The Ember Data store instance used to check if the bank account exists.
 * @param {Object} payload The payload object that may contain `bank_account_id`.
 */

export function normalizeMissingBankAccount(store, payload) {
  let bankAccountId = payload.bank_account_id;
  if (bankAccountId && !store.peekRecord('bank-account', bankAccountId)) {
    delete payload.bank_account_id;
  }
}
