// @ts-nocheck
import dayjs from 'dayjs';
import window from 'ember-window-mock';

/**
 * Formats a number with a metric suffix (k, M)
 * @param {number} num Number to format
 * @returns Formatted number
 */
export function metricSuffixer(num) {
  let round = (num, divider) => Math.round((num / divider) * 10) / 10;
  let abs = Math.abs(num);
  if (abs > 999999) {
    return `${round(num, 1000000)}M`;
  } else if (abs > 999) {
    return `${round(num, 1000)}K`;
  }
  return `${num}`;
}

/**
 * Reads the prefers-reduced-motion browser preference
 * @returns {boolean} True if animations are not reduced
 */
export function canAnimate() {
  let reduceMotion = window.matchMedia('(prefers-reduced-motion: reduce)');
  return !reduceMotion.matches;
}

/**
 * Returns a variation rate as a percentage
 * @param {number} current Current value
 * @param {number} previous Previous value
 * @param {number} decimals Number of decimals to display in a percentage format
 * @returns {number} variation Variation expressed as a percentage
 */
export function variation(current, previous, decimals = 1) {
  if (!Number.isFinite(previous) || previous === 0) return undefined;
  if (!Number.isFinite(current)) return undefined;

  let ratio = current / previous;
  let decimalFormatter = Math.pow(10, decimals) * 100;
  return Math.round((ratio - 1) * decimalFormatter) / decimalFormatter;
}

/**
 * Computes a sampling rate from a custom period
 * @param {*} period Custom period
 * @returns 'daily' | 'weekly' | 'monthly'
 */
export function getCustomPeriodSamplingRate({ startDate, endDate }) {
  let customPeriodDays = dayjs(endDate).diff(startDate, 'day');
  let customPeriodMonths = dayjs(endDate).diff(startDate, 'month');
  if (customPeriodDays <= 31) {
    return 'daily';
  } else if (customPeriodMonths > 3) {
    return 'monthly';
  } else {
    return 'weekly';
  }
}

/**
 * Setting time to current timezone
 * @param {*} timestamp UTC Timestamp
 * @param {*} timezone Timezone (default: Europe/Paris)
 */
export function utcToZonedDate(
  timestamp: string | number,
  timezone = 'Europe/Paris'
): Date | undefined {
  if (!timestamp) return undefined;

  const localDate = new Date(timestamp).toLocaleString('en-US', {
    timeZone: timezone,
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
  });
  const [month, day, year] = localDate.split('/');

  return new Date(Number(year), Number(month) - 1, Number(day));
}

export class Timevalue {
  time;
  data = {};

  setTime(timestamp, timezone) {
    this.time = utcToZonedDate(timestamp, timezone);
  }
}

export class IndicatorTimevalue extends Timevalue {
  constructor(timevalue, type, timezone) {
    super();
    this.setTime(timevalue.start_date, timezone);
    this.setData(timevalue, type);
  }

  setData(timevalue, type) {
    switch (type) {
      case 'balance':
        this.data.value = Number(timevalue.data.ending_balance);
        break;
      case 'net-change':
        this.data.value =
          Number(timevalue.data.ending_balance) - Number(timevalue.data.starting_balance);
        break;
      case 'inflows':
      case 'outflows':
        this.data.value = Number(timevalue.data.amount_sum);
        this.display = new FlowDisplay(timevalue);
        break;
    }
  }
}

export class CashflowTimevalue extends Timevalue {
  constructor(inflows, outflows, balance, previousInflows, previousOutflows, timezone) {
    super();
    this.setTime(balance.start_date, timezone);
    this.setData(inflows, outflows, balance);
    this.setDisplay(inflows, outflows, previousInflows, previousOutflows);
  }

  setData(inflows, outflows, balance) {
    this.data = {
      startingBalance: Number(balance.data?.starting_balance),
      endingBalance: Number(balance.data?.ending_balance),
      inflows: inflows ? Number(inflows?.data?.amount_sum) : 0,
      outflows: outflows ? Number(outflows?.data?.amount_sum) : 0,
    };
  }

  setDisplay(inflows, outflows, previousInflows, previousOutflows) {
    this.display = {
      balance: {
        netChange: this.data.endingBalance - this.data.startingBalance,
      },
      inflows: new FlowDisplay(inflows, previousInflows),
      outflows: new FlowDisplay(outflows, previousOutflows),
    };
  }
}

export class FlowDisplay {
  constructor(currentFlow, previousFlow) {
    this.amount = currentFlow ? Number(currentFlow.data?.amount_sum) : 0;
    this.count = currentFlow ? Number(currentFlow.data?.amount_count) : null;
    this.variation = previousFlow
      ? variation(this.amount, Number(previousFlow.data?.amount_sum))
      : null;
  }
}
