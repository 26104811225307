import Service, { service } from '@ember/service';

import { apiBaseURL } from 'qonto/constants/hosts';
import { safeLocalStorage } from 'qonto/helpers/safe-local-storage';
import {
  copyBeneficiaryIntoTransfer,
  copyBeneficiaryLabelsIntoTransfer,
  copyBeneficiaryVatIntoTransfer,
} from 'qonto/utils/transfers';

/**
 * @typedef {Object} Amount
 * @property {string} value - The value of the transfer.
 * @property {string} currency - The currency of the transfer, e.g., "EUR".
 */

/**
 * @typedef {Object} TransferInformation
 * @property {Amount} amount - The amount of the transfer.
 * @property {string} beneficiary_name - The name of the beneficiary of the transfer.
 * @property {string} description - The description of the transfer.
 */

/**
 * @typedef {Object} CreateTransferInformationResponse
 * @property {TransferInformation} transfer_information - The transfer information.
 */

export default class QontoPilotService extends Service {
  @service abilities;
  @service beneficiariesManager;
  @service intl;
  @service networkManager;
  @service organizationManager;
  @service store;
  @service toastFlashMessages;

  modal;

  LOCAL_STORAGE_PREFIX_RECENT_QUICK_ACTIONS = 'recent-quick-actions';
  RECENT_QUICK_ACTIONS_LENGTH = 3;

  /**
   * Searches for and retrieves beneficiary information based on the provided name.
   * This method queries the beneficiaries manager with the organization's ID and the beneficiary's name.
   *
   * @param {string} beneficiaryName - The name of the beneficiary to search for.
   * @returns {Promise<Object|null>} A promise that resolves to the beneficiary object if found, or null if an error occurs.
   * @throws {Error} Throws an error if the beneficiaries data could not be loaded.
   * @private
   */
  async #findBeneficiaryInformation(beneficiaryName) {
    try {
      let beneficiary = await this.beneficiariesManager.loadSepaBeneficiaries(
        this.organizationManager.organization.id,
        beneficiaryName
      );
      return beneficiary[0];
    } catch {
      this.toastFlashMessages.toastError('Failed to load beneficiaries');
    }
  }

  /**
   * Creates a transfer record using the provided transfer information and beneficiary data.
   * This method constructs a new transfer object with all necessary details and adds an idempotency key.
   *
   * @param {TransferInformation} transferInfo - The information about the transfer extracted from the user input.
   * @param {Object} beneficiary - The beneficiary information.
   * @returns {Object} The newly created transfer record.
   */
  #createTransferRecord(transferInfo, beneficiary) {
    let transfer = this.store.createRecord('transfer', {
      amount: transferInfo.amount.value,
      beneficiary,
      instant: !beneficiary.qontoBankAccount,
      reference: transferInfo.description,
      organization: this.organizationManager.organization,
      bankAccount: this.organizationManager.organization.mainAccount,
    });

    copyBeneficiaryIntoTransfer(transfer, beneficiary, { forceCopy: true });
    copyBeneficiaryLabelsIntoTransfer(transfer, beneficiary);

    if (this.abilities.can('view vat bookkeeping')) {
      copyBeneficiaryVatIntoTransfer(transfer, beneficiary);
    }

    transfer.addIdempotencyKey();
    return transfer;
  }

  /**
   * Processes the user input to extract transaction details and creates a transfer record.
   * This method sends a POST request to the backend with the user input and organization ID,
   * then uses the response to create a transfer record.
   *
   * @param {string} userInput - The user input containing details for the transaction.
   * @returns {Promise<Object>} A promise that resolves to a transfer object containing the transaction details.
   * @throws {Error} Throws an error if there is a failure in processing the user input or creating the transfer.
   */
  async processUserInput(userInput) {
    try {
      /** @type {CreateTransferInformationResponse} */
      let response = await this.networkManager.request(`${apiBaseURL}/v1/qonto-pilot/invoke`, {
        method: 'POST',
        data: JSON.stringify({
          organization_id: this.organizationManager.organization.id,
          user_input: userInput,
        }),
      });

      let maxAmountWithoutAttachmentCents =
        this.organizationManager.organization.transferSettings.max_amount_without_attachment_cents;

      if (response.transfer_information.amount.value >= maxAmountWithoutAttachmentCents / 100) {
        this.toastFlashMessages.toastError('The amount is too high. Please use the normal flow');
        return null;
      }

      let beneficiary = await this.#findBeneficiaryInformation(
        response.transfer_information.beneficiary_name
      );

      if (!beneficiary) {
        return this.intl.t('qonto-pilot.modal.error.wrong-prompt');
      }

      return this.#createTransferRecord(response.transfer_information, beneficiary);
    } catch {
      return this.intl.t('qonto-pilot.modal.error.wrong-prompt');
    }
  }

  #setRecentQuickActionsArray(array) {
    safeLocalStorage.setItem(this.LOCAL_STORAGE_PREFIX_RECENT_QUICK_ACTIONS, JSON.stringify(array));
  }

  getRecentQuickActions() {
    let recentQuickActions = safeLocalStorage.getItem(
      this.LOCAL_STORAGE_PREFIX_RECENT_QUICK_ACTIONS
    );
    return recentQuickActions ? JSON.parse(recentQuickActions) : [];
  }

  addRecentQuickAction(item) {
    let recentQuickActionsArray =
      this.getRecentQuickActions(this.LOCAL_STORAGE_PREFIX_RECENT_QUICK_ACTIONS) || [];
    recentQuickActionsArray = recentQuickActionsArray.filter(key => key !== item);
    recentQuickActionsArray.unshift(item);
    if (recentQuickActionsArray.length > this.RECENT_QUICK_ACTIONS_LENGTH) {
      recentQuickActionsArray.pop();
    }
    this.#setRecentQuickActionsArray(recentQuickActionsArray);
  }
}
