/**
 * Recursively searches for the active main menu item
 *
 * @param {Array} items - The array of main menu/submenu group items to search
 * @param {string} mainMenuItemId - The current main menu item whose items are being checked
 * @returns {item|null} The active main menu item, or null if no active item is found
 *
 */
export default function findActiveMainMenuItem(items, mainMenuItem) {
  for (let item of items) {
    if (item.link?.isActive) return mainMenuItem ?? item;

    if (item.groups) {
      let activeMenuItem = findActiveMainMenuItem(
        item.groups.flatMap(({ items }) => items),
        item
      );

      if (activeMenuItem) return activeMenuItem;
    }
  }

  return null;
}
