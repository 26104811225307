import DoublyLinkedList, { DllNode } from 'qonto/utils/doubly-linked-list';

class BackStack {
  items = [];

  empty() {
    this.items.length = 0;
  }

  add(node) {
    this.items.push(node);
  }

  restore({ flowName, stepId }) {
    if (!this.items.length) {
      return [];
    }

    let index = this.items.findIndex(
      ({ value }) => value.flowName === flowName && value.id === stepId
    );

    return index < 0 ? [] : this.items.splice(index, this.items.length - index);
  }

  get length() {
    return this.items.length;
  }
}

export default class NavigationContextDll extends DoublyLinkedList {
  backStack = new BackStack();

  append(value) {
    let tailToBe = new DllNode(value);

    // Set the reference to the parent step on the new tail's `next` if current tail has it.
    if (this.tail?.next) {
      tailToBe.next = this.tail.next;
    }

    this._makeTail(tailToBe);
  }

  /**
   * Appends the first node of a secondary flow.
   * Sets a reference to the parent step on tail.next
   */
  appendFlow(value) {
    let tailReference = this.tail;

    this.append(value);

    this.tail.next = tailReference;
  }

  pop() {
    let tailReference = this.tail;

    let poppedNode = super.pop();
    this.backStack.add(poppedNode);

    // Keep the reference to the parent step in `tail.next` when the DLL is popped
    // only if the new tail is not the parent step
    if (this.tail && this.tail !== tailReference.next) {
      this.tail.next = tailReference.next;
    }
  }

  /**
   * Pop dll nodes between current tail (included) and the parent node
   */
  popFlow() {
    let parentNode = this.tail.next.value;
    let { id, flowName } = parentNode;
    this.popUntilStep(id, flowName);
    this.backStack.empty();
  }

  /**
   * Pop dll nodes until the given step id of a specific flow is reached
   */
  popUntilStep(stepId, flowName) {
    while (this.tail.value.id !== stepId || this.tail.value.flowName !== flowName) {
      this.pop();
    }
  }

  /**
   * Restores popped steps if any
   */
  restoreUntilStep(stepInfo) {
    let nodes = this.backStack.restore(stepInfo);
    if (nodes.length) {
      nodes.forEach(({ value }) => this.append(value));
      return nodes.length;
    }
    return 0;
  }
}
