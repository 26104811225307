/* eslint-disable require-await */
import { get } from '@ember/object';
import Service, { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

import * as Sentry from '@sentry/ember';
import { reads } from 'macro-decorators';

// session related data maybe extracted to session service

/**
 * User manager
 *
 * @class UserManager
 * @module qonto/services/user-manager
 * @extends Ember.Service
 */
export default class UserManagerService extends Service {
  @service localeManager;
  @service notifierManager;
  @service sessionManager;
  @service store;
  @service launchdarkly;
  @service tandem;

  redirectUponLogout = null;
  @tracked currentUser = null;

  @reads('sessionManager.data.authenticated.websocket_token') websocketToken;
  @reads('sessionManager.data.authenticated.expires') sessionExpirationTime;
  @reads('currentUser.memberships') userMemberships;

  /**
   * Send a request for a forget password
   *
   * @public
   * @method forgotPassword
   *
   * @param  {String} email
   * @returns {Promise}
   */
  async forgotPassword(email) {
    let userPassword = this.store.createRecord('users/password', {
      email,
    });

    return userPassword.forgotPassword();
  }

  /**
   * Get the devices of a user
   *
   * @public
   * @method getDevices
   *
   * @returns {Array} Array of devices
   */
  async getDevices() {
    let { devices } = await this.store.adapterFor('user').getDevices();
    return devices;
  }

  /**
   * Authentication
   *
   * @public
   * @method setup
   *
   * @param  {Object} [credentials={}]
   * @returns {Promise}
   */
  async setup(credentials = {}) {
    let { email, password, code, loginToken, authToken } = credentials;

    if (!this.sessionManager.isAuthenticated && !email && !loginToken && !authToken) {
      return;
    }

    let authData;
    if (loginToken) {
      authData = { login_token: loginToken };
    } else if (authToken) {
      authData = { openid_token: authToken };
    } else {
      authData = { email, password, code };
    }

    if (!this.sessionManager.isAuthenticated) {
      await this.sessionManager.authenticate('authenticator:custom', authData);
    }

    return await this.loadUser();
  }

  /**
   * Load the informations of a user
   *
   * @private
   * @method loadUser
   *
   * @returns {Object} User object
   */
  async loadUser() {
    let user = await this.store.queryRecord('user', {});
    await this._setCurrentUser(user);
    return user;
  }

  /**
   * Find the Membership linking the current user to an organization
   *
   * @public
   * @method findOrganizationMembership
   *
   * @params {Organization} An Organization
   * @return {Membership} The membership linking the current user to
   * the given organization or null if no Membership if found
   */
  async findOrganizationMembership(organization) {
    let user = this.currentUser;
    let userMemberships = await this.store.query('membership', { user_id: user.id });
    return userMemberships
      ? userMemberships.find(item => get(item, 'organization.id') === organization.id)
      : null;
  }

  /**
   * Set the current user
   *
   * @private
   * @method _setCurrentUser
   *
   * @param  {Object} user
   * @returns void
   */
  async _setCurrentUser(user) {
    this.currentUser = user;

    let { id, locale } = user;

    if (this.launchdarkly.isInitialized) {
      await this.launchdarkly.identify({
        userId: id,
        customData: {},
      });
    }

    Sentry.configureScope(scope => scope.setUser({ id }));

    this.notifierManager.connect(this.websocketToken, id);

    await this.localeManager.setLocale(locale);
    this.tandem.setLocale(locale);
    this.tandem.setUserId(id);
  }
}
