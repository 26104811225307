import { computed } from '@ember/object';
import { readOnly } from '@ember/object/computed';

import { buildValidations, validator } from 'ember-cp-validations';

import { DEBOUNCE_MS } from 'qonto/constants/timers';

const MembershipValidations = buildValidations(
  {
    monthlyTransferLimit: {
      validators: [
        validator('ds-error'),
        validator('number', {
          value(model) {
            return model.monthlyTransferLimit?.value;
          },
          dependentKeys: ['model.monthlyTransferLimit.value'],
          debounce: 0,
          gt: 0,
          allowString: true,
          messageKey: 'invitation-flow.expense-permissions.transfers.errors.monthly-eq-zero',
        }),
        validator('number', {
          value(model) {
            return model.monthlyTransferLimit?.value;
          },
          debounce: 0,
          dependentKeys: ['model.monthlyTransferLimit.value', 'model.perTransferLimit.value'],
          gte: readOnly('model.perTransferLimit.value'),
          allowString: true,
          messageKey: 'invitation-flow.expense-permissions.transfers.errors.too-low-monthly',
        }),
      ],
    },

    perTransferLimit: {
      validators: [
        validator('ds-error'),
        validator('number', {
          value(model) {
            return model.perTransferLimit?.value;
          },
          dependentKeys: ['model.perTransferLimit.value'],
          debounce: 0,
          gt: 0,
          allowString: true,
          messageKey: 'invitation-flow.expense-permissions.transfers.errors.per-transfer-eq-zero',
        }),
        validator('number', {
          value(model) {
            return model.perTransferLimit?.value;
          },
          debounce: 0,
          dependentKeys: ['model.monthlyTransferLimit.value', 'model.perTransferLimit.value'],
          disabled: computed(
            'model.{perTransferLimit.value,monthlyTransferLimit.value}',
            function () {
              let monthlyLimit = this.model.get('monthlyTransferLimit.value') ?? null;
              let perTransferLimit = this.model.get('perTransferLimit.value') ?? null;

              return monthlyLimit === null || perTransferLimit === null;
            }
          ),
          lte: readOnly('model.monthlyTransferLimit.value'),
          allowString: true,
          messageKey: 'invitation-flow.expense-permissions.transfers.errors.too-great-per-transfer',
        }),
      ],
    },
    nationality: {
      validators: [
        validator('ds-error'),
        validator('presence', {
          presence: true,
          messageKey: 'validations.errors.blank',
        }),
      ],
    },
  },
  {
    debounce: DEBOUNCE_MS,
  }
);

export default MembershipValidations;
