import { computed } from '@ember/object';

import { buildValidations, validator } from 'ember-cp-validations';

const AddressValidations = buildValidations({
  firstLine: {
    validators: [
      validator('ds-error'),
      validator('presence', {
        presence: true,
        message: computed('model.intl.locale', function () {
          return this.model.intl.t('validations.errors.blank');
        }),
      }),
      validator('length', { min: 2 }),
    ],
  },
  secondLine: {
    validators: [validator('ds-error')],
  },
  zipcode: {
    validators: [
      validator('ds-error'),
      validator('presence', {
        presence: true,
        message: computed('model.intl.locale', function () {
          return this.model.intl.t('validations.errors.blank');
        }),
      }),
      validator('length', { min: 2 }),
    ],
  },
  city: {
    validators: [
      validator('ds-error'),
      validator('presence', {
        presence: true,
        message: computed('model.intl.locale', function () {
          return this.model.intl.t('validations.errors.blank');
        }),
      }),
    ],
  },
  country: {
    validators: [
      validator('ds-error'),
      validator('presence', {
        presence: true,
        message: computed('model.intl.locale', function () {
          return this.model.intl.t('validations.errors.blank');
        }),
      }),
    ],
  },
});

export default AddressValidations;
