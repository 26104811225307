import ApplicationSerializer from './application';

export default class RequestMultiTransferTransferSerializer extends ApplicationSerializer {
  modelNameFromPayloadKey(payloadKey) {
    if (payloadKey === 'transfers') {
      return 'request-multi-transfer-transfer';
    }

    return super.modelNameFromPayloadKey(...arguments);
  }
}
