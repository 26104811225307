const MAX_QUEUE_SIZE = 50;

export default class EventsQueue {
  maxSize = MAX_QUEUE_SIZE;

  events = [];

  constructor(maxSize = MAX_QUEUE_SIZE) {
    this.maxSize = maxSize;
  }

  push(event) {
    if (this.size < this.maxSize) {
      this.events.push(event);
    }
  }

  flush() {
    this.events.forEach(event => event());
    this.clear();
  }

  clear() {
    this.events = [];
  }

  get size() {
    return this.events.length;
  }
}
