import { variation } from 'ember-launch-darkly';

import { safeLocalStorage } from 'qonto/helpers/safe-local-storage';

/**
 * @typedef {import('./config-types').TopBannerConfig} TopBannerConfig
 */

/**
 * @type {Array<TopBannerConfig>}
 *
 * Expected configuration coming from LaunchDarkly:
 *
 * {
 *   "message": {
 *     "en": "",
 *     "fr": "",
 *     "de": "",
 *     "es": "",
 *     "it": "",
 *     "pt": "",
 *   },
 *   "id": "unique-key",
 * }
 */
export const TEMPORARY_ANNOUNCEMENT_CONFIG = [
  {
    id: 'temporary-announcement',
    type: 'warning',
    componentName: 'topbar/temporary-announcement',
    model: ({ localeManager }) => {
      return {
        data: variation('operational--temporary-top-banner'),
        localeManager,
      };
    },
    message: ({ data, localeManager }) => {
      return data.message[localeManager.locale];
    },
    shouldDisplay: ({ data }) => {
      let hasMessage = Boolean(data?.message);
      let wasDismissed = Boolean(safeLocalStorage.getItem(data.id));

      return hasMessage && !wasDismissed;
    },
    extraProps: ({ data }) => ({ bannerId: data.id }),
  },
];
