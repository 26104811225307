// @ts-nocheck
import { METER_AMOUNTS_THRESHOLDS } from 'qonto/constants/budget';

interface CardLimit {
  atm_monthly_limit_maximum: number;
  extended_payment_monthly_limit_maximum: number;
  payment_monthly_limit_maximum: number;
}

type CardsLimits = {
  [key in string]: CardLimit;
};

export function getPaymentMonthlyLimitsMaximum(
  cardLevel: string,
  cardsLimits: CardsLimits
): number {
  return cardsLimits[cardLevel].payment_monthly_limit_maximum;
}

export function getExtendedPaymentMonthlyLimitsMaximum(
  cardLevel: string,
  cardsLimits: CardsLimits
): number {
  return cardsLimits[cardLevel].extended_payment_monthly_limit_maximum;
}

export function getAtmMonthlyLimitsMaximum(cardLevel: string, cardsLimits: CardsLimits): number {
  return cardsLimits[cardLevel].atm_monthly_limit_maximum;
}

// Given a monthly limit and a monthly spent amount, return the available amount for the month
export function getAvailableThisMonth(monthlyLimit: number, monthlySpent: number): number {
  return monthlyLimit - monthlySpent;
}

// Given a daily/monthly limit and a daily/monthly spent amount, return the available amount for the day
export function getAvailableToday(
  dailyLimit: number,
  dailySpent: number,
  availableThisMonth: number
): number {
  let availableToday = dailyLimit - dailySpent;
  return availableToday < availableThisMonth ? availableToday : availableThisMonth;
}

// Given a monthly limit and a monthly spent amount, return if a certain %age of the budget is reached
export function isMonthlyLimitClose(monthlyLimit: number, monthlySpent: number): boolean {
  let percent = (monthlySpent / monthlyLimit) * 100 || 0;
  return percent >= METER_AMOUNTS_THRESHOLDS.LOW;
}
