// @ts-nocheck
import { ForbiddenError, NotFoundError } from '@ember-data/adapter/error';
import { assert } from '@ember/debug';
import Service, { service } from '@ember/service';
import { camelize } from '@ember/string';
import { waitFor } from '@ember/test-waiters';
import { cached, tracked } from '@glimmer/tracking';

import { isNil } from 'es-toolkit';
import { apiBaseURL, billingNamespace } from 'qonto/constants/hosts';
import {
  SUBSCRIPTION_PRODUCT_TYPES,
  SUBSCRIPTION_RECURRENCES,
} from 'qonto/constants/subscriptions';
import { ignore404 } from 'qonto/utils/ignore-error';

/**
 * Subscription manager
 *
 * @class SubscriptionManagerService
 * @module qonto/services/subscription-manager
 * @extends Ember.Service
 */

export default class SubscriptionManagerService extends Service {
  @service organizationManager;
  @service segment;
  @service abilities;
  @service sentry;
  @service store;
  @service intl;
  @service flowLinkManager;
  @service networkManager;
  @service toastFlashMessages;

  @tracked subscriptions = null;
  @tracked _currentSubscription = null;
  @tracked _currentPricePlan = null;
  @tracked subscriptionFeatures = null;
  @tracked subscriptionOptions = null;

  hasFeature(featureCode) {
    return Boolean(this.subscriptionFeatures?.find(({ code }) => code === featureCode));
  }

  @waitFor
  async upgradeRecommendation(featureCode) {
    return this.store.adapterFor('organization-subscription').upgradeRecommendation(featureCode);
  }

  @waitFor
  async hasUpgrade(featureCode) {
    try {
      await this.upgradeRecommendation(featureCode);
    } catch (error) {
      if (error instanceof NotFoundError || error instanceof ForbiddenError) {
        return false;
      }

      throw error;
    }

    return true;
  }

  getLimitByFeatureCode(featureCode) {
    let limit = this.getLimitObjectByFeatureCode(featureCode);
    return isNil(limit) ? limit : limit?.value;
  }

  getLimitObjectByFeatureCode(featureCode) {
    let feature = this.subscriptionFeatures?.find(({ code }) => code === featureCode);
    return feature === undefined ? undefined : feature.limit;
  }

  @waitFor
  async loadSubscription(organization) {
    if (organization.underRegistration) return;

    return await this.store.query(
      'organization-subscription',
      {
        includes: ['product'],
      },
      {
        adapterOptions: {
          headers: {
            ['X-Qonto-Organization-ID']: organization.id,
          },
        },
      }
    );
  }

  @cached
  get features() {
    return (
      this.subscriptionFeatures?.reduce((acc, { code }) => {
        acc[camelize(code)] = true;
        return acc;
      }, {}) || {}
    );
  }

  @cached
  get options() {
    return (
      this.subscriptionOptions?.reduce((acc, { code, price }) => {
        acc[camelize(code)] = price;
        return acc;
      }, {}) || {}
    );
  }

  async refresh(organization = this.organizationManager.organization) {
    // Ignore 404 failure on GET /subscriptions
    // it might not be created yet

    this.subscriptions = await this.loadSubscription(organization).catch(ignore404);

    if (!organization.underRegistration) {
      let [subscriptionFeatures, options] = await Promise.all([
        this.store.query('subscriptions-feature', {}),
        this.store.query('subscriptions-option', {}),
      ]);

      this.subscriptionFeatures = subscriptionFeatures;
      this.subscriptionOptions = options;
    } else {
      this.subscriptionFeatures = null;
      this.subscriptionOptions = null;
    }

    this.resetTracking();
  }

  resetTracking() {
    let { currentSubscription, currentPricePlan } = this;
    this.segment.identify({
      freeTrialCurrent: currentSubscription?.activeTrial ? currentPricePlan?.get('code') : 'none',
      freeTrialEligibility: currentSubscription?.availableTrials?.length
        ? currentSubscription?.availableTrials?.map(({ productCode }) => productCode)
        : 'none',
    });
  }

  setTrackingUserProperties() {
    this.segment.identify({
      expense_spend_management: this.getProduct('expense_spend_management')?.recurrence || null,
      accounts_payable: this.getProduct('accounts_payable')?.recurrence || null,
      accounts_receivable: this.getProduct('accounts_receivable')?.recurrence || null,
    });
  }

  get currentSubscription() {
    return this.subscriptions?.find(({ product }) => !product.isAddon);
  }

  set currentSubscription(subscription) {
    this._currentSubscription = subscription;
  }

  get currentPricePlan() {
    return this.currentSubscription?.product;
  }

  set currentPricePlan(plan) {
    this._currentPricePlan = plan;
  }

  get currentAddons() {
    return this.subscriptions?.filter(({ product }) => product.isAddon);
  }

  get shouldHideUpsell() {
    // eslint-disable-next-line no-restricted-syntax -- This is a temporary check, see linked MR for more context
    return this.currentPricePlan?.groupCode === 'light';
  }

  getProduct(code) {
    return this.subscriptions?.find(({ product }) => product.code === code);
  }

  hasProductAndRecurrence(code, recurrence) {
    return (
      this.getProduct(code) &&
      this.subscriptions?.find(({ product }) => product.code === code).recurrence === recurrence
    );
  }

  hasAvailableTrialProduct(code, rec) {
    return this.currentSubscription?.availableTrials.find(
      ({ productCode, recurrence }) => productCode === code && recurrence === rec
    );
  }

  hasAddon(addonCode) {
    return this.currentAddons?.some(addon => addon.product?.groupCode === addonCode);
  }

  /**
   * Retrieve subscription's pricePlan from an organization identified by its slug
   *
   * @public
   * @method getSubscriptionPricePlanFor
   *
   * @param {String} slug
   * @returns  {Promise.<PricePlan|undefined>}
   */
  async getSubscriptionPricePlanFor(slug) {
    let organization = await this.organizationManager.getOrganizationBySlug(slug);
    let subscriptions = await this.loadSubscription(organization);

    return subscriptions?.find(({ product }) => !product.isAddon)?.product;
  }

  get nextRecurrenceDate() {
    return this.currentSubscription.nextRecurrenceDate;
  }

  get nextInvoicingDate() {
    return this.currentSubscription.nextInvoicingDate;
  }

  /**
   * Has the current subscriptions max number of bank accounts been reached?
   *
   * @public
   * @method hasReachedBankAccountLimit
   *
   * @return {Boolean}
   */
  get hasReachedBankAccountLimit() {
    let { length } = this.organizationManager.organization.activeOrPendingBankAccounts;
    let limit = this.getLimitByFeatureCode('bank_account');
    return limit !== null && length >= limit;
  }

  /**
   * Has the current subscription max number of team members been reached?
   *
   * @public
   * @method hasReachedUserLimit
   *
   * @return {Boolean}
   */
  get hasReachedUserLimit() {
    let { organization } = this.organizationManager;
    let userLimit = this.getLimitByFeatureCode('additional_users');
    return userLimit !== null && organization.membershipsCountingTowardsPlanLimitCount >= userLimit;
  }

  /**
   * Has the current subscription max number of accountants been reached?
   *
   * @public
   * @method hasReachedAccountantLimit
   *
   * @return {Boolean}
   */
  get hasReachedAccountantLimit() {
    let { organization } = this.organizationManager;
    let accountantLimit = this.getLimitByFeatureCode('accountant_access');

    return (
      accountantLimit !== null &&
      organization.accountantsCountingTowardsPlanLimitCount >= accountantLimit
    );
  }

  /**
   * Do we need to upgrade in order to invite more members?
   *
   * @public
   * @method planUpgradeIsNeeded
   *
   * @return {Boolean}
   */
  get planUpgradeIsNeeded() {
    assert('currentPricePlan should have been initialized first', this.currentPricePlan);

    return (
      this.hasReachedUserLimit &&
      this.hasReachedAccountantLimit &&
      this.abilities.cannot('create paid members members')
    );
  }

  get planPriceWithVAT() {
    let recurrence = this.currentSubscription.recurrence;

    if (recurrence === SUBSCRIPTION_RECURRENCES.ANNUAL) {
      return this.currentSubscription.productAnnualPriceVatIncluded;
    }
    return this.currentSubscription.productMonthlyPriceVatIncluded;
  }

  get isCurrentPlanFree() {
    return this.planPriceWithVAT.value === '0';
  }

  @cached
  get directDebitCollectionFee() {
    return this.intl.formatNumber(this.options.directDebitCollectionSend.value, {
      style: 'currency',
      currency: 'EUR',
    });
  }

  async upgradeWithRecommendation(featureName, { queryParams = {} } = {}) {
    assert('featureName should be provided to upgradeWithRecommendation', featureName);

    let {
      recommended_recurrence: recurrence,
      recommended_product: { code, type },
    } = await this.networkManager.request(
      `${apiBaseURL}/${billingNamespace}/subscriptions/upgrade_recommendation?feature_code=${featureName}`
    );

    this.transitionToSubscriptionOrAddonChange({
      code,
      queryParams,
      recurrence,
      type,
    });
  }

  transitionToSubscriptionOrAddonChange({ code, queryParams, recurrence, type }) {
    assert('code should be provided to transitionToSubscriptionOrAddonChange', code);
    assert('recurrence should be provided to transitionToSubscriptionOrAddonChange', recurrence);
    assert('type should be provided to transitionToSubscriptionOrAddonChange', type);

    if (type === SUBSCRIPTION_PRODUCT_TYPES.ADDON) {
      this.flowLinkManager.transitionTo({
        name: 'addon-change',
        stepId: 'addons',
        queryParams: {
          ...queryParams,
          addon: code,
          addon_recurrence: recurrence,
        },
      });
    } else if (type === SUBSCRIPTION_PRODUCT_TYPES.PLAN) {
      this.flowLinkManager.transitionTo({
        name: 'subscription-change',
        stepId: 'plans',
        queryParams: {
          ...queryParams,
          plan: code,
          recurrence,
        },
      });
    }
  }
}

declare module '@ember/service' {
  interface Registry {
    'subscription-manager': SubscriptionManagerService;
  }
}
