import { buildValidations, validator } from 'ember-cp-validations';

import { DEBOUNCE_MS } from 'qonto/constants/timers';

const ExerciseValidations = buildValidations(
  {
    startDate: [validator('ds-error'), validator('presence', true)],
    endDate: [validator('ds-error'), validator('presence', true)],
    periods: [validator('has-many'), validator('length', { min: 1 })],
    totalBudgeted: [validator('number', { gt: 0 })],
  },
  {
    debounce: DEBOUNCE_MS,
  }
);

export default ExerciseValidations;
