import ENV from 'qonto/config/environment';
import { HPKE_CARDS_BE_PUBLIC_KEYS_TABLE } from 'qonto/constants/encryption';

const { backendUrlMode } = ENV;

function _getHpkeCardsBackendPublicKey() {
  return HPKE_CARDS_BE_PUBLIC_KEYS_TABLE[backendUrlMode];
}

// For test and mock purpose only
// see https://sinonjs.org/how-to/typescript-swc/
export let getHpkeCardsBackendPublicKey = _getHpkeCardsBackendPublicKey;
// for sinon auto-cleanup
export const hpkeCardsBackendPublicKeyMock = {
  set getHpkeCardsBackendPublicKey(mockImplementation) {
    getHpkeCardsBackendPublicKey = mockImplementation;
  },
  get getHpkeCardsBackendPublicKey() {
    return _getHpkeCardsBackendPublicKey;
  },
};
