import { SORT_ORDER } from 'qonto/constants/sort';

/**
 * Callback method for Array.sort()
 * Sorts an array by a given key and order (default ascending)
 * @param {string} key Key to sort on
 * @param {SORT_ORDER.ASC | SORT_ORDER.DEC} order Sorting order (SORT_ORDER constant)
 * @returns Array sorted on the given key and order
 */
export const sortByKey = (key, order = SORT_ORDER.ASC) => {
  let direction = order === SORT_ORDER.ASC ? 1 : -1;
  return (a, b) => (a[key] > b[key] ? direction : a[key] < b[key] ? -direction : 0);
};

/**
 * Callback method for Array.sort()
 * Sorts an array by given keys (ascending)
 * @param {Array<string>} keys
 * @returns Array sorted on the given keys
 */
export const sortByKeys = keys => (a, b) =>
  keys
    .map(o => {
      return a[o] > b[o] ? 1 : a[o] < b[o] ? -1 : 0;
    })
    .reduce((p, n) => (p ? p : n), 0);
