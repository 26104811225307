import { FR_CREDITORS_CODE } from 'qonto/constants/mandates';

type CreditorCode = (typeof FR_CREDITORS_CODE)[keyof typeof FR_CREDITORS_CODE];

const DEFAULT_DELIM = ' ';
const { DGFIP, SIE, URSSAF } = FR_CREDITORS_CODE;

const UMR_PATTERNS: Record<CreditorCode, RegExp> = {
  [DGFIP]: /^(.{0,2})(.{0,5})(.{0,4})(.{0,5})(.{0,4})(.{0,5})(.{0,4})(.{0,5})/,
  [SIE]: /^(.{0,2})(.{0,5})(.{0,4})(.{0,5})(.{0,4})(.{0,5})(.{0,4})(.{0,5})(.?)/,
  [URSSAF]: /^(.{0,5})(.{0,5})(.{0,5})(.{0,5})(.{0,5})(.{0,5})(.{0,5})/,
};

export function setCaretPosition(
  element: HTMLInputElement,
  previousValue: string,
  newValue: string
): void {
  let { selectionEnd } = element;

  let previousPosition = selectionEnd;
  let newPosition = previousPosition;

  if (previousValue.length === previousPosition) {
    newPosition = newValue.length;
  }

  // To avoid caret jumping when editing value not at the end
  element.value = newValue;

  // Set the caret position
  element.setSelectionRange(newPosition, newPosition);
}

function setRLMCCaretPosition(
  element: HTMLInputElement,
  previousValue: string,
  newValue: string
): void {
  let { selectionEnd } = element;

  let isAlreadyFormatted = /\(\d+\)/.test(previousValue);
  let newPosition = isAlreadyFormatted ? (selectionEnd ?? 0) : (selectionEnd ?? 0) + 1;

  // To avoid caret jumping when editing value not at the end
  element.value = newValue;

  // Set the caret position
  element.setSelectionRange(newPosition, newPosition);
}

function getFormattedValue(rawValue: string, formatRegex: RegExp, delim = DEFAULT_DELIM): string {
  return rawValue?.replace(formatRegex, function () {
    let capturedGroups = [...arguments].slice(1, arguments.length - 2);
    return [...capturedGroups].filter(o => o).join(delim);
  });
}

export function maskInput(input: string, mask = 'X', padLeft = 4, padRight = 3): string {
  if (!input || padLeft + padRight >= input.length) {
    return input;
  }

  input = input.replace(/[^a-zA-Z0-9•]/g, '');

  return `${input.slice(0, padLeft)}${mask.repeat(
    input.length - (padLeft + padRight)
  )}${input.slice(input.length - padRight)}`;
}

export function formatIban(iban: string, isIBANObfuscated: boolean): string {
  if (!iban) {
    return iban;
  }

  let sanitizedIban = iban
    .replace(/[^a-zA-Z0-9•]/g, '') //!\ In the transfer request controller, IBANs are masked using dots
    .toUpperCase()
    .substring(0, 34)
    .replace(/(.{4})/g, `$1 `)
    .trim();

  return isIBANObfuscated ? sanitizedIban.replace(/x/gi, '•') : sanitizedIban;
}

export function formatBIC(bic: string): string {
  return bic
    ?.replace(/[^a-zA-Z0-9]/g, '')
    .toUpperCase()
    .trim();
}

export function formatSortCode(sortCode: string): string {
  if (!sortCode) {
    return sortCode;
  }

  let sanitizedSortCode = sortCode.toUpperCase().replace(/[\W_]/g, '').substring(0, 6);
  return getFormattedValue(sanitizedSortCode, /(.{0,2})(.{0,2})(.{0,2})/, '-');
}

export function formatUmr(umr: string, creditorCode: CreditorCode): string {
  if (!umr) {
    return umr;
  }

  const checkCodes: CreditorCode[] = [DGFIP, SIE];

  let maxLength = checkCodes.includes(creditorCode) ? 34 : 35;

  if (![DGFIP, SIE, URSSAF].includes(creditorCode)) {
    return umr
      .toUpperCase()
      .replace(/[^A-Z0-9/\-?:().,'+\s]/g, '')
      .substring(0, maxLength);
  }

  let sanitizedUmr = umr
    .toUpperCase()
    .replace(/[^A-Z0-9/\-?:().,'+]/g, '')
    .substring(0, maxLength);

  return getFormattedValue(sanitizedUmr, UMR_PATTERNS[creditorCode]);
}

export function formatIcs(ics: string, creditorCode: CreditorCode): string {
  if (!ics) {
    return ics;
  }

  let isFrGouvCreditor = [DGFIP, SIE, URSSAF].includes(creditorCode);
  let maxLength = isFrGouvCreditor ? 13 : 35;

  let sanitizedIcs = ics
    .toUpperCase()
    .replace(/[^A-Z0-9/\-?:().,'+]/g, '')
    .substring(0, maxLength);

  if (!isFrGouvCreditor) {
    return sanitizedIcs;
  }

  return getFormattedValue(sanitizedIcs, /(.{0,2})(.{0,2})(.{0,3})(.{0,3})(.{0,3})/);
}

export function formatCheckCMC7(cmc7: string): string {
  let sanitizedValue = cmc7?.replace?.(/\D/g, '').substring(0, 31);
  return getFormattedValue(sanitizedValue, /(\d{0,7})(\d{0,12})(\d{0,12})/);
}

export function formatCheckRLMC(rlmc: string): string {
  let sanitizedValue = rlmc?.replace?.(/\D/g, '').substring(0, 2);

  // When deleting the last char which is the closing ")" we need to remove the last digit as well
  if (/^\(\d+$/.test(rlmc)) {
    sanitizedValue = sanitizedValue?.slice(0, -1);
  }

  return sanitizedValue?.replace(/(\d{1,2})/, '($1)');
}

export function formatPagopaNoticeNumber(noticeNumber: string): string {
  return noticeNumber
    ?.replace(/[^a-zA-Z0-9]/g, '')
    .replace(/(.{4})/g, `$1 `)
    .trim();
}

export function handleFormatUmr(element: HTMLInputElement, creditorCode: CreditorCode): void {
  let { value } = element;
  let newValue = formatUmr(value, creditorCode);
  setCaretPosition(element, value, newValue);
}

export function handleFormatIcs(element: HTMLInputElement, creditorCode: CreditorCode): void {
  let { value } = element;
  let newValue = formatIcs(value, creditorCode);
  setCaretPosition(element, value, newValue);
}

export function handleFormatIban(element: HTMLInputElement, isIBANObfuscated: boolean): void {
  let { value } = element;
  let newValue = formatIban(value, isIBANObfuscated);
  setCaretPosition(element, value, newValue);
}

export function handleFormatBIC(element: HTMLInputElement) {
  let { value } = element;
  let newValue = formatBIC(value);
  setCaretPosition(element, value, newValue);
}

export function handleFormatSortCode(element: HTMLInputElement) {
  let { value } = element;
  let newValue = formatSortCode(value);
  setCaretPosition(element, value, newValue);
}

export function handleFormatCheckCMC7(element: HTMLInputElement): void {
  let { value } = element;
  let newValue = formatCheckCMC7(value);
  setCaretPosition(element, value, newValue);
}

export function handleFormatCheckRLMC(element: HTMLInputElement): void {
  let { value } = element;
  let newValue = formatCheckRLMC(value);
  setRLMCCaretPosition(element, value, newValue);
}

export function handleFormatPagopaNoticeNumber(element: HTMLInputElement): void {
  let { value } = element;
  let newValue = formatPagopaNoticeNumber(value);
  setCaretPosition(element, value, newValue);
}
