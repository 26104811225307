import { buildValidations, validator } from 'ember-cp-validations';

const ExportTemplateValidations = buildValidations({
  name: [
    validator('ds-error'),
    validator('presence', {
      presence: true,
      messageKey: 'validations.errors.presence',
    }),
    validator('length', {
      max: 50,
      messageKey: 'transactions.exports.custom-template.validations.character-limitation',
    }),
    validator('unique-attribute', {
      messageKey: 'transactions.exports.custom-template.validations.duplicate',
    }),
  ],
});

export default ExportTemplateValidations;
