import { buildValidations, validator } from 'ember-cp-validations';

const password = [
  validator('ds-error'),
  validator('presence', true),
  validator('zxcvbn'),
  validator('length', {
    min: 9,
    messageKey: 'validations.errors.password_length_min',
  }),
];

export const UserPasswordValidations = buildValidations({
  password,
});

const UserValidations = buildValidations({
  code: [
    validator('presence', true),
    validator('length', {
      is: 6,
    }),
  ],
  email: [
    validator('presence', { presence: true, messageKey: 'sign_in.password-reset-error-blank' }),
    validator('format', {
      type: 'email',
      minTldLength: 2,
      messageKey: 'sign_in.password-reset-error-address-email-blank',
    }),
  ],
  pendingEmail: [
    validator('presence', true),
    validator('format', {
      type: 'email',
      minTldLength: 2,
    }),
  ],
  password,
  gender: [validator('presence', true)],
  birthCountry: [validator('presence', true)],
  birthCity: [validator('presence', true)],
  phoneNumber: [validator('presence', true)],
  passwordConfirmation: [
    validator('confirmation', {
      on: 'password',
      messageKey: 'validations.errors.password_do_not_match',
    }),
  ],
  birthdate: {
    validators: [
      validator('ds-error'),
      validator('presence', {
        presence: true,
        messageKey: 'validations.errors.blank',
      }),
      validator('date', {
        after: '1/1/1900',
        format: 'DD/MM/YYYY',
        messageKey: 'validations.errors.invalid_date',
      }),
    ],
  },
});

export default UserValidations;
