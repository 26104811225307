import ApplicationSerializer from 'qonto/serializers/application';

export default ApplicationSerializer.extend({
  primaryKey: 'bulk_id',

  serialize(snapshot, options) {
    let payload = this._super(snapshot, options);
    let json = Object.assign({}, payload);

    if (json.email === '') {
      json.email = null;
    }

    return json;
  },
});
