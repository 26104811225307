/**
 * Retrieve all unique properties from filters groups
 * Keyword 'label' is used when property value matches with the labelList id
 * @param {Array.<Object>} filtersGroupExpressions
 * @param {Array.<Object>} labelLists
 * @returns { Array.<String> }
 */

export function getFilterGroupExpressions(filtersGroupExpressions = [], labelLists = []) {
  let filtersProperties = new Set();

  function processExpressions(groupExpressions) {
    groupExpressions.forEach(({ expressions, property }) => {
      if (expressions) {
        processExpressions(expressions);
      } else if (property) {
        if (labelLists.some(({ id }) => id === property)) {
          property = 'label';
        }

        filtersProperties.add(property);
      }
    });
  }

  filtersGroupExpressions
    .filter(Boolean)
    .forEach(({ expressions }) => processExpressions(expressions));

  return Array.from(filtersProperties);
}
