import ApplicationSerializer from 'qonto/serializers/application';

export default class TeamSerializer extends ApplicationSerializer {
  // The `DELETE /v3/teams/:id` endpoint responds with `{ "message": "Success" }`
  // if a team is deleted. Ember Data interprets `message` as an API resource
  // and then complains that it can't find a `message` Ember Data model in the
  // app. The following message fixes the warning by explicitly ignoring the
  // success payload.
  normalizeDeleteRecordResponse(store, primaryModelClass, payload) {
    if (payload?.message === 'Success') return { data: null };

    return super.normalizeDeleteRecordResponse(...arguments);
  }
}
