import JSONAPISerializer from 'qonto/serializers/-json-api';

export default class UserActionV2Serializer extends JSONAPISerializer {
  normalize(model, hash) {
    // The ID has to be unique for each EmberData record,
    // so we first backup the original id, and then we set a new one
    hash.attributes.original_id = hash.id;
    hash.id = `${hash.relationships.membership.data.id}_${hash.attributes.key}`;

    return super.normalize(model, hash);
  }

  modelNameFromPayloadKey(payloadKey) {
    if (payloadKey === 'user_actions') {
      return 'user-action-v2';
    }
    return super.modelNameFromPayloadKey(...arguments);
  }
}
