import { computed } from '@ember/object';

import { buildValidations, validator } from 'ember-cp-validations';

const MandatesDetailsValidations = buildValidations({
  'model.umr': [
    validator('presence', true),
    validator('length', {
      disabled: computed('model.taxInstitutionCode', function () {
        return (
          this.model.taxInstitutionCode !== 'dgfip' &&
          this.model.taxInstitutionCode !== 'taxDepartment'
        );
      }),
      isWarning: true,
      is: 34,
      allowBlank: true,
      count: '34',
      messageKey: 'validations.errors.mandates.mandate-details.umr-warning',
    }),
    validator('length', {
      disabled: computed('model.taxInstitutionCode', function () {
        return this.model.taxInstitutionCode !== 'urssaf';
      }),
      isWarning: true,
      is: 35,
      allowBlank: true,
      count: '35',
      messageKey: 'validations.errors.mandates.mandate-details.umr-warning',
    }),
  ],
  'model.creditorIdentifier': [
    validator('presence', true),
    validator('length', {
      disabled: computed('model.taxInstitutionCode', function () {
        return (
          this.model.taxInstitutionCode !== 'dgfip' &&
          this.model.taxInstitutionCode !== 'taxDepartment' &&
          this.model.taxInstitutionCode !== 'urssaf'
        );
      }),
      isWarning: true,
      is: 13,
      allowBlank: true,
      messageKey: 'validations.errors.mandates.mandate-details.ics-warning',
    }),
    validator('length', {
      // Validates that german ics are exactly 18 chars long
      disabled: computed('model.model.creditorIdentifier', function () {
        return !/^de/i.test(this.model.model.creditorIdentifier);
      }),
      is: 18,
      allowBlank: true,
      messageKey: 'validations.errors.mandates.mandate-details.ics-german-length-error',
    }),
    validator('length', {
      min: 8,
      allowBlank: true,
      messageKey: 'validations.errors.mandates.mandate-details.ics-error',
    }),
  ],
});

export default MandatesDetailsValidations;
