import dayjs from 'dayjs';

import { DATE_PICKER_FIELD_FORMAT } from 'qonto/constants/dates';

export function getCurrentParisDate() {
  let parisDate = dayjs().tz('Europe/Paris').format();
  return dayjs(parisDate).toDate();
}

export function getCurrentParisDateString() {
  let parisDate = dayjs().tz('Europe/Paris').format();
  return dayjs(parisDate).format(DATE_PICKER_FIELD_FORMAT);
}

export function isPeriodWithinInterval(period, interval) {
  return (
    dayjs(period.startDate).isBetween(interval.start, interval.end, 'day', '[]') &&
    dayjs(period.endDate).isBetween(interval.start, interval.end, 'day', '[]')
  );
}

export function addDaysToToday(daysToAdd) {
  let today = dayjs();
  let newDate = today.add(daysToAdd, 'day');
  return newDate.toDate();
}

export function subDaysToToday(daysToSub) {
  let today = dayjs();
  let newDate = today.subtract(daysToSub, 'day');
  return newDate.toDate();
}

export function addBusinessDays(date, daysToAdd) {
  let newDate = dayjs(date);
  let addedDays = 0;

  while (addedDays < daysToAdd) {
    newDate = newDate.add(1, 'day');
    if (newDate.day() !== 0 && newDate.day() !== 6) {
      // Skip weekends
      addedDays++;
    }
  }

  return newDate.toDate();
}

export function subBusinessDays(date, daysToSub) {
  let newDate = dayjs(date);
  let subDays = 0;

  while (subDays < daysToSub) {
    newDate = newDate.subtract(1, 'day');
    if (newDate.day() !== 0 && newDate.day() !== 6) {
      // Skip weekends
      subDays++;
    }
  }

  return newDate.toDate();
}

export function eachMonthOfInterval(start, end) {
  let startDate = dayjs(start);
  let endDate = dayjs(end);
  let months = [];

  let currentMonth = startDate.startOf('month');

  while (currentMonth.isBefore(endDate) || currentMonth.isSame(endDate, 'month')) {
    months.push(currentMonth.toDate());
    currentMonth = currentMonth.add(1, 'month');
  }

  return months;
}

export function differenceInCalendar(end, start, unit) {
  if (start === undefined || end === undefined) return NaN;
  let startOfUnit = unit;
  // This is required as startOf is locale dependent, the first day of this week according to ISO 8601 is Monday
  // We currently don't have usage of week in codebase but this line is added to prevent future flakiness
  if (unit === 'week') startOfUnit = 'isoWeek';
  return dayjs(end).startOf(startOfUnit).diff(dayjs(start).startOf(startOfUnit), unit);
}

export function isDateInTheFuture(date) {
  let newDate = date ? dayjs(date) : dayjs();
  return newDate.isAfter(dayjs().toDate());
}
