import window from 'ember-window-mock';

import ENV from 'qonto/config/environment';

const runningOnSubdomain = window.location.href.includes('.dev.qonto.co');
const isAuthenticationShareable = ENV.environment === 'development' && runningOnSubdomain;

let cookieDomain = null;
if (ENV.deployTarget === 'staging') {
  cookieDomain = `.qonto.co`;
} else if (ENV.deployTarget === 'production') {
  cookieDomain = '.qonto.com';
} else if (isAuthenticationShareable) {
  cookieDomain = '.dev.qonto.co';
}

export default cookieDomain;
