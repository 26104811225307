import { getOwner } from '@ember/owner';
import Service, { service } from '@ember/service';

import { flowSetupClasses } from 'qonto/routes/flows/setup';

const FLOWS_ROUTE_NAME = 'flows';

export default class FlowLinkManagerService extends Service {
  @service flow;
  @service organizationManager;
  @service router;

  initialStepIdFor(name) {
    let FlowSetupClass = flowSetupClasses[name];
    return new FlowSetupClass(getOwner(this)).initialStepId;
  }

  urlFor(options) {
    let params = this.#getParams(options);
    return this.router.urlFor(...params);
  }

  transitionTo(options) {
    return this.#doTransition(options);
  }

  replaceWith(options) {
    return this.#doTransition(options, true);
  }

  #doTransition(options, replace = false) {
    let transitionFn = replace ? this.router.replaceWith : this.router.transitionTo;

    let params = this.#getParams(options);

    this.flow.clearStorage();
    return transitionFn.call(this.router, ...params);
  }

  #getParams({ name, stepId, queryParams = {} }) {
    let { slug: organizationSlug } = this.organizationManager.organization;
    let initialStepId = stepId ?? this.initialStepIdFor(name);

    return [FLOWS_ROUTE_NAME, organizationSlug, name, initialStepId, { queryParams }];
  }
}
