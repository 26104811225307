import { isTesting, macroCondition } from '@embroider/macros';
import { buildValidations, validator } from 'ember-cp-validations';

const LegalEntityValidations = buildValidations(
  {
    capitalAmount: [
      validator('ds-error'),
      validator('presence', {
        presence: true,
        messageKey: 'validations.errors.legal-entity.capital-amount',
      }),
      validator('number', {
        messageKey: 'validations.errors.legal-entity.capital-amount',
        allowString: true,
        gte: 1,
      }),
    ],
    legalName: [validator('ds-error'), validator('presence', true)],
    legalNumber: [
      validator('ds-error'),
      validator('presence', true),
      validator('length', {
        messageKey: 'validations.errors.legal-number',
        max: 20,
      }),
    ],
  },
  {
    debounce: macroCondition(isTesting()) ? 0 : 500,
  }
);

export default LegalEntityValidations;
