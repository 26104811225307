import dayjs from 'dayjs';
import locale_fr from 'dayjs/locale/fr';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import isBetween from 'dayjs/plugin/isBetween';
import isoWeek from 'dayjs/plugin/isoWeek';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isToday from 'dayjs/plugin/isToday';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import minMax from 'dayjs/plugin/minMax';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import relativeTime from 'dayjs/plugin/relativeTime';
import timezone from 'dayjs/plugin/timezone';
import updateLocale from 'dayjs/plugin/updateLocale';
import utc from 'dayjs/plugin/utc';
import 'dayjs/locale/de';
import 'dayjs/locale/it';
import 'dayjs/locale/es';

export function setupDayjsAndOverrideLocales() {
  dayjs.extend(advancedFormat);
  dayjs.extend(isBetween);
  dayjs.extend(isSameOrAfter);
  dayjs.extend(isSameOrBefore);
  dayjs.extend(isoWeek);
  dayjs.extend(isToday);
  dayjs.extend(localizedFormat);
  dayjs.extend(minMax);
  dayjs.extend(quarterOfYear);
  dayjs.extend(relativeTime);
  dayjs.extend(timezone);
  dayjs.extend(updateLocale);
  dayjs.extend(utc);

  dayjs.updateLocale('en', {
    formats: {
      LL: 'MMM. D, YYYY',
      ll: "MMM 'YY",
      llll: 'ddd, MMM D, YYYY h:mm A',
    },
  });
  dayjs.updateLocale('fr', {
    formats: {
      ...locale_fr.formats,
      LL: 'D MMM YYYY',
      ll: 'MMM YY',
    },
  });

  dayjs.tz.setDefault('Europe/Paris');
}
