import { htmlSafe } from '@ember/template';

import { ORGA_STATUS, ORGA_SUSPENDED_REASON } from 'qonto/constants/organization';

/**
 * @typedef {import('./config-types').TopBannerConfig} TopBannerConfig
 */

/**
 * @type {Array<TopBannerConfig>}
 */
export const ITALY_ISSUES_CONFIG = [
  {
    id: 'italy-issues',
    type: 'warning',
    message: (_, intl) => {
      return intl.t('topbar_msg.it-bank-of-italy-issues', {
        link: htmlSafe(
          `<a href=${intl.t(
            'topbar_msg.it-bank-of-italy-issues-link-url'
          )} target="_blank" rel="noopener noreferrer">  ${intl.t(
            'topbar_msg.it-bank-of-italy-issues-link-text'
          )}</a>`
        ),
        htmlSafe: true,
      });
    },
    shouldDisplay: ({ organizationManager }) => {
      let organization = organizationManager.organization;
      let isSuspendedForExpiredDueDiligence =
        organization.status === ORGA_STATUS.SUSPENDED &&
        organization.suspensionReason === ORGA_SUSPENDED_REASON.EXPIRED_DUE_DILIGENCE;

      return (
        organization.legalCountry === 'IT' &&
        !organization.hasRestrictSensitiveActionsFeature &&
        !isSuspendedForExpiredDueDiligence
      );
    },
  },
];
