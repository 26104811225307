import { EmbeddedRecordsMixin } from '@ember-data/serializer/rest';

import ApplicationSerializer from 'qonto/serializers/application';
import { normalizeMissingBankAccount } from 'qonto/utils/normalize-missing-bank-account';

export default class CardSerializer extends ApplicationSerializer.extend(EmbeddedRecordsMixin) {
  attrs = {
    address: { embedded: 'always' },
  };

  serialize(snapshot, options) {
    let json = super.serialize(snapshot, options);

    if (json.is_qcp) {
      delete json.pin;
      delete json.pin_confirmation;
    }

    delete json.is_qcp;

    return json;
  }

  normalize(modelClass, payload) {
    normalizeMissingBankAccount(this.store, payload);
    return super.normalize(modelClass, payload);
  }
}
