/* eslint-disable ember/require-computed-property-dependencies */
import { computed } from '@ember/object';

import { buildValidations, validator } from 'ember-cp-validations';

const AllowedEmailValidations = buildValidations({
  email: [
    validator('ds-error'),
    validator('format', {
      type: 'email',
      disabled: computed('model.email', function () {
        return !this.model.email || !this.model.email.length;
      }),
      minTldLength: 2,
      messageKey: 'validations.errors.email',
    }),
    validator('presence', true),
  ],
});

export default AllowedEmailValidations;
