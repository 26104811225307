import Service from '@ember/service';

export default class KomboConnectService extends Service {
  async show(url) {
    if (!this._showKomboConnect) {
      let { showKomboConnect } = await import('@kombo-api/connect');
      this._showKomboConnect = showKomboConnect;
    }
    return this._showKomboConnect(url);
  }
}
