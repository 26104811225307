import isFunction from 'qonto/utils/is-function';

/**
 * Checks if obj is a thennable (a promise-like object)
 *
 * @param {*} obj
 * @returns {Boolean}
 */
export default function isThenable(obj) {
  return typeof obj === 'object' && obj !== null && isFunction(obj.then);
}
