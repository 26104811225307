import { service } from '@ember/service';

import BaseValidator from 'ember-cp-validations/validators/base';

const NoEmptySpace = BaseValidator.extend({
  intl: service(),

  validate(value, options = {}) {
    if (value && value.includes(' ')) {
      return options.messageKey
        ? this.intl.t(options.messageKey)
        : "Value shouldn't contain any spaces";
    }

    return true;
  },
});

export default NoEmptySpace;
