import BaseValidator from 'ember-cp-validations/validators/base';

const Pin = BaseValidator.extend({
  validate(pin) {
    let reason =
      pin && pin.length !== 4
        ? 'pin_invalid_length'
        : isNaN(Number(pin))
          ? 'pin_not_a_number'
          : pin === '0000' || pin === '1234'
            ? 'pin_cannot_be_0000_or_1234'
            : null;

    return reason ? this.createErrorMessage(reason, pin) : true;
  },
});

export default Pin;
