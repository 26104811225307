import JSONAPISerializer from 'qonto/serializers/-json-api';

export default class UserActionSerializer extends JSONAPISerializer {
  normalize(model, hash) {
    if (!hash.id) {
      // The ID has to be unique for each EmberData record,
      // so we use the membership ID and the action name
      hash.id = `${hash.relationships.membership.data.id}_${hash.attributes.name}`;
    }
    return super.normalize(model, hash);
  }
}
