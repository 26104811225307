import Transform from '@ember-data/serializer/transform';
import { decamelize } from '@ember/string';

import LocalFile, { localFilesKeys } from 'qonto/objects/local-file';

export default class FilesTransform extends Transform {
  deserialize(serialized) {
    if (!Array.isArray(serialized)) {
      serialized = [serialized];
    }
    return serialized.map(this.deserializeFile);
  }

  serialize(deserialized) {
    if (!Array.isArray(deserialized)) {
      deserialized = [deserialized];
    }
    return deserialized.map(this.serializeFile);
  }

  // Converts underscored attributes to camelCased when deserializing.
  deserializeFile(serialized) {
    let attributeKey;
    let attributes = {};

    localFilesKeys.forEach(key => {
      attributeKey = decamelize(key);
      if (serialized && serialized[attributeKey] !== undefined) {
        attributes[key] = serialized[attributeKey];
      }
    });

    return LocalFile.create(attributes);
  }

  // Converts camelCased attributes to underscored when serializing.
  serializeFile(deserialized) {
    let attributeKey;
    let attributes = {};

    localFilesKeys.forEach(key => {
      attributeKey = decamelize(key);
      if (deserialized && deserialized[key] !== undefined) {
        attributes[attributeKey] = deserialized[key];
      }
    });

    return attributes;
  }
}
