import { buildValidations, validator } from 'ember-cp-validations';

const ReceivableInvoicesSettingsValidations = buildValidations({
  contactEmail: [
    validator('presence', {
      presence: true,
      messageKey: 'receivable-invoices.invoice-creation.new.tab-settings.errors.required-field',
    }),
    validator('format', {
      type: 'email',
      minTldLength: 2,
      messageKey:
        'receivable-invoices.invoice-creation.new.tab-settings.errors.invalid-contact-email',
    }),
  ],
  nextNumber: [
    validator('presence', {
      presence: true,
      messageKey: 'receivable-invoices.invoice-creation.errors.required-field',
    }),
  ],
});

export default ReceivableInvoicesSettingsValidations;
