import JSONAPISerializer from '@ember-data/serializer/json-api';
import { underscore } from '@ember/string';

import { OPERATOR } from 'qonto/constants/reminders-configuration';

export default class RemindersConfigurationSerializer extends JSONAPISerializer {
  modelNameFromPayloadKey(key) {
    switch (key) {
      case 'reminders':
        return 'reminders-configuration';
      case 'customers':
        return 'client-hub';
      default:
        return super.modelNameFromPayloadKey(key);
    }
  }

  payloadKeyFromModelName(key) {
    switch (key) {
      case 'reminders-configuration':
        return 'reminders';
      case 'client-hub':
        return 'customers';
      default:
        return super.payloadKeyFromModelName(key);
    }
  }

  keyForAttribute(attr) {
    return underscore(attr);
  }

  keyForRelationship(relationship) {
    switch (relationship) {
      case 'client':
        return 'customer';
      default:
        return super.keyForRelationship(relationship);
    }
  }

  serializeHasMany(snapshot, json, relationship) {
    if (relationship.key === 'rules') {
      json.attributes.rules = snapshot
        .hasMany('rules')
        .map(rule => this.serialize(rule).data.attributes);
    } else {
      super.serializeHasMany(snapshot, json, relationship);
    }
  }

  normalizeQueryRecordResponse(store, primaryModelClass, payload) {
    if (Array.isArray(payload.data)) {
      if (payload.data.length === 0) {
        payload.data = null;
      } else {
        payload.data = payload.data[0];
      }
    }
    this.normalizeRules(payload);
    return super.normalizeQueryRecordResponse(...arguments);
  }

  normalizeRules(payload) {
    if (!payload.data?.attributes?.rules) {
      return;
    }

    let data = payload.data;
    let rules = this._sortRules(data.attributes.rules);

    payload.included = payload.included || [];
    data.relationships = data.relationships || {};
    data.relationships.rules = {
      data: [],
    };

    rules.forEach(rule => {
      let identity = {
        id: crypto.randomUUID(),
        type: 'reminders-configuration/rules',
      };
      payload.included.push({
        ...identity,
        attributes: rule,
      });
      data.relationships.rules.data.push(identity);
    });

    delete data.attributes.rules;
  }

  _sortRules(rules) {
    let getRulePosition = rule => {
      switch (rule.operator) {
        case OPERATOR.BEFORE:
          return -rule.offset_days;
        case OPERATOR.ON:
          return 0;
        case OPERATOR.AFTER:
          return rule.offset_days;
      }
    };

    return rules.slice().sort((a, b) => getRulePosition(a) - getRulePosition(b));
  }
}
