import type { Beneficiary } from 'qonto/services/international-out/types';

/**
 * Formats the account information of an international beneficiary into a human-readable string.
 *
 * @param params - The parameters object.
 * @param params.accountIdentifier - The account identifier.
 * @param params.bankIdentifier - The bank identifier.
 * @returns The formatted account information.
 *
 * @example
 *
 * ```ts
 * formatAccountInformation({ accountIdentifier: "1234567890", bankIdentifier: "BANK123" }); // Returns: "1234567890 ∙ BANK123"
 * formatAccountInformation({ accountIdentifier: "1234567890" }); // Returns: "1234567890"
 * ```
 */
export function formatAccountInformation({
  accountIdentifier,
  bankIdentifier,
}: Pick<Beneficiary, 'accountIdentifier' | 'bankIdentifier'>): string {
  if (bankIdentifier) {
    return `${accountIdentifier} \u22C5 ${bankIdentifier}`;
  }

  return accountIdentifier;
}

/**
 * Formats a number representing an exchange rate to a string with up to 4 decimal places.
 *
 * @param rate - The number representing the exchange rate to be formatted.
 * @returns The formatted exchange rate.
 *
 * @example
 *
 * ```ts
 * formatExchangeRate(3.00000879); // Returns: "3.0000"
 * formatExchangeRate(0.00087631); // Returns: "0.0008763"
 * ```
 */
export function formatExchangeRate(rate: number): string {
  if (isNaN(rate)) {
    return 'NaN';
  }

  let numberString = rate.toString();
  let decimalIndex = numberString.indexOf('.');

  if (decimalIndex === -1) {
    return rate.toFixed(4); // Add '.0000' for whole numbers
  }

  if (rate >= 1) {
    // For non-zero integer part, truncate to 4 decimal places
    return numberString.substring(0, decimalIndex + 5);
  } else {
    // For zero integer part, keep the initial zeros, find the first non-zero after the decimal
    let decimalPart = numberString.substring(decimalIndex + 1);
    let firstNonZeroIndex = decimalPart.search(/[^0]/);

    if (firstNonZeroIndex === -1) {
      return '0.0000';
    } else {
      let endIndex = decimalIndex + 1 + firstNonZeroIndex + 4;
      return numberString.substring(0, endIndex);
    }
  }
}

/**
 * Formats a number representing an exchange rate to a string and provides additional details about the formatted value.
 *
 * @param rate - The exchange rate to be formatted.
 * @returns An object containing the formatted exchange rate value as a string and the count of decimal places.
 *
 * @example
 *
 * ```ts
 * formatExchangeRateWithDetails(1.23456); // Returns { value: "1.2345", decimalPlacesCount: 4 }
 * formatExchangeRateWithDetails(0.00087631); // Returns { value: "0.0008763", decimalPlacesCount: 7 }
 * formatExchangeRateWithDetails(3); // Returns { value: "3.0000", decimalPlacesCount: 4 }
 * ```
 */
export function formatExchangeRateWithDetails(rate: number): {
  value: string;
  decimalPlacesCount: number;
} {
  let value = formatExchangeRate(rate);
  let [, decimalPart] = value.split('.');
  return {
    value,
    decimalPlacesCount: decimalPart ? decimalPart.length : 0,
  };
}
