import { htmlSafe } from '@ember/template';

import { pluralize } from 'ember-inflector';

const BENEFICIARY_WARNINGS = ['new_beneficiary', 'beneficiary_name_changed'];
const SPEND_LIMITS_WARNINGS = ['insufficient_per_transfer_limits', 'insufficient_monthly_limits'];

const INSTANT_TRANSFER_WARNINGS = [
  'instant_amount_above_threshold',
  'instant_non_reachable_bic',
  'instant_under_maintenance',
  'instant_operational_unavailability',
  'instant_functional_unavailability',
];

const FINANCING_WARNINGS = [
  'financing_only_available_for_main_account',
  'financing_internal_transfer_forbidden',
  'financing_scheduled_transfer_unavailable',
];

const WARNING_LEVELS = {
  cost: 'warning',
  already_issued: 'warning',
  qonto_bank_account: 'info',
  instant_non_reachable_bic: 'info',
  instant_under_maintenance: 'info',
  insufficient_funds: 'error',
};

/**
 * @param {IntlService} intlService - The translation service
 * @param {String} modelName - The name of the relevant model
 * @param {String} warningType - A warning from Response
 * @returns {<{warningMessage: string, warningType: string, warningLevel: string}>}
 */
export function parseWarning(intlService, modelName, warningType) {
  let translationKey = `${pluralize(modelName)}.warnings.${warningType}`;
  let warningMessage = htmlSafe(intlService.t(translationKey));
  let warningLevel = WARNING_LEVELS[warningType] || 'warning';
  return { warningMessage, warningType, warningLevel };
}

function sanitizeEstimatedCost(estimatedPrice, intlService, modelName) {
  let { amount, amount_currency: currency } = estimatedPrice;
  let computed_amount = intlService.formatMoney(amount, { currency });

  if (modelName === 'transfer' || modelName === 'request') {
    return { computed_amount };
  }
}

function extractEstimateCost(intlService, modelName, response) {
  let localizedCost = [];

  let hasEstimateCost =
    response.estimated_price &&
    response.estimated_price.amount &&
    response.estimated_price.amount_currency &&
    response.estimated_price.amount > 0;

  if (hasEstimateCost) {
    let sanitizedCost = sanitizeEstimatedCost(response.estimated_price, intlService, modelName);
    let translationKey = intlService.t(`${pluralize(modelName)}.warnings.cost`, sanitizedCost);
    let estimateCost = htmlSafe(translationKey);
    localizedCost = [
      { warningMessage: estimateCost, warningType: 'cost', warningLevel: WARNING_LEVELS['cost'] },
    ];
  }

  return localizedCost;
}

/**
 * Extracts and localize confirmatation warnings from backend response
 * Extracts transfer costs estimations if applicable
 *
 * @param {IntlService} intlService - The translation service
 * @param {string} modelName - The name of the relevant model
 * @param {Object} response - The backend payload
 * @returns {Array} - an array of warning objects, a localized cost
 * object is added at the end of the array if applicable
 */
function extractConfirmationWarnings(
  intlService,
  modelName,
  response,
  { showEstimateCost = true } = {}
) {
  let selectWarnings = warning => {
    return ![
      ...BENEFICIARY_WARNINGS,
      ...INSTANT_TRANSFER_WARNINGS,
      ...SPEND_LIMITS_WARNINGS,
      ...FINANCING_WARNINGS,
      'fx_market_closed',
    ].includes(warning);
  };
  let warnings = response?.warnings?.filter(selectWarnings) || [];
  let localizedWarnings = warnings.map(warning => parseWarning(intlService, modelName, warning));

  let localizedCost = [];

  if (showEstimateCost) {
    localizedCost = extractEstimateCost(intlService, modelName, response);
  }

  return [...localizedWarnings, ...localizedCost];
}

function extractConfirmationErrors(response) {
  let hasError = response.errors && response.errors.length;
  return hasError ? response.errors : [];
}

function extractBeneficiaryWarnings(response) {
  let hasWarning = response?.warnings?.length > 0;
  let beneficiaryWarning;

  if (hasWarning) {
    for (let warning of BENEFICIARY_WARNINGS) {
      if (response.warnings.includes(warning)) {
        beneficiaryWarning = warning;
        break;
      }
    }
  }
  return beneficiaryWarning;
}

function extractSpendLimitsWarning(response) {
  let hasWarning = Boolean(response.warnings?.length);
  // There can only be one spend limit warning
  let spendLimitsWarning = null;

  if (hasWarning) {
    for (let warning of SPEND_LIMITS_WARNINGS) {
      if (response.warnings.includes(warning)) {
        spendLimitsWarning = warning;
        break;
      }
    }
  }
  return spendLimitsWarning;
}

export {
  extractConfirmationWarnings,
  extractConfirmationErrors,
  extractBeneficiaryWarnings,
  extractSpendLimitsWarning,
  SPEND_LIMITS_WARNINGS,
};
