function sumDonutCashflows(cashflows) {
  return cashflows.reduce((acc, { value }) => acc + value, 0);
}

function calculateVariation(cashflows, comparisonCashflows) {
  cashflows.forEach(it => {
    let comparisonMonthValue = comparisonCashflows.find(({ name }) => name === it.name)?.value;
    it.variation = comparisonMonthValue
      ? (it.value - comparisonMonthValue) / comparisonMonthValue
      : null;
  });
}

function aggregateCashflows(cashflows, totalCashflows, remainderLabel) {
  let mainCashflows = cashflows.slice(0, 7);
  let totalOthersCashflows = totalCashflows - sumDonutCashflows(mainCashflows);

  // starting with the 8th item, we push all remaining items within the "details" item
  let detailsCashflows = cashflows
    .slice(7)
    .map(it => ({ ...it, indice: it.value / totalCashflows }));

  // the "detailsCashflows" list should be limited to 5 items
  // the remaining items are aggregated in one last entry
  if (detailsCashflows.length >= 5) {
    detailsCashflows = detailsCashflows.slice(0, 5);

    let totalRemainder = totalOthersCashflows - sumDonutCashflows(detailsCashflows);

    detailsCashflows = [
      ...detailsCashflows,
      {
        name: remainderLabel,
        value: totalRemainder,
        indice: totalRemainder / totalCashflows,
      },
    ];
  }

  let otherCashflows = {
    name: 'other',
    value: totalOthersCashflows,
    indice: totalOthersCashflows / totalCashflows,
    details: detailsCashflows,
  };

  return [...mainCashflows, otherCashflows];
}

function formatDonutCashflows(
  remainderLabel,
  currentCashflows,
  totalCashflows,
  comparisonCashflows = []
) {
  let cashflows = currentCashflows
    .map(it => ({ ...it, indice: it.value / totalCashflows }))
    .filter(({ indice }) => indice >= 0.0005);

  if (cashflows.length > 7) {
    cashflows = aggregateCashflows(cashflows, totalCashflows, remainderLabel);
  }

  // compute the rate of change for the first 7 items
  if (comparisonCashflows.length) {
    calculateVariation(cashflows, comparisonCashflows);
  }

  let uncategorizedItemIndex = cashflows.findIndex(({ name }) => name === 'uncategorized');
  if (uncategorizedItemIndex >= 0) {
    let [uncategorizedItem] = cashflows.splice(uncategorizedItemIndex, 1);
    cashflows.push(uncategorizedItem);
  }

  return cashflows;
}

export { sumDonutCashflows, formatDonutCashflows };
