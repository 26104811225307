export type InvoiceSettingsForm = {
  invoiceNumberingPattern?: string;
  invoiceNextNumber?: string;
  quoteNumberingPattern?: string;
  quoteNextNumber?: string;
};

export function getNextInvoiceNumberPreview({
  invoiceNumberingPattern,
  invoiceNextNumber,
}: InvoiceSettingsForm): string {
  return getNextNumberPreview({
    numberingPattern: invoiceNumberingPattern,
    nextNumber: invoiceNextNumber,
  });
}

export function getNextQuoteNumberPreview({
  quoteNumberingPattern,
  quoteNextNumber,
}: InvoiceSettingsForm): string {
  return getNextNumberPreview({
    numberingPattern: quoteNumberingPattern,
    nextNumber: quoteNextNumber,
  });
}

export function getNextNumberPreview({
  numberingPattern,
  nextNumber,
}: {
  numberingPattern?: string;
  nextNumber?: string;
}): string {
  let currentDate = new Date();
  let month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
  let fullYear = currentDate.getFullYear().toString();

  let prefix =
    numberingPattern?.replace(/\(MM\)/g, month).replace(/\(YYYY\)|\(AAAA\)|\(JJJJ\)/g, fullYear) ??
    '';

  let number = nextNumber ?? '';

  return prefix + number;
}
