import { readOnly } from '@ember/object/computed';

import { buildValidations, validator } from 'ember-cp-validations';

import { DEBOUNCE_MS } from 'qonto/constants/timers';

const ChecksValidations = buildValidations(
  {
    emitterName: [
      validator('ds-error'),
      validator('presence', true),
      validator('length', { min: 2 }),
    ],
    amount: [
      validator('ds-error'),
      validator('presence', true),
      validator('number', {
        allowString: true,
        gt: 0,
        lte: readOnly('model.context.maxCheckDepositAmount'),
        descriptionKey: 'validations.errors.amount',
      }),
    ],
    amountCurrency: [
      validator('ds-error'),
      validator('presence', true),
      validator('length', { min: 2 }),
    ],
    cmc7: [
      validator('ds-error'),
      validator('presence', true),
      validator('number', { allowString: true }),
    ],
    rlmc: [
      validator('ds-error'),
      validator('presence', true),
      validator('number', { allowString: true }),
    ],
    status: [validator('ds-error')],
    emittedDate: [
      validator('ds-error'),
      validator('date', {
        after: '1/1/1900',
        messageKey: 'validations.errors.invalid_date',
      }),
      // As the date format validator is flaky we need to add a length detection
      validator('length', { min: 10 }),
      validator('presence', true),
    ],
    declinedReason: [validator('ds-error')],
  },
  {
    debounce: DEBOUNCE_MS,
  }
);

export default ChecksValidations;
