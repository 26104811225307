import type IntlService from 'ember-intl/services/intl';

/**
 * Returns the translated text based on a key if it exists, otherwise returns the fallback translated text.
 *
 * @param translation - An object containing the key for the translation and the fallback text.
 * @param translation.key - The key for the translation.
 * @param translation.fallback - The fallback text to use if the translation does not exist.
 * @param intl - The intl service object.
 * @param locale - The locale to apply for the translation lookup.
 * @returns The translated text based on the key or the fallback text if the key does not exist.
 *
 * @example
 *
 * ```ts
 * const translation = { key: 'login.title', fallback: 'Login' };
 * const intl: IntlService = this.intl;
 * const locale: string = 'en-US';
 * const translatedText = maybeT(translation, intl, locale);
 * console.log(translatedText); // Outputs the translated text for 'login.title' or 'Login' if the key doesn't exist.
 * ```
 */
export default function maybeT(
  translation: { key: string; fallback: string },
  intl: IntlService,
  locale: string
): string {
  if (intl.exists(translation.key, locale)) {
    return intl.t(translation.key);
  }
  return translation.fallback;
}
