import { DIRECT_DEBIT_SUBSCRIPTION_SCHEDULE_TYPES } from 'qonto/constants/direct-debit-subscriptions';
import JSONAPISerializer from 'qonto/serializers/-json-api';

export default class DirectDebitSubscriptionSerializer extends JSONAPISerializer {
  serialize(snapshot, options) {
    let json = super.serialize(snapshot, options);
    let scheduleType = json.data.attributes.schedule_type;

    if (scheduleType !== DIRECT_DEBIT_SUBSCRIPTION_SCHEDULE_TYPES.CUSTOM) {
      delete json.data.attributes.schedule_custom_interval;
      delete json.data.attributes.schedule_custom_unit;
    }

    if (scheduleType === DIRECT_DEBIT_SUBSCRIPTION_SCHEDULE_TYPES.ONE_OFF) {
      delete json.data.attributes.schedule_total_collection_count;
    }

    return json;
  }
}
