import Transform from '@ember-data/serializer/transform';

export default class CommaSeparatedStringTransform extends Transform {
  deserialize(serialized) {
    return (serialized || []).join(', ');
  }

  serialize(deserialized) {
    return (deserialized || '').trim().split(/\s*,\s*/);
  }
}
