import ApplicationSerializer from 'qonto/serializers/application';

export default class SubscriptionsOptionSerializer extends ApplicationSerializer {
  modelNameFromPayloadKey() {
    return 'subscriptions-option';
  }

  normalizeResponse(store, primaryModelClass, payload, id, requestType) {
    payload.options.forEach(option => {
      option.id = option.code;
    });
    return super.normalizeResponse(store, primaryModelClass, payload, id, requestType);
  }
}
