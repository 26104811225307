/**
 * Generates a unique hash code for the given string value.
 *
 * The hash function is adapted from https://gist.github.com/hyamamoto/fd435505d29ebfa3d9716fd2be8d42f0#gistcomment-2800233
 *
 * @param {string} [value] - (optional) The string to hash
 * @returns {number} The resulting hash code.
 */
export default function stringHash(value?: string): number {
  if (!value) return 0;

  const hash = Array.from(value).reduce((s, c) => (Math.imul(31, s) + c.charCodeAt(0)) | 0, 0);

  return Math.abs(hash);
}
