import Service from '@ember/service';
import { tracked } from '@glimmer/tracking';

import window from 'ember-window-mock';

import { CONSENT_IS_REQUIRED_VENDORS, TECHNICAL_VENDORS } from 'qonto/constants/cookie-vendors';

const ALL_INTEGRATIONS_KEY = 'All';
const ALL_INTEGRATIONS_DISABLED = false;

export default class CookiesConsentManager extends Service {
  @tracked cookiesConsent = {};

  updateCookiesConsent() {
    let consent = {};

    for (let { name } of Object.values(TECHNICAL_VENDORS)) {
      consent[name] = true;
    }

    for (let vendor of Object.values(CONSENT_IS_REQUIRED_VENDORS)) {
      consent[vendor.name] = this.hasCookieConsentForVendor(vendor);
    }

    this.cookiesConsent = { ...consent };

    return this.cookiesConsent;
  }

  hasCookieConsentForVendor({ id }) {
    return window.Didomi?.getUserConsentStatusForVendor?.(id) ?? false;
  }

  getUserStatusLastUpdated() {
    return window.Didomi?.getUserStatus?.().updated ?? '';
  }

  shouldConsentBeCollected() {
    return window.Didomi?.shouldConsentBeCollected?.() ?? true;
  }

  getSegmentIntegrations() {
    return {
      [ALL_INTEGRATIONS_KEY]: ALL_INTEGRATIONS_DISABLED,
      ...this.cookiesConsent,
    };
  }
}
