import Service, { service } from '@ember/service';

export default class InsuranceManager extends Service {
  @service organizationManager;
  @service prismic;

  get defaultFetchLinks() {
    return ['insurance_risk.name', 'insurance_provider.logo'];
  }

  get defaultOrderings() {
    return {
      field: ['my.insurance_offer.order', 'my.insurance_offer.uid'],
    };
  }

  get defaultPredicates() {
    let { legalCountry } = this.organizationManager.organization;

    return [
      this.prismic.prismicLibrary.predicate.at('document.type', 'insurance_offer'),
      this.prismic.prismicLibrary.predicate.at('document.tags', [
        `market_${legalCountry.toLowerCase()}`,
      ]),
    ];
  }

  async getInsuranceProducts(categorySlug) {
    let { legalSector } = this.organizationManager.organization;

    let predicates = this.defaultPredicates;
    let segmentId = await this.#fetchSegmentId(legalSector);

    if (segmentId) {
      predicates.push(
        this.prismic.prismicLibrary.predicate.at('my.insurance_offer.segments.segment', segmentId)
      );
    }

    if (categorySlug) {
      predicates.push(
        this.prismic.prismicLibrary.predicate.at('my.insurance_offer.category', categorySlug)
      );
    }

    let offers = await this.prismic.get({
      predicates,
      orderings: this.defaultOrderings,
      fetchLinks: this.defaultFetchLinks,
    });

    let alwaysDisplayedOffers = await this.#fetchAlwaysDisplayedOffers(categorySlug);

    // Combine offers and alwaysDisplayedOffers, removing duplicates
    let combinedOffers = [...alwaysDisplayedOffers.results, ...offers.results];
    let uniqueOffers = Array.from(new Set(combinedOffers.map(offer => offer.id))).map(id =>
      combinedOffers.find(offer => offer.id === id)
    );

    return uniqueOffers;
  }

  async getInsuranceProvider(slug) {
    let providers = await this.prismic.get({
      predicates: [
        this.prismic.prismicLibrary.predicate.at('document.type', 'insurance_provider'),
        this.prismic.prismicLibrary.predicate.at('my.insurance_provider.slug', slug),
      ],
      pageSize: 1,
    });

    return providers.results[0];
  }

  async #fetchSegmentId(legalSector) {
    if (!legalSector) {
      return null;
    }

    let segments = await this.prismic.get({
      predicates: [
        this.prismic.prismicLibrary.predicate.at('document.type', 'insurance_segment'),
        this.prismic.prismicLibrary.predicate.fulltext(
          'my.insurance_segment.legal_sectors',
          legalSector
        ),
      ],
    });

    return segments.results?.[0]?.id;
  }

  async #fetchAlwaysDisplayedOffers(categorySlug) {
    let predicates = this.defaultPredicates;

    predicates.push(
      this.prismic.prismicLibrary.predicate.at('my.insurance_offer.always_displayed', true)
    );

    if (categorySlug) {
      predicates.push(
        this.prismic.prismicLibrary.predicate.at('my.insurance_offer.category', categorySlug)
      );
    }

    let alwaysDisplayedOffers = await this.prismic.get({
      predicates,
      orderings: this.defaultOrderings,
      fetchLinks: this.defaultFetchLinks,
    });

    return alwaysDisplayedOffers;
  }
}
