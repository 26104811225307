import { camelize } from '@ember/string';

import JSONAPISerializer from 'qonto/serializers/-json-api';

export default class SelfInvoiceSerializer extends JSONAPISerializer {
  normalize(modelClass, payload) {
    payload.attributes.amount_due = payload.attributes.amount_due?.value;

    return super.normalize(modelClass, payload);
  }

  normalizeSaveResponse(_store, _primaryModelClass, payload) {
    // normalize embedded items to JSONAPI format
    // move embedded items data to included array and add relationship data
    let { relationships, included } = this._buildInvoiceItems({
      items: payload.data.attributes.items,
      id: payload.data.id,
    });
    delete payload.data.attributes.items;
    payload.data.relationships = { ...payload.data.relationships, ...relationships };
    payload.included = included;
    return super.normalizeSaveResponse(...arguments);
  }

  normalizeFindRecordResponse(_store, _primaryModelClass, payload) {
    // normalize embedded items to JSONAPI format
    // move embedded items data to included array and add relationship data
    let { relationships, included } = this._buildInvoiceItems({
      items: payload.data.attributes.items,
      id: payload.data.id,
    });
    delete payload.data.attributes.items;
    payload.data.relationships = { ...payload.data.relationships, ...relationships };
    payload.included = included;
    return super.normalizeFindRecordResponse(...arguments);
  }

  serializeHasMany(snapshot, json, relationship) {
    if (relationship.key === 'items' && !snapshot.adapterOptions?.partial) {
      json.attributes['items'] =
        snapshot.hasMany('items')?.map(i => {
          let {
            data: { attributes, id },
          } = i.serialize({ includeId: true });
          let vat_rate = parseFloat(attributes.vat_rate);
          attributes.vat_rate = vat_rate
            ? parseFloat(vat_rate.toFixed(4)).toString()
            : attributes.vat_rate;

          // We serialize amounts to MoneyObjects
          if (attributes.unit_price) {
            attributes.unit_price = {
              value: attributes.unit_price,
              currency: json.attributes.currency,
            };
          }

          if (id) {
            return { id, ...attributes };
          }
          return attributes;
        }) || [];
    } else {
      super.serializeHasMany(...arguments);
    }
  }

  extractErrors(store, typeClass, payload, id) {
    let errors = payload.errors.map(({ code, ...rest }) => {
      let error = { ...rest, detail: code };

      if (error.source?.pointer) {
        error.source.pointer = error.source.pointer.replace('snapshot/', 'snapshot_');
        error.source.pointer = camelize(error.source.pointer);
      }
      return error;
    });
    return new JSONAPISerializer().extractErrors(store, typeClass, { errors }, id);
  }

  _buildInvoiceItems(invoiceData) {
    let items = invoiceData.items;
    if (items === undefined)
      return {
        relationships: {},
        included: [],
      };
    return {
      relationships: {
        items: {
          data: items.map(({ id }) => ({ id, type: 'receivable-invoice/item' })),
        },
      },
      included: items.map(item => {
        // Normalize MoneyObject attributes to values only
        item.unit_price = item.unit_price?.value;

        return {
          id: item.id,
          type: 'receivable-invoice/item',
          attributes: item,
          relationships: {
            self_invoice: { data: { id: invoiceData.id, type: 'self-invoice' } },
          },
        };
      }),
    };
  }

  serialize(snapshot, option) {
    let json = super.serialize(snapshot, option);

    // Serialize amounts to MoneyObject using invoice currency

    if (json.data.attributes.amount_due) {
      json.data.attributes.amount_due = {
        value: json.data.attributes.amount_due,
        currency: json.data.attributes.currency,
      };
    }
    return json;
  }
}
