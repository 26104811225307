import { EmbeddedRecordsMixin } from '@ember-data/serializer/rest';

import F24BaseSerializer from './base';

export default class F24TaxInformationSerializer extends F24BaseSerializer.extend(
  EmbeddedRecordsMixin
) {
  attrs = {
    erario: { embedded: 'always' },
    imu: { embedded: 'always' },
    others: { embedded: 'always' },
    inpsTaxList: { embedded: 'always' },
    inailTaxList: { embedded: 'always' },
    regioniTaxList: { embedded: 'always' },
  };

  serialize(snapshot, options) {
    let json = super.serialize(snapshot, options);

    json.inps = { tax_list: json.inps_tax_list };
    json.regioni = { tax_list: json.regioni_tax_list };
    json.other_institutions = {
      inail: { tax_list: json.inail_tax_list },
      others: json.others,
    };

    delete json.inps_tax_list;
    delete json.regioni_tax_list;
    delete json.inail_tax_list;
    delete json.others;

    return json;
  }

  normalize(modelClass, hash) {
    hash.inps_tax_list = hash.inps?.tax_list;
    hash.inail_tax_list = hash.other_institutions?.inail?.tax_list;
    hash.others = hash.other_institutions?.others;
    hash.regioni_tax_list = hash.regioni?.tax_list;

    return super.normalize(modelClass, hash);
  }
}
