export default function serializeRecordToPOJO(record) {
  let { id } = record;
  let { fields, modelName: type } = record.constructor;
  let attributes = {};
  let relationships = {};

  fields.forEach((kind, field) => {
    switch (kind) {
      case 'belongsTo': {
        let belongsToRef = record.belongsTo(field);
        let belongsToId = belongsToRef.id();
        let relationshipValue = belongsToRef.value();
        relationships[field] = relationshipValue
          ? { id: belongsToId, type: belongsToRef.type }
          : null;
        break;
      }
      case 'hasMany': {
        let hasManyRef = record.hasMany(field);
        let hasManyIds = hasManyRef.ids();
        let relationshipValue = hasManyRef.value();
        relationships[field] = relationshipValue
          ? hasManyIds.map(id => ({ id, type: hasManyRef.type }))
          : null;
        break;
      }
      case 'attribute':
        attributes[field] = record[field];
        break;
    }
  });

  return { id, type, relationships, attributes, isSerializedEmberModel: true };
}
