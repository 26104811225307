import { EmbeddedRecordsMixin } from '@ember-data/serializer/rest';

import ApplicationSerializer from 'qonto/serializers/application';
import {
  removeNationalityFromArray,
  serializeNationalities,
} from 'qonto/utils/update-nationalities';

export default class MembershipSerializer extends ApplicationSerializer.extend(
  EmbeddedRecordsMixin
) {
  attrs = {
    address: { embedded: 'always' },
    documents: { embedded: 'always' },
    // About currentMonthSpendings, monthlyTransferLimit, perTransferLimit.
    // These properties are related to the Spend Limits feature.
    // We need to store these properties in the membership model, however
    // we don't want to pass them at the .save(). There is a proper action
    // for that inside the model.
    currentMonthSpendings: { serialize: false },
    monthlyTransferLimit: { serialize: false },
    perTransferLimit: { serialize: false },
  };

  normalize(typeClass, hash, modelName) {
    // Handle the nationality and nationalities logic by ensuring
    // that nationality is removed from nationalities for the view layer.
    let nationality = hash.nationality;
    let nationalities = hash.nationalities || [];

    if (nationality && nationalities.includes(nationality)) {
      hash.nationalities = removeNationalityFromArray(
        nationalities,
        nationalities.indexOf(nationality)
      );
    }

    this._adjust(hash);
    return super.normalize(typeClass, hash, modelName);
  }

  serialize(snapshot) {
    let json = super.serialize(...arguments);

    // Handle adding nationality back to nationalities before sending to the server.
    let nationality = snapshot.attr('nationality');
    let nationalities = snapshot.attr('nationalities');

    json.nationalities = serializeNationalities(nationalities, nationality);

    return json;
  }

  _adjust(membership) {
    // The team API is still returning the old string team property
    // so we delete it here to make sure it won't mess with the serializer
    delete membership.team;

    if (membership.permissions === null) {
      delete membership.permissions;
    }
  }
}
