import JSONAPISerializer from '@ember-data/serializer/json-api';
import { underscore } from '@ember/string';

export default class extends JSONAPISerializer {
  keyForAttribute(attr) {
    return underscore(attr);
  }

  keyForRelationship(attr) {
    return underscore(attr);
  }

  /**
   * This overrides how the `type` fields in each `data` object will be generated.
   *
   * Ember Data uses `kebab-case` by default, but qonto-api expects `snake_case`.
   */
  payloadKeyFromModelName(modelName) {
    return underscore(super.payloadKeyFromModelName(modelName));
  }
}
