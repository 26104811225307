import Service, { service } from '@ember/service';

import {
  ANNUAL_TURNOVER_OPTIONS,
  ANNUAL_TURNOVER_OPTIONS_IT,
} from 'qonto/constants/business-finances';

export default class AnnualTurnoverService extends Service {
  @service intl;

  getAnnualTurnover(legalCountry) {
    if (legalCountry === 'IT') {
      return {
        options: ANNUAL_TURNOVER_OPTIONS_IT,
        translations: [
          this.intl.t(
            'organizations.profile.company_profile.organization_edit.business_finances.annual_turnover.micro-businesses.option1'
          ),
          this.intl.t(
            'organizations.profile.company_profile.organization_edit.business_finances.annual_turnover.micro-businesses.option2'
          ),
          this.intl.t(
            'organizations.profile.company_profile.organization_edit.business_finances.annual_turnover.micro-businesses.option3'
          ),
          this.intl.t(
            'organizations.profile.company_profile.organization_edit.business_finances.annual_turnover.micro-businesses.option4'
          ),
          this.intl.t(
            'organizations.profile.company_profile.organization_edit.business_finances.annual_turnover.micro-businesses.option5'
          ),
        ],
      };
    } else {
      return {
        options: ANNUAL_TURNOVER_OPTIONS,
        translations: [
          this.intl.t(
            'organizations.profile.company_profile.organization_edit.business_finances.annual_turnover.option1'
          ),
          this.intl.t(
            'organizations.profile.company_profile.organization_edit.business_finances.annual_turnover.option2'
          ),
          this.intl.t(
            'organizations.profile.company_profile.organization_edit.business_finances.annual_turnover.option3'
          ),
          this.intl.t(
            'organizations.profile.company_profile.organization_edit.business_finances.annual_turnover.option4'
          ),
          this.intl.t(
            'organizations.profile.company_profile.organization_edit.business_finances.annual_turnover.option5'
          ),
        ],
      };
    }
  }
}
