/**
 * Shuffles an array using the Fisher-Yates (Knuth) shuffle algorithm.
 * @param array The array to shuffle.
 * @returns A new array with the elements shuffled.
 */

export function shuffleArray<T>(array: unknown[]): unknown[] {
  let shuffledArray = array.slice();

  for (let i = shuffledArray.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
  }

  // Ensure shuffled array is different from the original array
  if (shuffledArray.every((value, index) => value === array[index])) {
    return shuffleArray(array);
  }

  return shuffledArray;
}
