import Service, { service } from '@ember/service';

import { dropTask } from 'ember-concurrency';

import { ErrorInfo } from 'qonto/utils/error-info';

export default class BudgetManagerService extends Service {
  @service toastFlashMessages;
  @service sentry;
  @service intl;
  @service segment;
  @service modals;

  archiveBudgetTask = dropTask(async ({ budget, trackingEvent }) => {
    this.segment.track(trackingEvent);

    let result = await this.modals.open('popup/destructive', {
      title: this.intl.t('team-budgets.budget.archive.modal.title'),
      description: this.intl.t('team-budgets.budget.archive.modal.subtitle', {
        budget_name: budget.name,
      }),
      cancel: this.intl.t('btn.back'),
      confirm: this.intl.t('team-budgets.budget.archive.modal.confirm'),
    });

    if (result !== 'confirm') {
      this.segment.track('budget_archive-confirmation-back_clicked');

      return;
    }

    this.segment.track('budget_archive-confirmation_clicked');

    try {
      await budget.archive();

      this.toastFlashMessages.toastSuccess(
        this.intl.t('team-budgets.budget.archive.toast.success', { budget_name: budget.name })
      );
    } catch (error) {
      if (ErrorInfo.for(error).shouldSendtoSentry) {
        this.sentry.captureException(error);
      }

      this.toastFlashMessages.toastError(
        this.intl.t('toasts.errors.server_error', { htmlSafe: true })
      );
    }
  });
}
