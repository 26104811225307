/**
 * Builds a formatted query for transaction statistics endpoints
 * @param {string} organizationId Current organization id
 * @param {Array<*>} aggregations Array of requested transaction statistics aggregations
 * @param {*} period Statistics Period
 * @param {*} side Transaction side (credit or debit)
 * @param {Array<string> | null} bankAccounts List of selected bankAccounts. All selected by default (null value)
 * @param {FilterGroup} filters Formatted filter group for Transactions Query Language
 * @returns Formatted query for transaction statistics endpoints
 */
export default function buildQuery(
  organizationId,
  aggregations,
  { interval, startDate, endDate },
  side,
  bankAccounts = null,
  filters
) {
  return {
    organization_id: organizationId,
    aggregations,
    bank_account_ids: bankAccounts,
    filter_group: {
      conditional: 'and',
      expressions: [
        ...(side
          ? [
              {
                property: 'side',
                operator: 'eq',
                values: [side],
              },
            ]
          : []),
        ...(interval
          ? [
              {
                property: 'display_at',
                operator: 'interval',
                values: [interval],
              },
            ]
          : [
              {
                property: 'display_at',
                operator: 'gte',
                values: [startDate.getTime()],
              },
              {
                property: 'display_at',
                operator: 'lte',
                values: [endDate.getTime()],
              },
            ]),
        ...(filters ? [filters] : []),
      ],
    },
  };
}
