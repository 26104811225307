// @ts-nocheck
import Service, { service } from '@ember/service';

import * as Sentry from '@sentry/ember';

export default class SentryService extends Service {
  @service launchdarkly;

  withScope(scopeFn) {
    Sentry.withScope(scopeFn);
  }

  captureMessage(msg, { level = 'info', cft } = {}) {
    Sentry.withScope(scope => {
      scope.setTag('sentryService', true);
      if (cft) {
        scope.setTag('CFT', cft);
      }
      Sentry.captureMessage(msg, level);
    });
  }

  captureException(error, { cft, captureContext } = {}) {
    Sentry.withScope(scope => {
      scope.setTag('sentryService', true);
      let originalError = error.cause || error;
      if (originalError.status) {
        scope.setTags({
          http_status: originalError.status,
          http_method: originalError.method,
          api_url: originalError.url,
        });
      }
      if (cft) {
        scope.setTag('CFT', cft);
      }
      if (originalError.errors?.length) {
        originalError.errors.forEach((e, i) => {
          scope.setContext(`error_${i}`, e);
        });
      }
      Sentry.captureException(error, captureContext);
    });
  }
}

declare module '@ember/service' {
  interface Registry {
    sentry: SentryService;
  }
}
