import { buildValidations, validator } from 'ember-cp-validations';

import { EXERCISE_KINDS } from 'qonto/constants/budget';
import { DEBOUNCE_MS } from 'qonto/constants/timers';

const BudgetValidations = buildValidations(
  {
    name: [validator('ds-error'), validator('presence', true)],
    exerciseKind: [
      validator('ds-error'),
      validator('presence', true),
      validator('inclusion', {
        in: Object.values(EXERCISE_KINDS),
      }),
    ],
    assignedTo: [validator('ds-error'), validator('presence', true)],
    exercises: [validator('has-many'), validator('length', { min: 1 })],
  },
  {
    debounce: DEBOUNCE_MS,
  }
);

export default BudgetValidations;
