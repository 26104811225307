import type { XlsFile } from 'qonto/lib/xls-file';
import type { Mapping } from './types.ts';

export function prepareColumns(xlsFile: XlsFile, mapping?: Mapping, colsToHide?: string[]) {
  trimColumns(xlsFile, colsToHide);
  sortColumns(xlsFile, mapping);
}

function trimColumns(xlsFile: XlsFile, colsToHide: string[] = []) {
  let emptyColumns = xlsFile.columns.filter(
    (column: string) => !xlsFile.rows.some((row: Record<string, string>) => row[column])
  );
  xlsFile?.removeColumns([...colsToHide, ...(emptyColumns ?? [])]);
}

function sortColumns(xlsFile: XlsFile, mapping?: Mapping) {
  function columnInMapping(column: string, mapping?: Mapping) {
    return mapping && Object.keys(mapping).includes(column) ? 1 : 0;
  }

  function columnHasData(column: string, rows: Record<string, string>[]) {
    return rows?.some(row => row[column] !== null) ? 1 : 0;
  }

  xlsFile?.sortColumns((a: string, b: string) => {
    return (
      columnInMapping(b, mapping) - columnInMapping(a, mapping) ||
      columnHasData(b, xlsFile.rows) - columnHasData(a, xlsFile.rows)
    );
  });
}
