import ApplicationSerializer from 'qonto/serializers/application';

export default class SubscriptionsFeatureSerializer extends ApplicationSerializer {
  modelNameFromPayloadKey() {
    return 'subscriptions-feature';
  }

  normalizeResponse(store, primaryModelClass, payload, id, requestType) {
    payload.features.forEach(feature => {
      feature.id = feature.code;
    });

    return super.normalizeResponse(store, primaryModelClass, payload, id, requestType);
  }
}
