import dayjs from 'dayjs';

import { KYCB_UPDATE_BANNER_STORAGE_KEY } from 'qonto/components/topbar/kyc-kyb-pending-update';
import { safeLocalStorage } from 'qonto/helpers/safe-local-storage';

/**
 * @typedef {import('./config-types').TopBannerConfig} TopBannerConfig
 */

/**
 * @typedef {import('./config-types').Services} Services
 */

const CACHE_KEY = 'kyc-kyb-update';

/**
 * @param {Services} services - The parameters for the function.
 */
async function model({ organizationManager, abilities }) {
  if (hasOngoingKycKybUpdateProcess({ organizationManager, abilities })) {
    let { organization } = organizationManager;
    let updateProcess = await organization.belongsTo('ongoingKycKybUpdateProcess').load();
    return {
      organization,
      updateProcess,
    };
  } else {
    return null;
  }
}

function hasOngoingKycKybUpdateProcess({ organizationManager, abilities }) {
  let { organization } = organizationManager;

  return Boolean(
    organization.belongsTo('ongoingKycKybUpdateProcess').id() &&
      abilities.can('view settings organization') &&
      abilities.can('read kyc kyb update process organization') &&
      organization.hasKycKybUpdateFeature
  );
}

/**
 * @type {Array<TopBannerConfig>}
 */
export const KYC_KYB_UPDATE_CONFIG = [
  {
    id: 'kyc-kyb-update-de',
    model,
    modelCacheKey: CACHE_KEY,
    type: 'warning',
    componentName: 'topbar/kyc-kyb-pending-update',
    message: () => ' ', // required due to the bannerFlashMessages implementation
    shouldDisplay: model => {
      if (!model) {
        return false;
      }
      let { organization, updateProcess } = model;
      return (
        organization.legalCountry === 'DE' &&
        safeLocalStorage.getItem(KYCB_UPDATE_BANNER_STORAGE_KEY) !==
          organization.belongsTo('ongoingKycKybUpdateProcess').id() &&
        updateProcess.status === 'created' &&
        updateProcess.legallyRequired &&
        (updateProcess.isCompanyReportStatusNotFound || updateProcess.isCompanyReportStatusNull) &&
        updateProcess.createdAt > dayjs().subtract(30, 'day')
      );
    },
    extraProps: ({ updateProcess }) => ({ updateID: updateProcess.id }),
  },
];
