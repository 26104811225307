import { assert } from '@ember/debug';

import FlashMessages from 'ember-cli-flash/services/flash-messages';

export default class BannerFlashMessagesService extends FlashMessages {
  /**
   * Triggers an info top banner.
   *
   * @public
   * @method topBannerInfo
   *
   * @param {string} message - The message to display to the user.
   * @param {string} [componentName] - An optional custom component to use for the flash message.
   * @param {string} [id] - An optional unique identifier for the flash message.
   * @param {object} [data] - An optional object that will be merged with the flash message's content.
   * @returns void
   */
  topBannerInfo(message, componentName = 'top-banner', id, data = {}) {
    assert('A flashMessage must have a message', message);

    return this.add({
      componentKind: 'top-banner',
      componentName,
      type: 'info',
      sticky: true,
      priority: 100,
      message,
      id: id || message,
      ...data,
    }).getFlashObject();
  }

  /**
   * Triggers a warning top banner.
   *
   * @public
   * @method topBannerWarning
   *
   * @param {string} message - The message to display to the user.
   * @param {string} [componentName] - An optional custom component to use for the flash message.
   * @param {string} [id] - An optional unique identifier for the flash message.
   * @param {object} [data] - An optional object that will be merged with the flash message's content.
   * @returns void
   */
  topBannerWarning(message, componentName = 'top-banner', id, data = {}) {
    assert('A flashMessage must have a message', message);

    return super
      .add({
        componentKind: 'top-banner',
        componentName,
        type: 'warning',
        sticky: true,
        priority: 200,
        message,
        id: id || message,
        ...data,
      })
      .getFlashObject();
  }

  /**
   * Triggers an error top banner.
   *
   * @public
   * @method topBannerError
   *
   * @param {string} message - The message to display to the user.
   * @param {string} [componentName] - An optional custom component to use for the flash message.
   * @param {string} [id] - An optional unique identifier for the flash message.
   * @param {object} [data] - An optional object that will be merged with the flash message's content.
   * @returns void
   */
  topBannerError(message, componentName = 'top-banner', id, data = {}) {
    assert('A flashMessage must have a message', message);

    return this.add({
      componentKind: 'top-banner',
      componentName,
      type: 'error',
      sticky: true,
      priority: 300,
      message,
      id: id || message,
      ...data,
    }).getFlashObject();
  }

  clearTopBannerMessages() {
    this.clearMessages();
  }

  removeTopBannerMessage(idOrMessage) {
    this.queue?.forEach(flash => {
      if (flash.id?.toString() === idOrMessage?.toString()) {
        flash.destroyMessage();
      }
    });
  }
}
