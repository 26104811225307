import * as Sentry from '@sentry/ember';
import fetch from 'fetch';

import { announcementBaseURL as ANNOUNCEMENT_URL } from 'qonto/constants/hosts';

/**
 * Extract required attributes (`text`, `start_date` and `end_date`) from
 * Firestore response.
 *
 * If one of these attribute is missing, this function will throw an error. For
 * instance, if `fields.start_date` is undefined. This error will be catched
 * and logged to Sentry by the function `getAnnouncement()`.
 * @private
 * @param {Object} fields - raw Firestore data
 * @returns {Object} message - in-app banner should be displayed:
 * @returns {string} message.startDate - from that date
 * @returns {string} message.endDate - until that date
 * @returns {Object} message.text
 * @returns {string} message.text.en - with this localized text
 */
function extractRequiredFields(fields) {
  let message = {};
  message.text = {};

  message.startDate = fields.start_date.timestampValue;
  message.endDate = fields.end_date.timestampValue;

  let textFields = fields.text.mapValue.fields;
  for (let lang in textFields) {
    message.text[lang] = textFields[lang].stringValue;
  }

  return message;
}

/**
 * Extract optional attributes (`scope.cbs`) from Firestore response.
 * @private
 * @param {Object} fields - raw Firestore data
 * @returns {Object} scope - in-app banner should be scoped to:
 * @returns {string[]} scope.cbs - an array similar to this one:
 * [`qonto`, `treezor`]. Note that it might be empty. In that case, the
 * announcement is considered as unscoped.
 */
function extractOptionalFields(fields) {
  let cbs = [];

  let cbsValues = [];
  try {
    cbsValues = fields.scope.mapValue.fields.cbs.arrayValue.values;
  } catch {
    // ignore error as the field is optional
  }

  if (Array.isArray(cbsValues)) {
    for (let c of cbsValues) {
      cbs.push(c.stringValue);
    }
  }

  return { scope: { cbs } };
}

/**
 * Make the actual network request. Always returns an array (that may be
 * empty).
 * @param {Object[]} documents - raw data from Firestore.
 */
async function fetchFirestore() {
  let data = [];

  let response;
  try {
    response = await fetch(`${ANNOUNCEMENT_URL}/messages`);
  } catch (error) {
    // network request failures are harmless on this call
    if (!(error instanceof TypeError)) {
      Sentry.captureException(error);
    }
  }

  if (response?.ok) {
    let json = await response.json();

    if (json.documents && Array.isArray(json.documents)) {
      data = json.documents;
    }
  }

  return data;
}

/**
 * Take Firestore data,
 * extract the attributes of interest,
 * and normalize the data into a consumable data structure.
 * @param {Object[]} documents - raw data from Firestore.
 * @returns {Object[]} messages - normalized data.
 */
export function normalize(documents) {
  let messages = [];
  for (let doc of documents) {
    // we don't want to stop the execution
    // if the parsing of a specific message fails
    try {
      let { fields } = doc;

      let message = extractRequiredFields(fields);
      let { scope } = extractOptionalFields(fields);
      message.scope = scope;

      messages.push(message);
    } catch (error) {
      Sentry.captureException(error);
    }
  }

  return messages;
}

/**
 * Fetch Firestore for announcement to make. The announcement is added in
 * Firebase by the product / copywritters people. This messaging is typically
 * useful in case of outage.
 * Firestore data is normalized.
 * Then, the announcement to make is filtered following two criteria: date and
 * scope.
 * @param {Object} options
 * @param {string} options.locale - The user locale.
 * @param {string} options.cbs - The company cbs type. Might be either
 * `treezor` or `qonto`.
 * @returns {string[]} Messages to display in the topbar. If no message
 * should be displayed, the function returns an empty array.
 */
export default async function getAnnouncement({ locale, cbs: companyCbs }) {
  let documents = await fetchFirestore();

  let messages = normalize(documents);

  let texts = [];
  for (let message of messages) {
    let { endDate, startDate } = message;

    // `startDate < now < endDate`
    // if not, do not display the announcement
    let now = Date.now();
    if (now < Date.parse(startDate) || now > Date.parse(endDate)) {
      continue;
    }

    // if `scope === { cbs: [] }`, announcement is delivered to all companies
    let { cbs } = message.scope;
    if (cbs.length && !cbs.includes(companyCbs)) {
      continue;
    }

    let text = message.text[locale];
    if (text) {
      texts.push(text);
    }
  }

  return texts;
}
