import Transform from '@ember-data/serializer/transform';
import { decamelize } from '@ember/string';

import LocalFile, { localFilesKeys } from 'qonto/objects/local-file';

export default class FileTransform extends Transform {
  // Converts underscored attributes to camelCased when deserializing.
  deserialize(serialized) {
    let attributeKey;
    let attributes = {};

    localFilesKeys.forEach(key => {
      attributeKey = decamelize(key);
      if (serialized && serialized[attributeKey] !== undefined) {
        attributes[key] = serialized[attributeKey];
      }
    });

    return LocalFile.create(attributes);
  }

  // Converts camelCased attributes to underscored when serializing.
  serialize(deserialized) {
    let attributeKey;
    let attributes = {};

    localFilesKeys.forEach(key => {
      attributeKey = decamelize(key);
      if (deserialized && deserialized[key] !== undefined) {
        attributes[attributeKey] = deserialized[key];
      }
    });

    return attributes;
  }
}
