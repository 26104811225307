import Service, { service } from '@ember/service';

import { isDevelopingApp, macroCondition } from '@embroider/macros';
import { variation } from 'ember-launch-darkly';

export default class ZendeskHelpcenterService extends Service {
  @service zendeskLocalization;
  @service sentry;
  @service prismic;
  @service organizationManager;

  ZENDESK_BASE_URL = {
    fr: 'https://qonto9015.zendesk.com',
    it: 'https://qonto9015-it.zendesk.com',
    de: 'https://qonto9015-de.zendesk.com',
    es: 'https://qonto9015-es.zendesk.com',
  };

  PRISMIC_OPERATION_TYPES_MAP = {
    biller: 'transaction_helper_fees',
    card: 'transaction_helper_cards',
    cheque: 'transaction_helper_checks',
    direct_debit: 'transaction_helper_directdebits',
    f24: 'transaction_helper_f24',
    income: 'transaction_helper_incomes',
    pagopa_payment: 'transaction_helper_pagopa',
    recall: 'transaction_helper_recalls',
    swift_income: 'transaction_helper_swiftin',
    pay_later: 'transaction_helper_transfers',
    financing_installment: 'transaction_helper_transfers',
    transfer: 'transaction_helper_transfers',
  };

  PRISMIC_CATEGORIES_TYPES = ['educational', 'troubleshoot'];

  PRISMIC_DEFAULT_CATEGORY = 'educational';

  ZENDESK_OPERATION_TYPES_MAP = {
    transfer: 'transaction_helper_transfers',
    card: 'transaction_helper_cards',
    cheque: 'transaction_helper_cheques',
    direct_debit: 'transaction_helper_direct_debits',
    income: 'transaction_helper_incomes',
    biller: 'transaction_helper_fees',
    recall: 'transaction_helper_recalls',
    swift_income: 'transaction_helper_swiftin',
    f24: 'transaction_helper_f24',
    pagopa_payment: 'transaction_helper_pagopa',
    pay_later: 'transaction_helper_pay_later',
    financing_installment: 'transaction_helper_credit_transfers',
    direct_debit_collection: 'transaction_helper_sdd_collection',
    international_out: 'transaction_helper_international_out',
    i24: 'transaction_helper_i24',
    mandate: 'transaction_helper_mandates',
    direct_debit_hold: 'transaction_helper_sdd_hold',
  };

  ZENDESK_CATEGORIES_TYPES = ['display_cards_educational', 'display_cards_troubleshoot'];

  ZENDESK_DEFAULT_CATEGORY = 'display_cards_educational';

  isProduction() {
    return macroCondition(!isDevelopingApp()) ? true : false;
  }

  #getCurrentBaseUrl() {
    let country = this.zendeskLocalization.organizationCountry;
    return (
      this.ZENDESK_BASE_URL[country] ||
      this.ZENDESK_BASE_URL[this.zendeskLocalization.ZENDESK_DEFAULTS.UNAUTH_FALLBACK_COUNTRY]
    ); // Default to FR if no match
  }

  #composeUrl({ version = 'v2', path, searchParams }) {
    let baseUrl = this.#getCurrentBaseUrl();
    let url = new URL(`/api/${version}/${path}`, baseUrl);
    url.search = new URLSearchParams(searchParams).toString();
    return url.toString();
  }

  async #prismicGetArticlesByLabel({ labels }) {
    let predicates = [
      this.prismic.prismicLibrary.predicate.at('document.type', 'faq_article'),
      this.prismic.prismicLibrary.predicate.at('document.tags', labels),
      // eslint-disable-next-line ember/no-array-prototype-extensions
      this.prismic.prismicLibrary.predicate.any('my.faq_article.platform', ['all', 'web']),
      this.prismic.prismicLibrary.predicate.at(
        `my.faq_article.show_in_${this.organizationManager.organization.legalCountry.toLowerCase()}`,
        true
      ),
    ];

    try {
      let response = await this.prismic.get({ predicates });
      return response.results;
    } catch (error) {
      this.sentry.captureException(error);
    }
  }

  #prismicSortArticlesByOrder({ articles }) {
    return articles.sort((a, b) => a.data.order - b.data.order);
  }

  async _getArticlesByLabels({ labels, locale }) {
    let computedLocale = this.zendeskLocalization.calcZendeskLocale(
      locale || this.zendeskLocalization.userLocale
    );
    // NOTE: Zendesk do not support en generic locale and business decision was to use american english
    // so we need to convert en to en-us. No other coversion are needed.
    if (computedLocale === 'en') computedLocale = 'en-us';

    let url = this.#composeUrl({
      path: `help_center/${computedLocale}/articles`,
      searchParams: { label_names: labels.join(',') },
    });

    try {
      let response = await fetch(url);
      if (response.ok) {
        let data = await response.json();
        return data.articles;
      }
      throw new Error(`Error fetching articles: ${response.statusText}`);
    } catch (error) {
      this.sentry.captureException(error);
    }
  }

  _categorizeArticle = ({
    article,
    categories,
    defaultCategory,
    categoryNode,
    categorizedArticles,
  }) => {
    let category = categories.find(type => article[categoryNode].includes(type)) || defaultCategory;
    let icons = {
      [categories[0]]: 'icon_question_rounded_outlined',
      [categories[1]]: 'icon_nav_troubleshooting',
    };
    if (!categorizedArticles[category]) categorizedArticles[category] = [];
    categorizedArticles[category].push({
      ...article,
      icon: icons[category],
    });
  };

  _articlesByCategories({ articles }) {
    let CATEGORIES_TYPES = variation('feature--prismic-zendesk-migration')
      ? this.ZENDESK_CATEGORIES_TYPES
      : this.PRISMIC_CATEGORIES_TYPES;
    let DEFAULT_CATEGORY = variation('feature--prismic-zendesk-migration')
      ? this.ZENDESK_DEFAULT_CATEGORY
      : this.PRISMIC_DEFAULT_CATEGORY;
    let CATEGORY_NODE = variation('feature--prismic-zendesk-migration') ? 'label_names' : 'tags';
    let categorizedArticles = {};
    articles.forEach(article =>
      this._categorizeArticle({
        article,
        categories: CATEGORIES_TYPES,
        defaultCategory: DEFAULT_CATEGORY,
        categoryNode: CATEGORY_NODE,
        categorizedArticles,
      })
    );
    return categorizedArticles;
  }

  shouldReturnEmptyArticles({ operationType }) {
    let FORBIDDEN_OPERATION_TYPES = ['unknown', 'other'];
    return FORBIDDEN_OPERATION_TYPES.includes(operationType);
  }

  async fetchArticlesByOperationType({ operationType, locale }) {
    let articles = [];
    let count = 0;

    if (this.shouldReturnEmptyArticles({ operationType })) return { articles, count };

    if (!variation('feature--prismic-zendesk-migration')) {
      let label = this.PRISMIC_OPERATION_TYPES_MAP[operationType];
      articles = await this.#prismicGetArticlesByLabel({ labels: [label] });
      count = articles.length;
      articles = this.#prismicSortArticlesByOrder({ articles });
    } else {
      let label = this.ZENDESK_OPERATION_TYPES_MAP[operationType];
      articles = await this._getArticlesByLabels({ labels: [label], locale });
      count = articles.length;
    }

    articles = this._articlesByCategories({ articles });

    return { articles, count };
  }

  categoriesTypesFromArticles({ articles = {} }) {
    let DEFAULT_CATEGORY = variation('feature--prismic-zendesk-migration')
      ? this.ZENDESK_DEFAULT_CATEGORY
      : this.PRISMIC_DEFAULT_CATEGORY;
    return Object.keys(articles).sort((a, b) => {
      if (a === DEFAULT_CATEGORY) {
        return 1;
      } else if (b === DEFAULT_CATEGORY) {
        return -1;
      }
      return 0;
    });
  }
}
