/**
 * Recursively removes null values from an object.
 *
 * @param {Object} obj - The object to be processed.
 * @returns {Object} - The object with null values removed.
 */
export default function removeNullValues(obj) {
  let entries = Object.entries(obj).filter(([, val]) => val !== null);

  return entries.reduce((accumulator, [key, value]) => {
    if (typeof value === 'object') {
      value = removeNullValues(value);
      if (Object.keys(value).length === 0) {
        return accumulator;
      }
    }
    return { ...accumulator, [key]: value };
  }, {});
}
