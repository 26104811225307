export default function getResourcesIdsToFetch(transactions) {
  let attachmentsToFetch = [];
  let membershipsToFetch = [];

  // Parse transactions to get fetchable ids
  transactions.forEach(transaction => {
    // Get all attachments ids to fetch
    if (transaction.attachmentIds && transaction.attachmentIds.length) {
      attachmentsToFetch.push(...transaction.attachmentIds);
    }

    // Get all initiators to fetch
    if (transaction.initiatorId) {
      // Check membership uniqueness
      if (!membershipsToFetch.includes(transaction.initiatorId)) {
        membershipsToFetch.push(transaction.initiatorId);
      }
    }
  });

  return { attachmentsToFetch, membershipsToFetch };
}
