export async function loadScript(
  src,
  { scriptId = '', insertBeforeAllTags = false, defer = false, type = null } = {}
) {
  await new Promise((resolve, reject) => {
    let script = document.createElement('script');
    script.id = scriptId;
    script.onload = resolve;
    script.onerror = event => {
      reject(new ExternalScriptError(event.target.src));
    };
    script.src = src;
    if (defer) script.defer = true;
    if (type) script.type = type;

    if (insertBeforeAllTags) {
      let firstScriptTagInDocument = document.getElementsByTagName('script')[0];
      firstScriptTagInDocument.parentNode.insertBefore(script, firstScriptTagInDocument);
    } else {
      document.body.appendChild(script);
    }
  });
}

export class ExternalScriptError extends Error {
  constructor(url) {
    let message = `Failed to load script at ${url}`;
    super(message);
    this.name = 'ExternalScriptError';
    this.url = url;
  }
}
