function serializeLabelExpression(property, operator, values) {
  let labelProperty = ['exists', 'not_exists'].includes(operator) ? 'label_list_ids' : 'label_ids';

  if (labelProperty === 'label_list_ids') {
    values = [property];
  }

  if (operator === 'exists') {
    operator = 'in';
  } else if (operator === 'not_exists') {
    operator = 'not_in';
  }

  return {
    property: labelProperty,
    operator,
    values,
  };
}

function deserializeLabelExpression({ property, operator, values }, labelLists) {
  let labelList;

  if (property === 'label_ids') {
    labelList = labelLists.find(list => list.labels.find(({ id }) => id === values[0]));
  } else {
    labelList = labelLists.find(({ id }) => id === values[0]);
  }

  property = labelList?.id;

  // This means the expression is: "Label list is empty/is not empty"
  if (values[0] === property) {
    values = [];

    if (operator === 'in') {
      operator = 'exists';
    } else if (operator === 'not_in') {
      operator = 'not_exists';
    }
  }

  return { property, operator, values };
}

function isLabelProperty(value, property, labelLists = []) {
  let labelId = value || property;

  return labelLists.some(
    ({ labels, id: labelListId }) =>
      labels.find(({ id }) => id === labelId) || labelListId === labelId
  );
}

function deserializeExpressions(conditional, expressions, labelLists) {
  let defaultGroup = { conditional, expressions: [] };
  let groups = [defaultGroup];

  expressions.forEach(expression => {
    if (['label_ids', 'label_list_ids'].includes(expression.property)) {
      expression = deserializeLabelExpression(expression, labelLists);
    }

    if (expression.expressions) {
      let expressions = expression.expressions.map(it => {
        if (['label_ids', 'label_list_ids'].includes(it.property)) {
          return deserializeLabelExpression(it, labelLists);
        }

        return it;
      });

      expression.expressions = expressions;

      groups.push(expression);
    } else {
      defaultGroup.expressions.push(expression);
    }
  });

  return groups.filter(({ expressions }) => expressions.length);
}

function serializeExpressions(expressions, labelLists) {
  return expressions.map(({ expressions, conditional }) => {
    let group = { conditional, expressions: [] };

    expressions.forEach(({ property, operator, values }) => {
      let isLabel = isLabelProperty(values?.[0], property, labelLists);

      if (isLabel) {
        let {
          property: newProperty,
          operator: newOperator,
          values: newValues,
        } = serializeLabelExpression(property, operator, values);

        property = newProperty;
        operator = newOperator;
        values = newValues;
      }

      group.expressions.push({ property, operator, values });
    });

    return group;
  });
}

export { deserializeExpressions, serializeExpressions, isLabelProperty };
