import JSONAPISerializer from 'qonto/serializers/-json-api';
import {
  removeNationalityFromArray,
  serializeNationalities,
} from 'qonto/utils/update-nationalities';

export default class KycKybUpdateMembershipChangeRequestSerializer extends JSONAPISerializer {
  normalize(modelClass, resourceHash) {
    // Handle the nationality and nationalities logic by ensuring
    // that nationality is removed from nationalities for the view layer.
    let nationality = resourceHash.attributes.nationality;
    let nationalities = resourceHash.attributes.nationalities || [];

    if (nationality && nationalities.includes(nationality)) {
      resourceHash.attributes.nationalities = removeNationalityFromArray(
        nationalities,
        nationalities.indexOf(nationality)
      );
    }

    return super.normalize(modelClass, resourceHash);
  }

  serialize(snapshot) {
    let json = super.serialize(...arguments);

    // Handle adding nationality back to nationalities before sending to the server.
    let nationality = snapshot.attr('nationality');
    let nationalities = snapshot.attr('nationalities');

    json.data.attributes.nationalities = serializeNationalities(nationalities, nationality);

    return json;
  }
}
