/**
 * Add a document to a model in the store.
 *
 * @public
 * @param {Object} store - Ember store
 * @param {Object} model - Ember data model to link the document to
 * @param {Object} docType - Doctype of the document
 * @param {Object} file - File (document) added to the model
 * @returns {void}
 */
export function addDocumentFileToModel(store, model, docType, file) {
  let doc = model.documents.find(item => item.docType === docType);
  if (doc) {
    if (!doc.files) {
      doc.files = [];
    }
    if (!doc.files.includes(file)) {
      doc.files.push(file);
      doc.notifyPropertyChange('files');
    }
  } else {
    let doc = store.createRecord('document', {
      docType,
      files: [file],
    });
    model.documents.push(doc);
    model.notifyPropertyChange('documents');
  }
}

/**
 * Remove a document to a model in the store.
 *
 * @public
 * @param {Object} store - Ember store
 * @param {Object} model - Ember data model to unlink the document
 * @param {Object} docType - Doctype of the document
 * @param {Object} file - File (document) in the model
 * @returns {void}
 */
export function removeDocumentFileFromModel(store, model, docType, file) {
  let doc = model.documents.find(item => item.docType === docType);
  let files = doc.files;
  if (files.length === 1 && files[0] === file) {
    return doc.destroyRecord();
  } else {
    let index = files.indexOf(file);
    files.splice(index, 1);
  }
  model.notifyPropertyChange('documents');
}
