import ApplicationSerializer from './application';

export default class HubActivationSerializer extends ApplicationSerializer {
  modelNameFromPayloadKey() {
    return 'hub-activation';
  }
  payloadKeyFromModelName() {
    return 'activation';
  }
}
