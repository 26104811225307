/**
 * Checks if the given value is a function.
 *
 * @param {*} value - The value to check.
 * @returns {boolean} Returns `true` if the value is a function, otherwise `false`.
 *
 * @example
 * // Returns true
 * isFunction(() => {});
 *
 * // Returns true
 * isFunction(function() {});
 *
 * // Returns false
 * isFunction(42);
 *
 * // Returns false
 * isFunction(null);
 */
export default function isFunction(value) {
  return typeof value === 'function';
}
