import { computed } from '@ember/object';

import { buildValidations, validator } from 'ember-cp-validations';

import { DEBOUNCE_MS } from 'qonto/constants/timers';

const VAT_VALIDATIONS = {
  ES: /^(ES)\w{9}$/,
  IT: /^(IT)\d{11}$/,
  DE: /^(DE)\d{9}$/,
};

const OrganizationValidations = buildValidations(
  {
    name: [validator('ds-error'), validator('presence', true)],
    'address.firstLine': [
      validator('ds-error'),
      validator('presence', true),
      validator('belongs-to'),
    ],
    proofOfAddress: [
      validator('ds-error'),
      validator('presence', {
        presence: true,
        messageKey: 'validations.errors.upload_company_proof_of_address_error',
      }),
    ],
    kbis: [
      validator('ds-error'),
      validator('presence', { presence: true, messageKey: 'validations.errors.upload_kbis_error' }),
    ],
    byLaws: [
      validator('ds-error'),
      validator('presence', {
        presence: true,
        messageKey: 'validations.errors.upload_by_laws_error',
      }),
    ],
    shareholdersListIncludedInByLaws: [
      validator('inline', {
        validate: value => value || 'error', // Only valid if shareholdersListIncludedInByLaws is true
      }),
    ],
    notaryMentionsIncludedInByLaws: [
      validator('inline', {
        validate: value => value || 'error',
      }),
    ],
    legalTvaNumber: [
      validator('format', {
        regex: computed('model.legalCountry', function () {
          return VAT_VALIDATIONS[this.model.legalCountry];
        }),
        messageKey: 'validations.errors.vat_number.format',
      }),
    ],
  },
  {
    debounce: DEBOUNCE_MS,
  }
);

export default OrganizationValidations;
