import { computed } from '@ember/object';

import { buildValidations, validator } from 'ember-cp-validations';

import { DEBOUNCE_MS } from 'qonto/constants/timers';

const VAT_VALIDATIONS = {
  FR: /^\d{14}$/,
  IT: /^\d{11}$/,
};

const MAX_ESTIMATED_ANNUAL_TURNOVER = 100000000; // 100 million
const MAX_SINGLE_PAYMENT_VALUE = 2100000000; // 2.1 billion

const KycKybUpdateOrganizationChangeRequestValidations = buildValidations(
  {
    addressCity: {
      validators: [validator('ds-error'), validator('presence', true)],
    },
    addressFirstLine: {
      validators: [
        validator('ds-error'),
        validator('presence', true),
        validator('length', { min: 2 }),
      ],
    },
    addressZipCode: {
      validators: [
        validator('ds-error'),
        validator('presence', true),
        validator('length', { min: 2 }),
      ],
    },
    contactEmail: {
      validators: [
        validator('ds-error'),
        validator('presence', true),
        validator('format', {
          type: 'email',
          minTldLength: 2,
        }),
      ],
    },
    legalName: [validator('ds-error'), validator('presence', true)],
    legalCode: [validator('ds-error'), validator('presence', true)],
    legalNumber: [validator('ds-error'), validator('presence', true)],
    legalSector: [validator('ds-error'), validator('presence', true)],
    legalRegistrationDate: [validator('ds-error'), validator('presence', true)],
    taxCountry: [validator('ds-error'), validator('presence', true)],
    taxIdentificationNumber: [
      validator('tin-format', {
        taxCountryFormat: computed('model.taxCountry', function () {
          return VAT_VALIDATIONS[this.model.taxCountry];
        }),
        messageKey: {
          wrongFormat:
            'organizations.profile.company_profile.organization_edit.tax_identification_number_error',
          fieldIsMissing: 'validations.errors.blank',
        },
      }),
    ],
    annualTurnover: [
      validator('presence', {
        presence: true,
      }),
    ],
    estimatedAnnualTurnover: [
      validator('presence', {
        presence: true,
        ignoreBlank: true,
        messageKey:
          'organizations.profile.company_profile.organization_edit.business_finances.annual_turnover.amount.error-message',
      }),
      validator('number', {
        allowString: true,
        gte: 0,
        lte: MAX_ESTIMATED_ANNUAL_TURNOVER,
        messageKey:
          'organizations.profile.company_profile.organization_edit.business_finances.annual_turnover.amount.error-message-amount',
      }),
    ],
    yearlyTransactionVolume: [
      validator('presence', {
        presence: true,
      }),
    ],
    maxSinglePaymentAmount: [
      validator('presence', {
        presence: true,
        ignoreBlank: true,
      }),
      validator('number', {
        allowString: true,
        gte: 0,
        lte: MAX_SINGLE_PAYMENT_VALUE,
        messageKey:
          'organizations.profile.company_profile.organization_edit.business_finances.max_single_payment_amount.error-message-amount',
      }),
    ],
    natureOfOperations: [
      validator('collection', {
        collection: true,
        messageKey: 'validations.errors.blank',
      }),
    ],
    activityDescription: [
      validator('presence', {
        presence: true,
        messageKey:
          'organizations.profile.company_profile.organization_edit.activity_description_mandatory.error-message',
      }),
      validator('length', {
        min: 10,
        messageKey:
          'organizations.profile.company_profile.organization_edit.activity_description_mandatory.error-message-short-input',
      }),
      validator('length', {
        max: 1000,
        messageKey:
          'organizations.profile.company_profile.organization_edit.activity_description_mandatory.error-message-long-input',
      }),
    ],
  },
  {
    debounce: DEBOUNCE_MS,
  }
);

export default KycKybUpdateOrganizationChangeRequestValidations;
