import { DEACTIVATION_REASON_DETAILS, ORGA_STATUS } from 'qonto/constants/organization';

const DEACTIVATION_REQUIREMENTS = [
  'accountBalanceZero',
  'balanceEqualToAuthorizedBalance',
  'noActiveSavingsAccount',
  'noPendingChecks',
];

export function hasRequirementsFulfilled(deactivation) {
  return DEACTIVATION_REQUIREMENTS.every(key => deactivation.requirements?.[key]);
}

export function isInDeactivation(organization, deactivationData) {
  let { status } = organization;
  let { ongoing } = deactivationData || {};

  return status !== ORGA_STATUS.SUSPENDED && ongoing;
}

export function isInDeactivationDueToExpiredDueDiligence(deactivationData) {
  let { requester, reasonDetails } = deactivationData || {};

  return (
    requester === 'qonto' && reasonDetails === DEACTIVATION_REASON_DETAILS.EXPIRED_DUE_DILIGENCE
  );
}
