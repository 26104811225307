import ApplicationSerializer from 'qonto/serializers/application';

export default class ApprovalWorkflowStateStepSerializer extends ApplicationSerializer {
  // back-end doesn't return id for steps
  normalize(model, hash, prop) {
    if (hash && !hash.id) {
      hash.id = crypto.randomUUID();
    }
    return super.normalize(model, hash, prop);
  }
}
