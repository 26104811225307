import CATEGORIES from 'qonto/constants/categories';

const { codes } = CATEGORIES;

export const TRANSACTION_TYPES = {
  CARD: 'Card',
  DIRECT_DEBIT: 'DirectDebit',
  INCOME: 'Income',
  TRANSFER: 'Transfer',
  CHECK: 'Check',
  PAGOPA_PAYMENT: 'PagopaPayment',
  FINANCING_INCOME: 'FinancingIncome',
  DIRECT_DEBIT_COLLECTION: 'DirectDebitCollection',
  DIRECT_DEBIT_COLLECTION_R_TRANSACTION: 'DirectDebitCollectionRTransaction',
  DIRECT_DEBIT_HOLD: 'DirectDebitHold',
};

function getCodes(transactionType) {
  return Object.values(TRANSACTION_TYPES).includes(transactionType)
    ? CATEGORIES[transactionType]
    : codes;
}

export default function filterActivityTagCodes(transactionTypes, transactionType) {
  let filteredCodes = [];

  if (transactionTypes?.length) {
    let codesForTypes = transactionTypes.map(getCodes);

    filteredCodes = codesForTypes.reduce((commonCodes, currentCodes) =>
      commonCodes.filter(code => currentCodes.includes(code))
    );
  } else if (transactionType) {
    filteredCodes = getCodes(transactionType);
  } else {
    filteredCodes = codes;
  }

  return filteredCodes;
}
