import JSONAPISerializer from './-json-api';

export default class RegistrationSerializer extends JSONAPISerializer {
  modelNameFromPayloadKey(key) {
    if (key === 'organizations') {
      return 'partner-organization';
    }
    return super.modelNameFromPayloadKey(key);
  }
}
