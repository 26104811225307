import { NotFoundError } from '@ember-data/adapter/error';
import Service, { service } from '@ember/service';

export default class OnBehalfRegistration extends Service {
  @service store;

  async loadOnBehalfRegistration(organizationId) {
    try {
      return await this.store.findRecord('on-behalf-registration', organizationId);
    } catch (error) {
      if (!(error instanceof NotFoundError)) {
        throw error;
      }
      // If it's a 404, the error is ignored
    }
  }
}
