import { PAY_OUT } from 'qonto/constants/international-out/quote';
import type { Quote } from 'qonto/services/international-out/types';

/**
 * Determines if the SWIFT network is used based on the payment option.
 *
 * @param quote - The transfer quote object.
 * @param quote.payOut - The pay out option.
 * @returns True if the SWIFT network is used (OUR or SHA), false otherwise.
 */
export function isSwiftNetwork(quote: Pick<Quote, 'payOut'>): boolean {
  return isSwiftOur(quote) || isSwiftSha(quote);
}

/**
 * Checks if the payment option is SWIFT OUR.
 * In that case, the sender pays both his and the beneficiary's bank charges.
 *
 * @param quote - The transfer quote object.
 * @param quote.payOut - The pay out option.
 * @returns True if the payment option is SWIFT OUR, false otherwise.
 */
export function isSwiftOur({ payOut }: Pick<Quote, 'payOut'>): boolean {
  return payOut === PAY_OUT.OUR;
}

/**
 * Checks if the payment option is SWIFT SHA.
 * In that case, the transfer fees are shared between the sender and the beneficiary of the funds.
 *
 * @param quote - The transfer quote object.
 * @param quote.payOut - The pay out option.
 * @returns True if the payment option is SWIFT SHA, false otherwise.
 */
export function isSwiftSha({ payOut }: Pick<Quote, 'payOut'>): boolean {
  return payOut === PAY_OUT.SHA;
}
