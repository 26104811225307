import SupplierInvoicesUploadManager from 'qonto/services/supplier-invoices-upload-manager';

const QUEUE_NAME = 'pay-by-invoice';

export default class PayByInvoiceUploadManager extends SupplierInvoicesUploadManager {
  queueName = QUEUE_NAME;

  constructor() {
    super(...arguments, {
      queueName: QUEUE_NAME,
    });
  }
}
