import ApplicationSerializer from 'qonto/serializers/application';

export default class OrganizationSubscriptionNewSerializer extends ApplicationSerializer {
  modelNameFromPayloadKey(payloadKey) {
    let key = payloadKey;
    if (payloadKey === 'subscriptions') {
      key = 'organization-subscription';
    }

    return super.modelNameFromPayloadKey(key);
  }

  payloadKeyFromModelName() {
    return 'subscription';
  }

  normalizeResponse(store, primaryModelClass, payload, id, requestType) {
    let products = [];

    // ensures when subscription is created, new subscription is kept first
    // https://gitlab.qonto.co/front/qonto-web/-/merge_requests/6713
    payload.subscriptions?.sort(({ product }) => (product?.type === 'core' ? -1 : 1));

    payload.subscriptions?.forEach(val => {
      if (val.product) {
        val.product_id = val.product.id;
        val.product.product_type = val.product.type;
        delete val.product.type;
        products.push(val.product);
        delete val.product;
      }
      val.available_trials = payload.available_trials;
      val.trial_by_features = payload.trial_by_features;
    });

    delete payload.available_trials;
    delete payload.trial_by_features;

    if (products.length) {
      payload['subscription-product'] = products;
    }

    return super.normalizeResponse(store, primaryModelClass, payload, id, requestType);
  }
}
