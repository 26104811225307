import { safeLocalStorage } from 'qonto/helpers/safe-local-storage';

/**
 * @typedef {import('./config-types').TopBannerConfig} TopBannerConfig
 */

/**
 * @typedef {import('./config-types').Services} Services
 */

/**
 * @param {Services} services - The parameters for the function.
 */
function model({ cardsManager }) {
  let { counters } = cardsManager;
  let countVirtual = counters?.virtual_renewal || 0;
  let countPhysical = counters?.physical_waiting_to_renew || 0;

  return {
    counters,
    countVirtual,
    countPhysical,
  };
}

/**
 * @type {Array<TopBannerConfig>}
 */
export const CARDS_RENEW_CONFIG = [
  {
    id: 'virtual-cards-renew',
    type: 'warning',
    model,
    componentName: 'topbar/cards/virtual-renew',
    message: ({ countVirtual }, intl) => {
      return intl.t('topbar_msg.cards.virtual-renew.text', { count: countVirtual });
    },
    shouldDisplay: ({ countVirtual, counters }) => {
      if (!counters) {
        return false;
      }

      let wasDismissed = Boolean(safeLocalStorage.getItem('virtual-renewed-banner-dismissed'));

      if (countVirtual > 0 && !wasDismissed) {
        return true;
      } else if (countVirtual === 0 && wasDismissed) {
        safeLocalStorage.removeItem('virtual-renewed-banner-dismissed');
      }
      return false;
    },
  },
  {
    id: 'physical-cards-renew',
    type: 'warning',
    model,
    componentName: 'topbar/cards/physical-renew',
    message: ({ countPhysical }, intl) => {
      return intl.t('topbar_msg.cards.renew.text', { count: countPhysical });
    },
    shouldDisplay: ({ countPhysical }) => {
      return countPhysical > 0;
    },
  },
];
