import { service } from '@ember/service';

import ApplicationSerializer from './application';

export default class ExportTemplateSerializer extends ApplicationSerializer {
  @service organizationManager;

  serialize() {
    let data = super.serialize(...arguments);
    data.organization_id = this.organizationManager.organization.id;
    return data;
  }
}
