import Service, { service } from '@ember/service';

import { variation } from 'ember-launch-darkly';

import { GMI_SOLUTION_INSTANCE_EVENTS } from 'qonto/constants/listeners';

export default class GmiSolutionInstanceManager extends Service {
  @service notifierManager;
  @service store;

  hasSubscription = false;

  willDestroy() {
    if (this.hasSubscription) {
      this.unsubscribe();
    }

    super.willDestroy(...arguments);
  }

  subscribe() {
    if (variation('feature--bolean-gmi-async-flow')) {
      if (!this.hasSubscription) {
        this.notifierManager.on(GMI_SOLUTION_INSTANCE_EVENTS.ACTIVE, this, '_handleActiveStatus');
        this.notifierManager.on(GMI_SOLUTION_INSTANCE_EVENTS.FAILED, this, '_handleFailedStatus');
        this.notifierManager.on(
          GMI_SOLUTION_INSTANCE_EVENTS.CONNECTING,
          this,
          '_handleConnectingStatus'
        );

        this.notifierManager.on(
          GMI_SOLUTION_INSTANCE_EVENTS.ACTION_REQUIRED,
          this,
          '_handleActionRequiredStatus'
        );

        this.hasSubscription = true;
      }
    }
  }

  unsubscribe() {
    if (this.hasSubscription) {
      this.notifierManager.off(GMI_SOLUTION_INSTANCE_EVENTS.ACTIVE, this, '_handleActiveStatus');
      this.notifierManager.off(
        GMI_SOLUTION_INSTANCE_EVENTS.CONNECTING,
        this,
        '_handleConnectingStatus'
      );
      this.notifierManager.off(
        GMI_SOLUTION_INSTANCE_EVENTS.ACTION_REQUIRED,
        this,
        '_handleActionRequiredStatus'
      );
      this.notifierManager.off(GMI_SOLUTION_INSTANCE_EVENTS.FAILED, this, '_handleFailedStatus');

      this.hasSubscription = false;
    }
  }

  _handleActiveStatus(payload) {
    this.updateGmiSolutionInstance(payload);
    this.unsubscribe();
  }

  _handleConnectingStatus(payload) {
    this.updateGmiSolutionInstance(payload);
  }

  _handleActionRequiredStatus(payload) {
    this.updateGmiSolutionInstance(payload);
  }

  _handleFailedStatus(payload) {
    this.updateGmiSolutionInstance(payload);
  }

  updateGmiSolutionInstance(payload) {
    let { gmi_status, troubleshooting_url, gmi_solution_instance_id, solution_instance_id } =
      payload;

    let gmiSolutionInstance = this.store.peekRecord(
      'gmi-solution-instance',
      gmi_solution_instance_id
    );

    if (gmiSolutionInstance) {
      gmiSolutionInstance.setProperties({
        gmiStatus: gmi_status,
        troubleshootingUrl: troubleshooting_url,
      });
    } else {
      this.store.createRecord('gmi-solution-instance', {
        id: gmi_solution_instance_id,
        solutionInstanceId: solution_instance_id,
        gmiStatus: gmi_status,
        troubleshootingUrl: troubleshooting_url,
      });
    }
  }
}
