import ApplicationSerializer from 'qonto/serializers/application';

export default class AddressSerializer extends ApplicationSerializer {
  // this workaround is required
  // since ember-data expects ids when (de)serializing relationships
  // here: organization <-> address and membership <-> address
  // and the backend sends:
  // {
  //   organizations: [
  //     {
  //       address: {
  //         `id` <- add random id here
  //         contact_name: 'contact name',
  //         ...
  //       },
  //       contract_status: 'signed'
  //     }
  //   ]
  // }
  normalize(model, hash, prop) {
    if (hash && !hash.id) {
      hash.id = crypto.randomUUID();
    }
    return super.normalize(model, hash, prop);
  }
}
