/* eslint-disable @qonto/no-import-roles-constants, ember/require-computed-macros */
import { computed } from '@ember/object';
import { readOnly } from '@ember/object/computed';

import { buildValidations, validator } from 'ember-cp-validations';

import { ROLES } from 'qonto/constants/membership';

const InviteValidations = buildValidations({
  firstName: {
    validators: [
      validator('ds-error'),
      validator('presence', {
        presence: true,
        messageKey: 'validations.errors.presence',
      }),
      validator('format', {
        regex: /^[\p{L}\p{M}0-9 '-.]*$/gu,
        messageKey: 'validations.errors.special-characters',
      }),
    ],
  },

  lastName: {
    validators: [
      validator('ds-error'),
      validator('presence', {
        presence: true,
        messageKey: 'validations.errors.presence',
      }),
      validator('format', {
        regex: /^[\p{L}\p{M}0-9 '-.]*$/gu,
        messageKey: 'validations.errors.special-characters',
      }),
    ],
  },

  email: {
    validators: [
      validator('ds-error'),
      validator('presence', {
        presence: true,
        messageKey: 'validations.errors.presence',
      }),
    ],
  },

  team: {
    validators: [
      validator('ds-error'),
      validator('presence', {
        presence: true,
        messageKey: 'validations.errors.presence',
      }),
    ],
  },

  role: {
    validators: [
      validator('ds-error'),
      validator('presence', {
        presence: true,
        messageKey: 'validations.errors.presence',
      }),
    ],
  },

  nationality: {
    validators: [
      validator('ds-error'),
      validator('presence', {
        disabled: computed('model.kycRequired', function () {
          return !this.model.kycRequired;
        }),
        presence: true,
        message: computed('model.intl.locale', function () {
          return this.model.intl.t('validations.errors.blank');
        }),
      }),
    ],
  },

  birthdate: {
    validators: [
      validator('ds-error'),
      validator('presence', {
        presence: true,
        disabled: computed('model.role', function () {
          return this.model.get('role') === ROLES.REPORTING;
        }),
        messageKey: 'validations.errors.presence',
      }),
      validator('date', {
        disabled: computed('model.role', function () {
          return this.model.get('role') === ROLES.REPORTING;
        }),
        after: '1/1/1900',
        format: 'DD/MM/YYYY',
        messageKey: 'validations.errors.invalid_date',
      }),
    ],
  },

  address: validator('belongs-to'),

  proofOfIdentity: {
    validators: [
      validator('ds-error'),
      validator('presence', {
        disabled: true,
        presence: true,
        messageKey: 'validations.errors.upload_id_error',
      }),
    ],
  },

  monthlyTransferLimit: {
    validators: [
      validator('ds-error'),
      validator('number', {
        value(model) {
          return model.monthlyTransferLimit?.value;
        },
        dependentKeys: ['model.monthlyTransferLimit.value'],
        debounce: 0,
        gt: 0,
        allowString: true,
        messageKey: 'invitation-flow.expense-permissions.transfers.errors.monthly-eq-zero',
      }),
      validator('number', {
        value(model) {
          return model.monthlyTransferLimit?.value;
        },
        debounce: 0,
        dependentKeys: ['model.monthlyTransferLimit.value', 'model.perTransferLimit.value'],
        gte: readOnly('model.perTransferLimit.value'),
        allowString: true,
        messageKey: 'invitation-flow.expense-permissions.transfers.errors.too-low-monthly',
      }),
    ],
  },

  perTransferLimit: {
    validators: [
      validator('ds-error'),
      validator('number', {
        value(model) {
          return model.perTransferLimit?.value;
        },
        dependentKeys: ['model.perTransferLimit.value'],
        debounce: 0,
        gt: 0,
        allowString: true,
        messageKey: 'invitation-flow.expense-permissions.transfers.errors.per-transfer-eq-zero',
      }),
      validator('number', {
        value(model) {
          return model.perTransferLimit?.value;
        },
        debounce: 0,
        dependentKeys: ['model.monthlyTransferLimit.value', 'model.perTransferLimit.value'],
        disabled: computed(
          'model.{perTransferLimit.value,monthlyTransferLimit.value}',
          function () {
            let monthlyLimit = this.model.get('monthlyTransferLimit.value') ?? null;
            let perTransferLimit = this.model.get('perTransferLimit.value') ?? null;

            return monthlyLimit === null || perTransferLimit === null;
          }
        ),
        lte: readOnly('model.monthlyTransferLimit.value'),
        allowString: true,
        messageKey: 'invitation-flow.expense-permissions.transfers.errors.too-great-per-transfer',
      }),
    ],
  },
});

export default InviteValidations;
