import window from 'ember-window-mock';
/**
 * Default scroll behaviour based on user preferences
 * @return {Object} scroll options with a smooth or auto behavior depending on user system preferences.
 */
function defaultScrollOptions() {
  // window.matchMedia allow us to know if the user disabled motion animations on his computer
  let hasReducedMotion = window.matchMedia('(prefers-reduced-motion: reduce)').matches;
  let behavior = hasReducedMotion ? 'auto' : 'smooth';
  return { behavior };
}

/**
 * Finds the first scrollable parent of an element
 * @param {DOMElement} element to find scrollable parent
 */
function scrollableParent(element) {
  if (!element) {
    return null;
  }
  let overflowY = window.getComputedStyle(element).overflowY;
  if (!['visible', 'hidden'].includes(overflowY)) {
    return element;
  }
  return scrollableParent(element.parentElement) || document.body;
}

/**
 * scrollIntoView
 * Aim to scroll the view to a given DOM element, with a smooth or auto behavior depending on user system preferences.
 * @param {string | DOMElement} dom element you want to scroll into view
 * @param {Object} scroll options with optional offsetTop
 */

export default function scrollIntoView(selectorOrElement, options = {}) {
  let element =
    typeof selectorOrElement === 'string'
      ? document.querySelector(selectorOrElement)
      : selectorOrElement;

  if (!element) {
    return false;
  }

  options = { ...defaultScrollOptions(), ...options };
  let { offsetTop, ...opts } = options;
  if (offsetTop) {
    let scrollableElement = scrollableParent(element);
    let top = element.offsetTop - scrollableElement.offsetTop - offsetTop;
    opts = { ...opts, left: 0, top };
    scrollableElement.scrollTo(opts);
  } else {
    element.scrollIntoView(opts);
  }

  return true;
}
