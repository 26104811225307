import { safeLocalStorage } from 'qonto/helpers/safe-local-storage';

export const NEW_BADGE_LOCAL_STORAGE_KEY = 'new-badge-visited-routes';

export const newBadgeLocalStorageKey = userId => `${NEW_BADGE_LOCAL_STORAGE_KEY}-${userId}`;

/**
 * Move non user specific key to current user specific key
 * If value has previously been set using key without user id move it to key including user id.
 * We use the current user id, in most cases there will be single user using the browser.
 * In cases where multiple users use the same browser the other users will see the new badges again before they are hidden when the visit the route. It's unlikely this will happen often, and the migration is one off.
 */
function migrateToUserSpecificStorage(userId) {
  let nonUserSpecificStorageValue = safeLocalStorage.getItem(NEW_BADGE_LOCAL_STORAGE_KEY);
  if (nonUserSpecificStorageValue) {
    safeLocalStorage.removeItem(NEW_BADGE_LOCAL_STORAGE_KEY);
    safeLocalStorage.setItem(newBadgeLocalStorageKey(userId), nonUserSpecificStorageValue);
  }
}

/**
 * Get the visited routes in localStorage
 * @returns Set of visited routes
 */
export function getBadgeVisitedRoutes(userId) {
  migrateToUserSpecificStorage(userId);
  let existingRoutes = JSON.parse(safeLocalStorage.getItem(newBadgeLocalStorageKey(userId))) || [];

  return new Set(existingRoutes);
}

/**
 * Set the visited routes in localStorage
 * @param {object} menu item
 */
export function setStorageBadge(activeSubMenuItem, userId) {
  let visitedRoutes = getBadgeVisitedRoutes(userId);

  visitedRoutes.add(activeSubMenuItem.link.routeName);

  // Convert Set to an array and store it in localStorage
  safeLocalStorage.setItem(
    newBadgeLocalStorageKey(userId),
    JSON.stringify(Array.from(visitedRoutes))
  );
}

/**
 * Updates menu items new status based on localStorage storing if the route has been visited before.
 * A sub menu item will have the "new" badge if the `isNew` property is set to true in the item and the route name is not included in localStorage
 *
 * @param {Object[]} items - Array of menu items with optional `isNew`, and `groups` properties.
 * @returns {Object[]} Processed sub menu items with updated `isNew` statuses.
 */
export function processNewBadge(visitedRoutes) {
  return function (items) {
    return items.map(({ groups, ...attributes }) => ({
      ...attributes,
      groups: groups?.map(group => ({
        ...group,
        items: group.items.map(item => {
          return {
            ...item,
            isNew: item.isNew && !visitedRoutes.has(item.link.routeName),
          };
        }),
      })),
    }));
  };
}
