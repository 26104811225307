import JSONAPISerializer from 'qonto/serializers/-json-api';

export default class AccountsReceivableOnboardingSerializer extends JSONAPISerializer {
  payloadKeyFromModelName(modelName) {
    if (modelName === 'accounts-receivable-onboarding') {
      // Do not pluralize the payload key
      return 'accounts_receivable_onboarding';
    }
    return super.payloadKeyFromModelName(modelName);
  }
}
