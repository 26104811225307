// @ts-nocheck
import Service, { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { ZENDESK_ARTICLES_IDS, ZENDESK_DEFAULTS } from '@repo/shared-config/constants/zendesk';

export default class ZendeskLocalizationService extends Service {
  @service organizationManager;
  @service localeManager;
  @service sessionManager;

  COUNTRY_TO_HELP_CENTER_MAP = {
    fr: 'fr',
    de: 'de',
    it: 'it',
    es: 'es',
    // Fallback for FAQ articles that are not localized
    pt: 'fr',
    at: 'fr',
    be: 'fr',
    nl: 'fr',
  };

  @tracked _organizationCountry =
    this.COUNTRY_TO_HELP_CENTER_MAP[
      this.organizationManager?.organization?.legalCountry?.toLowerCase()
    ] || ZENDESK_DEFAULTS.UNAUTH_FALLBACK_COUNTRY;

  get organizationCountry() {
    return this._organizationCountry.toLowerCase();
  }

  set organizationCountry(value) {
    if (value)
      this._organizationCountry =
        this.COUNTRY_TO_HELP_CENTER_MAP[value.toLowerCase()] ||
        ZENDESK_DEFAULTS.UNAUTH_FALLBACK_COUNTRY;
  }

  calcZendeskLocale(locale = ZENDESK_DEFAULTS.AUTH_FALLBACK_LOCALE) {
    let orgCountry = this.organizationCountry;
    let userLocale = locale.toLowerCase();
    let zendeskLocale = userLocale;

    if (orgCountry !== userLocale) {
      zendeskLocale = ZENDESK_DEFAULTS.AUTH_FALLBACK_LOCALE;
    }
    return zendeskLocale;
  }

  get userLocale() {
    return this.calcZendeskLocale(
      this.localeManager?.locale || ZENDESK_DEFAULTS.AUTH_FALLBACK_LOCALE
    );
  }

  get localization() {
    let orgCountry = this.organizationCountry;
    let userLocale = this.userLocale;

    return {
      orgCountry,
      userLocale,
    };
  }

  get composeHCZendeskUrl() {
    return `https://support-${this.organizationCountry}.qonto.com/hc/${this.userLocale}`;
  }

  get composeSSOZendeskUrl() {
    let ZENDESK_SSO_BASE_URL =
      'https://qonto9015.zendesk.com/auth/v2/login/continue_with_sso_tracking';

    let paramsByCountry = {
      fr: {
        authOrigin: '17320677699345,true,true',
        brandId: '17320677699345',
      },
      it: {
        authOrigin: '23132128531089,true,true',
        brandId: '23132128531089',
      },
      es: {
        authOrigin: '23132160601361,true,true',
        brandId: '23132160601361',
      },
      de: {
        authOrigin: '23132188747153,true,true',
        brandId: '23132188747153',
      },
    };

    let { authOrigin, brandId } = paramsByCountry[this.organizationCountry];

    let authOriginParam = `auth_origin=${authOrigin}`;
    let brandIdParam = `brand_id=${brandId}`;
    let localeParam = `locale=${this.userLocale}`;
    let returnToParam = `return_to=${this.composeHCZendeskUrl}`;
    let queryString = `${authOriginParam}&${brandIdParam}&${localeParam}&${returnToParam}`;

    return `${ZENDESK_SSO_BASE_URL}?${queryString}`;
  }

  enableFAQAuthenticationInTesting = false;

  get getLocalizedHelpCenter() {
    if (!this.isProduction && !this.enableFAQAuthenticationInTesting) {
      return this.composeHCZendeskUrl;
    } else if (this.sessionManager.isAuthenticated) {
      return this.composeSSOZendeskUrl;
    } else {
      return this.composeHCZendeskUrl;
    }
  }

  getLocalizedArticle(articleId) {
    let orgCountry = this.organizationCountry;

    if (ZENDESK_ARTICLES_IDS[articleId]?.[orgCountry]) {
      return `${this.getLocalizedHelpCenter}/articles/${ZENDESK_ARTICLES_IDS[articleId]?.[orgCountry]}`;
    } else {
      return this.getLocalizedHelpCenter;
    }
  }
}

declare module '@ember/service' {
  interface Registry {
    'zendesk-localization': ZendeskLocalizationService;
  }
}
