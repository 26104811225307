import ApplicationSerializer from 'qonto/serializers/application';
import transformKeys from 'qonto/utils/transform-keys';

export default class BookkeepingTransactionSerializer extends ApplicationSerializer {
  modelNameFromPayloadKey(key) {
    switch (key) {
      case 'accounts':
        return 'bookkeeping-account';
      case 'account_entries':
        return 'bookkeeping-account-entry';
      case 'transactions':
        return 'bookkeeping-transaction';
      case 'transaction':
        return 'bookkeeping-transaction';
      default:
        return super.modelNameFromPayloadKey(key);
    }
  }

  payloadKeyFromModelName(key) {
    if (key === 'bookkeeping-transaction') {
      return 'transaction';
    }

    return super.payloadKeyFromModelName(key);
  }

  normalizeResponse(store, primaryModelClass, payload, id, requestType) {
    if (requestType === 'findRecord') {
      this._adjustVat(payload.transaction.vat);
      payload.transaction.vat = transformKeys(payload.transaction.vat);
    }

    if (requestType === 'updateRecord') {
      let transaction = payload.transaction;

      transaction.amount = {
        value: transaction.amount,
        currency: transaction.amount_currency,
      };

      transaction.local_amount = {
        value: transaction.local_amount,
        currency: transaction.local_amount_currency,
      };

      delete transaction.amount_currency;
      delete transaction.local_amount_currency;
    }

    return super.normalizeResponse(store, primaryModelClass, payload, id, requestType);
  }

  _adjustVat(vat) {
    if (vat.hasOwnProperty('items') && Array.isArray(vat.items) && vat.items.length > 0) {
      vat.items.forEach(item => (item.bookkeeping_transaction_id = item.transaction_id));
    }
    return vat;
  }
}
