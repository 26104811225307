import { EmbeddedRecordsMixin } from '@ember-data/serializer/rest';

import ApplicationSerializer from './application';

export default class HubConenctionSerializer extends ApplicationSerializer.extend(
  EmbeddedRecordsMixin
) {
  attrs = {
    rules: { embedded: 'always' },
  };
  modelNameFromPayloadKey() {
    return 'hub-connection';
  }
  payloadKeyFromModelName() {
    return 'connection';
  }
}
