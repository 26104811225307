import JSONAPISerializer from 'qonto/serializers/-json-api';

import extractErrors from './-standard-extract-errors';

export default class CustomerSerializer extends JSONAPISerializer {
  extractErrors = extractErrors;

  serialize(snapshot, options) {
    let json = super.serialize(snapshot, options);

    let countryCode = json.data.attributes.billing_address
      ? json.data.attributes.billing_address.country_code
      : json.data.attributes.country_code;
    if (countryCode !== 'IT') {
      delete json.data.attributes.recipient_code;
      delete json.data.attributes.province_code;
    }

    let kind = json.data.attributes.kind;
    if (kind === 'individual') {
      delete json.data.attributes.vat_number;
    }

    let { first_name, last_name, name } = json.data.attributes;
    if (first_name && last_name && !name) {
      json.data.attributes.name = `${first_name} ${last_name}`;
    }

    let context = snapshot.adapterOptions?.context || options?.adapterOptions?.context;
    // "Inline" address fields are deprecated but still required by the SDD Collections API.
    // https://openapi-master.staging.qonto-cbs.co/api-direct-debit-collections-orchestrator#tag/Clients/operation/create-client
    if (context !== 'sdd') {
      delete json.data.attributes.address;
      delete json.data.attributes.zip_code;
      delete json.data.attributes.city;
      delete json.data.attributes.province_code;
      delete json.data.attributes.country_code;
    }

    let billingCountryCode = json.data.attributes.billing_address?.country_code;
    if (billingCountryCode !== 'IT') {
      delete json.data.attributes.billing_address?.province_code;
    }

    let deliveryCountryCode = json.data.attributes.delivery_address?.country_code;
    if (deliveryCountryCode !== 'IT') {
      delete json.data.attributes.delivery_address?.province_code;
    }

    return json;
  }

  normalize(model, hash, prop) {
    // This is a workaround until BE provides billing_address in the payload
    let { billing_address, zip_code, city, address, country_code, province_code } = hash.attributes;
    if (!billing_address) {
      hash.attributes.billing_address = {
        street_address: address,
        zip_code,
        city,
        country_code,
        ...(province_code && {
          province_code,
        }),
      };
    }

    return super.normalize(model, hash, prop);
  }
}
