import { EmbeddedRecordsMixin } from '@ember-data/serializer/rest';

import ApplicationSerializer from './application';

export default class HubApplicationSerializer extends ApplicationSerializer.extend(
  EmbeddedRecordsMixin
) {
  modelNameFromPayloadKey() {
    return 'hub-application';
  }
  attrs = {
    activation: { embedded: 'always' },
  };
}
