import ApplicationSerializer from 'qonto/serializers/application';

export default class NrcPaymentSerializer extends ApplicationSerializer {
  modelNameFromPayloadKey() {
    return 'nrcPayment';
  }

  payloadKeyFromModelName() {
    return 'payment';
  }
}
