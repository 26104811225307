import Service, { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

import { restartableTask, timeout } from 'ember-concurrency';
import { variation } from 'ember-launch-darkly';

import { apiBaseURL, apiNamespace } from 'qonto/constants/hosts';
import {
  ALL_STATUS,
  COCKPIT_INSIGHTS,
  INSIGHT_LABELS,
  STATUS_TABS_MAP,
  TABS,
} from 'qonto/constants/supplier-invoice';
import { safeLocalStorage } from 'qonto/helpers/safe-local-storage';
import { COUNTER_ANIMATION_DURATION } from 'qonto/react/components/counter';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

function formatInsights(insights) {
  return insights.reduce((formatted, { type, data, label }) => {
    let value;

    if (type === 'amount') {
      value = data.amount.value;
    } else if (type === 'counter') {
      value = data.value;
    }

    return { ...formatted, [label]: Number(value) };
  }, {});
}

function calculateDebounce(previousInsights, currentInsights, statuses) {
  let debounce = 0;

  let hasChanges = status => currentInsights[status] !== previousInsights[status];

  statuses.forEach(status => {
    if (hasChanges(status)) {
      debounce += COUNTER_ANIMATION_DURATION;
    }
  });

  return debounce;
}

export class Insights {
  @tracked inbox;
  @tracked scheduled;
  @tracked paid;
  @tracked fxInvoices;
  @tracked missingAmounts;

  constructor(insights) {
    Object.assign(this, insights);
    this.inbox = insights[INSIGHT_LABELS.inboxAmount];
    this.scheduled = insights[INSIGHT_LABELS.scheduledAmount];
    this.paid = insights[INSIGHT_LABELS.paidAmount];
    this.fxInvoices = insights[INSIGHT_LABELS.fxInvoicesCounter];
    this.missingAmounts = insights[INSIGHT_LABELS.missingAmountsCounter];
  }
}

export default class SupplierInvoicesInsightsService extends Service {
  @service segment;
  @service organizationManager;
  @service networkManager;
  @service store;
  @service toastFlashMessages;
  @service abilities;

  insightsKey = `${COCKPIT_INSIGHTS}_${this.organizationManager.organization.id}`;

  @tracked cachedInsights = JSON.parse(safeLocalStorage.getItem(this.insightsKey)) || {};

  get currentInsights() {
    if (this.abilities.can('access insights supplierInvoice')) {
      let insights = this.fetchInsightsTask?.lastSuccessful?.value || {};
      return new Insights(insights);
    }
  }

  get previousInsights() {
    if (this.abilities.can('access insights supplierInvoice')) {
      return new Insights(this.cachedInsights);
    }
  }

  get isFetchingInsights() {
    return this.fetchInsightsTask.isRunning || this.fetchTotalCountTask.isRunning;
  }

  get isErrorFetchingInsights() {
    return Boolean(this.fetchInsightsTask.last?.isError);
  }

  get totalCount() {
    return this.fetchTotalCountTask.lastSuccessful?.value?.meta?.total_count || 0;
  }

  fetchInsightsParentTask = restartableTask(async () => {
    let insights = await this.fetchInsightsTask.perform();

    this.#trackInboxInsights(insights);

    await this.cacheInsightsTask.perform(insights).catch(ignoreCancelation);
  });

  fetchInsightsTask = restartableTask(async () => {
    let { insights } = await this.networkManager.request(
      `${apiBaseURL}/${apiNamespace}/supplier_invoices/insights`
    );

    return formatInsights(insights);
  });

  fetchTotalCountTask = restartableTask(async () => {
    return await this.store.query('supplier-invoice', {
      per_page: 1,
      page: 1,
      filter: {
        status: STATUS_TABS_MAP(
          variation('feature--boolean-approval-workflow-for-supplier-invoices')
        )[TABS.ALL_INVOICES],
      },
    });
  });

  cacheInsightsTask = restartableTask(async insights => {
    let { cachedInsights, insightsKey } = this;

    if (JSON.stringify(insights) !== JSON.stringify(cachedInsights)) {
      // We need to await in order to see the animation between the old and the new insights
      let debounce = calculateDebounce(cachedInsights, insights, ALL_STATUS);
      await timeout(debounce);

      safeLocalStorage.setItem(insightsKey, JSON.stringify(insights));
      this.cachedInsights = insights;
    }
  });

  fetchInsights() {
    return Promise.all([
      this.fetchInsightsParentTask.perform().catch(ignoreCancelation),
      this.fetchTotalCountTask.perform().catch(ignoreCancelation),
    ]);
  }

  #trackInboxInsights() {
    if (
      Number(this.previousInsights?.[INSIGHT_LABELS.inboxCounter]) > 0 &&
      Number(this.currentInsights[INSIGHT_LABELS.inboxCounter]) === 0
    ) {
      this.segment.track('supplier-invoices_inbox0');
    }
  }
}
