import { max, min } from 'd3-array';

/**
 * Returns the minimum and the maximum of a series with specified conditions
 * @param {Array<number>} series Array of numerical values
 * @param {number} defaultMax Default maximum value for empty series
 * @param {number} headroom Scale headroom for extrema
 * @returns {Array<number>} Ordered array of [minimum, maximum]
 */
export function getExtrema(series, defaultMax = 1, headroom = 1) {
  let [minimum, maximum] = [0, defaultMax];
  let hasData = series?.some(value => value);

  if (hasData) {
    let valueMin = min(series, value => (value <= 0 ? value * headroom : value));
    let valueMax = max(series, value => (value >= 0 ? value * headroom : value));

    maximum = valueMax > 0 ? valueMax : 0;
    minimum = valueMin < 0 ? -max([Math.abs(valueMin), valueMax]) : 0;
  }

  return [minimum, maximum];
}
