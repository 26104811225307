import { EmbeddedRecordsMixin } from '@ember-data/serializer/rest';

import ApplicationSerializer from 'qonto/serializers/application';

export default ApplicationSerializer.extend(EmbeddedRecordsMixin, {
  attrs: {
    attachments: {
      deserialize: 'ids',
      serialize: 'records',
    },
  },
});
