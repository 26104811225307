import ApplicationSerializer from 'qonto/serializers/application';

export default class ReferralSerializer extends ApplicationSerializer {
  // Moves total_reward_* metadata from root to meta
  normalizeResponse(store, primarymodelclass, payload, id, requesttype) {
    let {
      total_reward_amount,
      total_reward_amount_cents,
      total_reward_amount_currency,
      ...newPayload
    } = payload;

    let totalMetaData = {
      total_reward_amount,
      total_reward_amount_cents,
      total_reward_amount_currency,
    };

    newPayload.meta = {
      ...totalMetaData,
      ...newPayload.meta,
    };

    return super.normalizeResponse(store, primarymodelclass, newPayload, id, requesttype);
  }
}
