import { action } from '@ember/object';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

import formatFileSize from '@qonto/ui-kit/utils/format-bytes';
import { enqueueTask } from 'ember-concurrency';

import BaseInvoicesUploadManager from 'qonto/services/invoices-upload-manager';
import { ErrorInfo } from 'qonto/utils/error-info';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

const QUEUE_NAME = 'accounts-receivable-onboarding';

export class FileUploadState {
  @tracked uploadFile;
  @tracked shouldToastOnInvalidFile = true;
  @tracked errors = [];
  @tracked isProcessing = false;

  constructor({ uploadFile }) {
    this.uploadFile = uploadFile;
  }

  get hasErrors() {
    return Boolean(this.errors?.length);
  }

  get isUploading() {
    return this.uploadFile && this.isProcessing;
  }

  startProcessing() {
    this.isProcessing = true;
  }

  finishProcessing() {
    this.isProcessing = false;
  }
}

export default class AccountsReceivableOnboardingUploadManager extends BaseInvoicesUploadManager {
  @service store;
  @service toastFlashMessages;

  maxSize = 10 * 1e6; // 10MB

  constructor() {
    super(...arguments, {
      queueName: QUEUE_NAME,
    });
  }

  get uploadUrl() {
    return this.store.adapterFor('accounts-receivable-onboarding').uploadURL;
  }

  uploadTask = enqueueTask({ maxConcurrency: 1 }, async fileUploadState => {
    let file = fileUploadState.uploadFile;
    try {
      fileUploadState.startProcessing();
      await file.upload(this.uploadUrl, {
        withCredentials: true,
        fileKey: 'file',
        data: {
          document_type: 'invoice',
        },
        headers: {
          'X-Qonto-Organization-ID': this.organizationManager.organization.id,
        },
      });

      fileUploadState.finishProcessing();
      this.onUploadFinished?.();
    } catch (error) {
      let errorInfo = ErrorInfo.for(error);

      if (errorInfo.shouldSendToSentry) {
        this.sentry.captureException(error, { cft: 'invoices' });
      }
      fileUploadState.errors = [this.intl.t('supplier-invoices.importing-modal.error.generic')];
      fileUploadState.finishProcessing();
      file.queue?.remove(file);
      this.onUploadErrors?.();
    }
  });

  @action
  upload(file) {
    let fileUploadState = new FileUploadState({ uploadFile: file });
    this.files = [...this.files, fileUploadState];

    if (file.size >= this.maxSize) {
      fileUploadState.errors = [
        this.intl.t('receivable-invoices.onboarding.invoice-upload.dropzone.invalid-file', {
          maxSize: formatFileSize(this.intl, this.maxSize),
        }),
      ];
      fileUploadState.finishProcessing();
      file.queue?.remove(file);

      if (this.shouldToastOnInvalidFile) {
        this.toastFlashMessages.toastError(fileUploadState.errors[0]);
      }

      return;
    }

    this.uploadTask.perform(fileUploadState).catch(ignoreCancelation);
  }
}
