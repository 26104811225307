import Service, { service } from '@ember/service';

import CURRENCIES from 'qonto/constants/currencies';

export default class BeneficiariesManager extends Service {
  @service store;

  async loadSepaBeneficiaries(organizationId, query) {
    return await this.loadBeneficiaries(organizationId, query, {
      hidden: false,
      fx: false,
      sct: true,
    });
  }

  async loadBeneficiaries(organizationId, query = null, filters = {}) {
    return await this.store.query('beneficiary', {
      organization_id: organizationId,
      filters,
      sort_by: 'name:asc',
      per_page: 150,
      query,
    });
  }

  createSepaBeneficiary(organization, prefilledOptions) {
    return this.store.createRecord('beneficiary', {
      organization,
      currency: CURRENCIES.default,
      vatRate: null,
      activityTag: 'other_expense',
      ...prefilledOptions,
    });
  }

  async getSEPABeneficiaryByIban(organizationId, iban) {
    let beneficiaries = await this.store.query('beneficiary', {
      organization_id: organizationId,
      sct: true,
      iban,
    });
    return beneficiaries[0];
  }
}
