export function adjustFlowDescription(flowDescription) {
  return Object.entries(flowDescription).reduce(
    (flowDescriptionAcc, [flowName, { steps, options }]) => ({
      [flowName]: {
        steps: Object.entries(steps).reduce(
          (stepValueAcc, [stepId, stepValue]) => ({
            [stepId]: {
              id: stepId,
              flowName,
              ...stepValue,
            },
            ...stepValueAcc,
          }),
          {}
        ),
        ...(options ? { options } : {}),
      },
      ...flowDescriptionAcc,
    }),
    {}
  );
}
