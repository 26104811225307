//@ts-nocheck
import type IntlService from 'ember-intl/services/intl';

function dayTranslation(intlService: IntlService, day: number): string {
  let days = [
    intlService.t('cards.settings.restrictions.active-days.weekday.sun'),
    intlService.t('cards.settings.restrictions.active-days.weekday.mon'),
    intlService.t('cards.settings.restrictions.active-days.weekday.tue'),
    intlService.t('cards.settings.restrictions.active-days.weekday.wen'),
    intlService.t('cards.settings.restrictions.active-days.weekday.thu'),
    intlService.t('cards.settings.restrictions.active-days.weekday.fri'),
    intlService.t('cards.settings.restrictions.active-days.weekday.sat'),
  ];

  return days[day];
}

export function activeDaysList(activeDays: number[], intlService: IntlService): string {
  if (!activeDays.length) throw new Error('Card has no specified active days');

  let isWeekendOnly = activeDays.length === 2 && activeDays.every(day => day === 6 || day === 0);
  if (isWeekendOnly) {
    return intlService.t('cards.settings.restrictions.active-days.weekend-enabled');
  }

  let isWeekdaysOnly = activeDays.length === 5 && !activeDays.some(day => day === 6 || day === 0);
  if (isWeekdaysOnly) {
    return intlService.t('cards.settings.restrictions.active-days.weekdays-enabled');
  }

  return activeDays
    .sort(function (a, b) {
      if (a === 0) return 1; // if a is sunday sort b before a
      if (b === 0) return -1; // if b is sunday sort a before b
      return a - b; // return normal week order
    })
    .map(day => dayTranslation(intlService, day))
    .join(', ');
}
