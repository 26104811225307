export function aggregateSelectedAttributes(account, remuneratedAccountDetails) {
  return {
    ...account?.getProperties(
      'id',
      'logo',
      'slug',
      'status',
      'accountType',
      'name',
      'authorizedBalance',
      'balanceCurrency',
      'main',
      'isRemunerated',
      'isExternalAccount'
    ),
    ...remuneratedAccountDetails?.getProperties(
      'currentMaturity',
      'maxMaturity',
      'startDate',
      'endDate',
      'isTaxApplicable',
      'interestRates',
      'maxMaturityEarnings'
    ),
  };
}
