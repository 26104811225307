/**
 * Parse the Content-Disposition response header
 * @param {Headers} headers
 * @returns {Map<string, string|boolean>}
 */
export let parseContentDisposition = headers => {
  let contentDisposition = headers.get('content-disposition');
  let keyValues = contentDisposition.split(';').map(keyValue => {
    let propKeyValue = keyValue.replaceAll('"', '').split('=');
    let key = propKeyValue[0].trim();
    let value = propKeyValue[1]?.replaceAll('"', '') ?? true;
    return [key, value];
  });
  return new Map(keyValues);
};
