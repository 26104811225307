// @ts-nocheck
import Service, { service } from '@ember/service';
import { dropTask } from 'ember-concurrency';

import { apiBaseURL, cashFlowCategoriesNamespace } from 'qonto/constants/hosts';
import { ErrorInfo } from 'qonto/utils/error-info';
import {
  CASHFLOW_REQUEST_DATE_FORMAT,
  type CashflowTimeseries,
} from 'qonto/react/api/models/cash-flow-timeseries';
import {
  getOrganizationTimeframeInterval,
  getPeriodTimeframeInterval,
} from 'qonto/react/utils/cash-flow-timeseries/period-timeframe-interval';
import {
  CashflowPeriodInterval,
  CashflowPeriodKey,
  type CashflowPeriod,
  type CashflowPeriodRate,
} from 'qonto/react/models/cash-flow-period';
import { mapTimeseriesToStatistics } from 'qonto/react/components/cash-flow/adapters/timeseries-statistics';
import dayjs from 'dayjs';

interface CashFlowTimeseriesManagerProps {
  period: CashflowPeriod;
  bankAccounts: string;
  frequency?: CashflowPeriodRate;
}

export default class CashFlowTimeseriesManager extends Service {
  @service organizationManager;
  @service networkManager;
  @service sentry;

  fetchTimeseriesTask = dropTask(async ({ bankAccounts, period, frequency }) => {
    const timeframe = frequency
      ? getOrganizationTimeframeInterval(this.organizationManager.organization)
      : getPeriodTimeframeInterval(period);

    const request = {
      bank_account_ids: bankAccounts,
      ...timeframe,
      ...(frequency && { aggregation_interval: frequency }),
    };

    try {
      let response: CashflowTimeseries = await this.networkManager.request(
        `${apiBaseURL}/${cashFlowCategoriesNamespace}/cash-flow/timeseries`,
        {
          method: 'POST',
          body: JSON.stringify(request),
        }
      );

      return mapTimeseriesToStatistics(response);
    } catch (error) {
      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }
    }
  });
}
