import ApplicationSerializer from '../application';

export default class F24BaseSerializer extends ApplicationSerializer {
  normalize(model, hash, prop) {
    if (hash && !hash.id) {
      hash.id = crypto.randomUUID();
    }
    return super.normalize(model, hash, prop);
  }
}
