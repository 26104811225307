const SOURCE_POINTER_REGEXP = /^\/?data\/(attributes|relationships)\/(.*)/;
const SOURCE_POINTER_PRIMARY_REGEXP = /^\/?data/;
const PRIMARY_ATTRIBUTE_KEY = 'base';

/**
  Convert an array of errors in JSON-API format into an object.
  ```javascript
  import { errorsArrayToHash } from '@ember-data/adapter/error';
  let errorsArray = [
    {
      title: 'Invalid Attribute',
      detail: 'Must be present',
      code: 'error_code',
      source: { pointer: '/data/attributes/name' }
    },
    {
      title: 'Invalid Attribute',
      detail: 'Must be present',
      source: { pointer: '/data/attributes/age' }
    },
    {
      title: 'Invalid Attribute',
      detail: 'Must be a number',
      source: { pointer: '/data/attributes/age' }
    }
  ];
  let errors = errorsArrayToHash(errorsArray, { useErrorCode: false });
  // {
  //   "name": ["Must be present"],
  //   "age":  ["Must be present", "must be a number"]
  // }
  ```
  @method errorsArrayToHash
  @public
  @param {Array} errors array of errors in JSON-API format
  @param {Object} options optional configuration object
  @return {Object}
*/
export function errorsArrayToHash(errors, options = {}) {
  let out = {};
  let { useErrorCode } = options;

  if (errors) {
    errors.forEach(error => {
      if (error.source && error.source.pointer) {
        let key = error.source.pointer.match(SOURCE_POINTER_REGEXP);

        if (key) {
          key = key[2];
        } else if (error.source.pointer.search(SOURCE_POINTER_PRIMARY_REGEXP) !== -1) {
          key = PRIMARY_ATTRIBUTE_KEY;
        }

        if (key) {
          out[key] = out[key] || [];
          out[key].push((useErrorCode && error.code) || error.detail || error.title);
        }
      }
    });
  }

  return out;
}
