import { EmbeddedRecordsMixin } from '@ember-data/serializer/rest';

import ApplicationSerializer from 'qonto/serializers/application';

export default class BudgetSerializer extends ApplicationSerializer.extend(EmbeddedRecordsMixin) {
  attrs = {
    activePeriod: { serialize: false },
    exercises: { embedded: 'always' },
  };

  serializeBelongsTo(snapshot, json, relationship) {
    if (relationship.key === 'assignedTo') {
      json.assigned_to = {
        id: snapshot.belongsTo('assignedTo').id,
        type: snapshot.belongsTo('assignedTo').modelName,
      };
    } else {
      super.serializeBelongsTo(snapshot, json, relationship);
    }
  }

  normalize(model, hash, prop) {
    hash.exercises.forEach(exercise => {
      exercise.budget_id = hash.id;
      exercise.periods.forEach(period => (period.exercise_id = exercise.id));
      if (hash.active_period) {
        let { period_id, amount_settled } = hash.active_period;
        let period = exercise.periods.find(({ id }) => id === period_id);
        if (period) {
          period.amount_settled = amount_settled;
          hash.active_period_id = period_id;
        }
      }
    });
    delete hash.active_period;
    return super.normalize(model, hash, prop);
  }
}
