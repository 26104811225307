import { service } from '@ember/service';

import ApplicationSerializer from 'qonto/serializers/application';

export default class extends ApplicationSerializer {
  @service organizationManager;
  @service abilities;

  serialize(snapshot, options) {
    let json = super.serialize(snapshot, options);

    delete json.approver_id;
    delete json.initiator_id;
    delete json.processed_at;
    delete json.created_at;
    delete json.reference;
    delete json.request_type;
    delete json.request_id;
    delete json.status;

    if (json.vehicle === null) delete json.vehicle;
    if (json.vehicle?.power === null) delete json.vehicle.power;

    let { arrival, departure, ...rest } = json;
    let normalizedJourneyDetails = {
      arrival: {
        formatted_address: arrival.formattedAddress,
        google_place_id: arrival.googlePlaceId,
      },
      departure: {
        formatted_address: departure.formattedAddress,
        google_place_id: departure.googlePlaceId,
      },
    };

    return { ...normalizedJourneyDetails, ...rest };
  }

  normalize(model, hash, prop) {
    let { organization } = this.organizationManager;
    let { arrival, departure, mileage_calculation, ...rest } = hash;
    let mileageCalculation = mileage_calculation;

    mileageCalculation.distanceMeters = mileage_calculation.distance_meters;
    if (mileage_calculation.rates) {
      mileageCalculation.rate = mileage_calculation.rates[0];
    }

    let normalizedJourneyDetails = {
      arrival: {
        formattedAddress: arrival.formatted_address,
        googlePlaceId: arrival.google_place_id,
      },
      departure: {
        formattedAddress: departure.formatted_address,
        googlePlaceId: departure.google_place_id,
      },
      mileage_calculation: organization.isFrench
        ? mileageCalculation
        : {
            distanceMeters: mileage_calculation.distance_meters,
            rate: mileage_calculation.rate,
          },
    };

    return super.normalize(model, { ...normalizedJourneyDetails, ...rest }, prop);
  }
}
