export function updateNationalityInArray(
  nationalities: string[],
  index: number,
  value: string
): string[] {
  let updatedNationalities = [...nationalities];
  updatedNationalities[index] = value;
  return updatedNationalities;
}

export function removeNationalityFromArray(nationalities: string[], index: number): string[] {
  return nationalities.filter((_, i) => i !== index);
}

export function addEmptyNationality(nationalities: string[]): string[] {
  return [...nationalities, ''];
}

export function removeEmptyNationalities(nationalities: string[]): string[] {
  return nationalities.filter(Boolean);
}

export function prependMainNationality(nationalities: string[], value: string): string[] {
  let updatedNationalities = nationalities.filter(n => n !== value);
  return [value, ...updatedNationalities];
}

export function isNationalitiesEmpty(nationalities: string[]): boolean {
  return nationalities.length === 0;
}

export function removeDuplicateNationalities(nationalities: string[]): string[] {
  return Array.from(new Set(nationalities));
}

export function serializeNationalities(
  nationalities: string[] | null,
  nationality: string | null
): string[] | null {
  if (!nationalities) {
    return nationality ? [nationality] : null;
  }

  let updatedNationalities = removeEmptyNationalities(nationalities);

  if (nationality) {
    updatedNationalities = prependMainNationality(updatedNationalities, nationality);
  }

  updatedNationalities = removeDuplicateNationalities(updatedNationalities);

  return isNationalitiesEmpty(updatedNationalities) ? null : updatedNationalities;
}
