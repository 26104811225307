import { buildValidations, validator } from 'ember-cp-validations';

const LabelListValidations = buildValidations({
  name: {
    validators: [
      validator('presence', {
        presence: true,
        ignoreBlank: true,
      }),
      validator('length', { max: 20 }),
    ],
  },
});

export default LabelListValidations;
