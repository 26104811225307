import { getOwner } from '@ember/application';
import { computed } from '@ember/object';

import { buildValidations, validator } from 'ember-cp-validations';

import { OPERATOR } from 'qonto/constants/reminders-configuration';

export default buildValidations({
  operator: [
    validator('presence', {
      presence: true,
      message() {
        return getOwner(this.model)
          .lookup('service:intl')
          .t('payment-reminders.form.validations.operator.presence');
      },
    }),
    validator('ds-error'),
  ],
  offsetDays: validator('presence', {
    presence: true,
    message() {
      return getOwner(this.model)
        .lookup('service:intl')
        .t('payment-reminders.form.validations.offset.presence');
    },
    disabled: computed('model.operator', function () {
      return ![OPERATOR.BEFORE, OPERATOR.AFTER].includes(this.model.operator);
    }),
  }),
  emailTitle: validator('presence', {
    presence: true,
    message() {
      return getOwner(this.model)
        .lookup('service:intl')
        .t('receivable-invoices.share-email.required-field');
    },
  }),
});
