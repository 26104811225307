import { DISCLAIMER_TYPES } from 'qonto/constants/transfers';
import cloneProperties from 'qonto/utils/clone-properties';
import { prepareLimitsData } from 'qonto/utils/transfers';

/**
 * Converts a transfer request into a transfer.
 * You need to specify the bank account associated with the transfer.
 *
 * @param {object} options - The options object containing necessary parameters.
 * @param {object} options.dataStore - The Ember Data store to create the transfer record.
 * @param {RequestTransferModel} options.transferRequest - The transfer request model instance containing transfer details.
 * @param {BankAccountModel} options.bankAccount - The bank account to associate the transfer with.
 * @returns {Promise<object>} A Promise that resolves to the created transfer model instance.
 */
export async function convertTransferRequestToTransfer({
  dataStore,
  transferRequest,
  bankAccount,
}) {
  let transfer = dataStore.createRecord('transfer', { bankAccount });

  await cloneProperties(transferRequest, transfer);

  transfer.email = transfer.beneficiaryEmail;
  transfer.iban = transfer.beneficiaryIban;
  transfer.name = transfer.beneficiaryName;
  transfer.bic = transfer.beneficiaryBic;

  return transfer;
}

/**
 * Computes an error message for quick transfer request approval based on certain conditions.
 *
 * @param {Object} intlService - The translation service.
 * @param {Array<string>} confirmationErrors - An array of errors related to the transfer and received from the server after transfer confirmation.
 *
 * @returns {string|null} An error message for quick transfer request approval or null if no error was found.
 */
export function getQuickTransferApproveErrorMessage(
  intlService,
  confirmationErrors,
  { hasMultipleActiveAccounts = false } = {}
) {
  if (!confirmationErrors.length) return null;

  let [errorCode] = confirmationErrors;

  switch (errorCode) {
    case 'expired':
      return intlService.t('transfers.toasts.quick-approve.error.expired');
    case 'iban_sepa_error':
      return intlService.t('transfers.toasts.quick-approve.error.not-sepa');
    case 'same_iban_error':
      return intlService.t('tasks.toast.same-iban');
    case DISCLAIMER_TYPES.BILLER_INSUFFICIENT_FUNDS:
      return hasMultipleActiveAccounts
        ? intlService.t('transfers.toasts.quick-approve.error.insufficient-funds-multiple-accounts')
        : intlService.t('transfers.toasts.quick-approve.error.insufficient-funds-single-account');
    default:
      return intlService.t('toasts.errors.generic');
  }
}

/**
 * Computes the success message for quick transfer request approval based on certain conditions.
 *
 * @param {Object} intlService - The translation service.
 * @param {RequestTransferModel|RequestMultiTransferModel} transferRequest - A transfer or multi-transfers request model instance.
 *
 * @returns {string} A success message for quick transfer request approval.
 */
export function getQuickTransferApproveSuccessMessage(intlService, transferRequest) {
  let messageOptions = {
    teamMember: transferRequest.initiator.get('fullName'),
  };

  let message = transferRequest.lastStep
    ? intlService.t('transfers.toasts.quick-approve.success.single', messageOptions)
    : intlService.t('approval-workflows.requests.approved.toast.success');

  if (!transferRequest.once && transferRequest.lastStep) {
    message =
      transferRequest.weekly || transferRequest.monthly
        ? intlService.t('transfers.toasts.quick-approve.success.recurring', messageOptions)
        : intlService.t('transfers.toasts.quick-approve.success.scheduled', messageOptions);
  }

  return message;
}

/**
 * Computes a warning message for quick transfer request approval based on certain conditions.
 *
 * @param {Object} intlService - The translation service.
 * @param {Array<string>} confirmationWarnings - An array of warnings related to the transfer and received from the server after transfer confirmation.
 * @param {Object} options - Additional options for customizing the warning message.
 * @param {boolean} options.hasMultipleActiveAccounts - Indicates whether there are multiple active accounts (current and/or remunarated accounts).
 * @param {Object} options.spendLimits - Object containing spending limit information received from the server after transfer confirmation.
 *
 * @returns {string|null} A warning message for quick transfer request approval or null if no warning is applicable.
 */
export function getQuickTransferApproveWarningMessage(
  intlService,
  confirmationWarnings,
  { hasMultipleActiveAccounts = false, spendLimits = {} } = {}
) {
  let blockingWarnings = [
    DISCLAIMER_TYPES.INSUFFICIENT_FUNDS,
    DISCLAIMER_TYPES.INSUFFICENT_MONTHLY_LIMITS,
    DISCLAIMER_TYPES.INSUFFICENT_PER_TRANSFER_LIMITS,
  ];

  let warnings = confirmationWarnings.filter(warning => blockingWarnings.includes(warning));
  if (!warnings.length) return null;

  let { monthly, monthSpendings, perTransfer } = prepareLimitsData(
    confirmationWarnings,
    spendLimits
  );

  let [warningCode] = warnings;

  switch (warningCode) {
    case DISCLAIMER_TYPES.INSUFFICIENT_FUNDS:
      return hasMultipleActiveAccounts
        ? intlService.t('transfers.toasts.quick-approve.error.insufficient-funds-multiple-accounts')
        : intlService.t('transfers.toasts.quick-approve.error.insufficient-funds-single-account');
    case DISCLAIMER_TYPES.INSUFFICENT_PER_TRANSFER_LIMITS:
      return intlService.t(`requests.transfers.sidebar.disclaimers.per-transfer-limit`, {
        per_transfer_limit: intlService.formatNumber(perTransfer),
      });
    case DISCLAIMER_TYPES.INSUFFICENT_MONTHLY_LIMITS:
      return intlService.t('requests.transfers.sidebar.disclaimers.monthly-transfer-limit', {
        balance_monthly_transfer_limit: intlService.formatNumber(
          Number(monthly) - Number(monthSpendings)
        ),
      });
    default:
      return null;
  }
}
