/**
 * Search for the active sub menu item from the active main menu item
 *
 * @param {object} item - The current active menu item
 * @returns {item|null} The active sub menu item, or null if no sub active item is found
 *
 */
export default function findActiveMainSubMenuItem(activeMenuItem) {
  if (!activeMenuItem?.groups) {
    return null;
  }
  for (let group of activeMenuItem.groups) {
    let activeItem = group.items.find(item => item.link.isActive);
    if (activeItem) {
      return activeItem;
    }
  }
  return null;
}
