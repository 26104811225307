import { assert } from '@ember/debug';
import { service } from '@ember/service';
import { DEBUG } from '@glimmer/env';

import { LOCALE_CODES, LOCALES } from '@repo/shared-config/constants/locales';
import IntlServiceBase from 'ember-intl/services/intl';

import CURRENCIES from 'qonto/constants/currencies';
import { differenceInCalendar } from 'qonto/utils/date';

export const defaultMoneyOptions = {
  minimumFractionDigits: 2,
  currency: CURRENCIES.default,
};

let optionsToIgnore = new Set(['htmlSafe', 'locale']);

/**
 * Replaces the `{` and `}` characters with `(` and `)` so that these
 * aren't interpreted as variables.
 *
 * Replaces the `\"` characters with `"` to allow additional escapes.
 * See https://github.com/ember-intl/ember-intl/issues/1035.
 */
function replaceInterpolators(input) {
  return String(input).replace(/\{/g, '(').replace(/\}/g, ')').replace(/\\"/g, '"');
}

function stringify(object) {
  return JSON.stringify(object, Object.keys(object).sort());
}

function stringifyOptions(options) {
  let filteredOptions = {};
  for (let [key, value] of Object.entries(options || {})) {
    if (optionsToIgnore.has(key)) {
      continue;
    }
    filteredOptions[key] = value;
  }
  return replaceInterpolators(stringify(filteredOptions));
}

/**
 * Returns the key and options in a way that helps end-developers
 * write tests.
 *
 * @private
 * @hide
 */
function _onMissingTranslation(key, _locales, options) {
  return `t:${key}:${stringifyOptions(options)}`;
}

export default class IntlService extends IntlServiceBase {
  @service sentry;

  getLocales = LOCALES;
  getLocaleCodes = LOCALE_CODES;

  // this is a proxy to the old _ee method present in v6 to allow
  // react-migration-toolkit/src/react/hooks/providers/use-ember-intl.ts
  // https://gitlab.qonto.co/npm-packages/react-migration-toolkit/-/blob/main/react-migration-toolkit/src/react/hooks/providers/use-ember-intl.ts#L11
  _ee = this._eventEmitter;

  // This is the default locale for the app, this has been changed in ember-intl v7.0.0
  // in order to maintain standard behaviour has been remapped during migration from v6 to v7
  get locale() {
    if (DEBUG) {
      // eslint-disable-next-line no-console
      console.warn('locale is deprecated, use intl.primaryLocale instead');
    }
    return this._locale;
  }

  // This is a proxy to an ember-intl internal property, this has been changed in ember-intl v7.0.0
  // https://ember-intl.github.io/ember-intl/docs/migration/v7#minimized-api-of-the-intl-service
  translationsFor(...args) {
    if (DEBUG) {
      // eslint-disable-next-line no-console
      console.warn('translationsFor is deprecated');
    }
    return this._intls[args[0]].messages;
  }

  // https://ember-intl.github.io/ember-intl/docs/guide/migration/v7#minimized-api-of-the-intl-service
  lookup(translationKey) {
    let translation = this.getTranslation(translationKey, this.primaryLocale);

    if (translation?.includes('Missing translation')) {
      this.sentry.captureException(new Error(translation));
    }
    return translation;
  }

  onMissingTranslation(key, _locale, options) {
    let missingError = _onMissingTranslation(key, _locale, options);
    this.sentry.captureException(missingError);

    if (DEBUG) {
      return missingError;
    } else {
      return '';
    }
  }

  /**
   * Format a number into currency
   *
   * @public
   * @method formatMoney
   *
   * @param {number} money
   * @param {Object} options
   * @param {number} options.digits - The minimum number of fraction digits to use. Possible values are from 0 to 20
   * @param {string} options.style - If 'currency' tries to prefix the amount with a currency symbol
   * @param {boolean|string} options.signus - Adds a string before the amount. If true, adds a '+' or '-' sign
   * @returns {string} A formatted currency string
   */
  formatMoney(money, options = {}) {
    assert('`style` option must not be used when calling `formatMoney()`', !options.style);

    let amount = money || 0;

    let opts = Object.assign({}, defaultMoneyOptions, options);
    let { digit } = opts;
    if (digit) {
      opts.minimumFractionDigits = Number(digit);
    }

    let { signus } = opts;
    if (signus === true) {
      signus = amount < 0 ? '-' : '+';
    }

    let formatted =
      this.formatNumber(!signus ? amount : Math.abs(amount), opts) + '\xA0' + opts.currency;

    if (signus) {
      formatted = `${signus}\xA0${formatted}`;
    }

    return formatted;
  }

  formatDateRelative(value, format = 'short') {
    let daysBeforeToday = value ? differenceInCalendar(new Date(), value, 'day') : 0;

    switch (daysBeforeToday) {
      case 0:
        return this.t('labels.today');
      case 1:
        return this.t('labels.yesterday');
      default:
        return this.formatDate(value, { format });
    }
  }

  formatCountry(value, options) {
    let regionNames = new Intl.DisplayNames(this.locale, { type: 'region', ...options });
    return regionNames.of(value);
  }

  formatNumber(value, options) {
    /**
     * Intl.NumberFormat throws errors on some browsers for some display options
     * https://github.com/WebKit/WebKit/blob/9cb35748705901155cb6d83963671c791046c035/Source/JavaScriptCore/runtime/IntlNumberFormat.cpp#L550-L586
     *
     * Fallback to reduced options if error is thrown, removing unstable options
     * cf. Google resolution: https://github.com/google/site-kit-wp/pull/3620/files
     */
    let fallbackOptions;
    try {
      return super.formatNumber(value, options);
    } catch {
      if (options.signDisplay) {
        fallbackOptions = { ...options, signDisplay: 'auto' };
      }
    }

    try {
      return super.formatNumber(value, fallbackOptions);
    } catch {
      delete options.signDisplay;
      delete options.currencyDisplay;

      return super.formatNumber(value, options);
    }
  }
}
