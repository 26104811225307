import Transform from '@ember-data/serializer/transform';
import { underscore } from '@ember/string';

import transformKeys from 'qonto/utils/transform-keys';

export default class NestedTransform extends Transform {
  deserialize(serialized) {
    if (!serialized) {
      return undefined;
    }
    return transformKeys(serialized);
  }

  serialize(deserialized) {
    if (!deserialized) {
      return undefined;
    }
    return transformKeys(deserialized, underscore);
  }
}
