import { get } from '@ember/object';
import { service } from '@ember/service';

import BaseValidator from 'ember-cp-validations/validators/base';

const UniqueAttribute = BaseValidator.extend({
  intl: service(),
  store: service(),

  validate(value, options, model, attribute) {
    return (
      this.store.peekAll(model.constructor.modelName).filter(item => get(item, attribute) === value)
        .length === 1 || this.intl.t(options.messageKey)
    );
  },
});

export default UniqueAttribute;
