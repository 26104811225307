import { EmbeddedRecordsMixin } from '@ember-data/serializer/rest';

import { PAYMENT_PURPOSE_TYPES } from 'qonto/constants/transfers';
import ApplicationSerializer from 'qonto/serializers/application';
import { normalizeMissingBankAccount } from 'qonto/utils/normalize-missing-bank-account';

export default ApplicationSerializer.extend(EmbeddedRecordsMixin, {
  attrs: {
    attachments: {
      serialize: 'ids',
      deserialize: 'ids',
    },
  },

  normalize(modelClass, resourceHash) {
    if (
      resourceHash.fx_payment_purpose === PAYMENT_PURPOSE_TYPES.UNSTRUCTURED &&
      resourceHash.fx_payment_purpose_unstructured
    ) {
      resourceHash.fx_payment_purpose = resourceHash.fx_payment_purpose_unstructured;
      delete resourceHash.fx_payment_purpose_unstructured;
    }

    normalizeMissingBankAccount(this.store, resourceHash);

    return this._super(modelClass, resourceHash);
  },

  serialize(snapshot, options) {
    let json = this._super(snapshot, options);

    if (json.credit_bank_account_id) {
      delete json.iban;
    }

    if (
      snapshot.record.get('fx') &&
      snapshot.record.get('paymentPurposeType') === PAYMENT_PURPOSE_TYPES.UNSTRUCTURED
    ) {
      json.fx_payment_purpose_unstructured = json.fx_payment_purpose;
      json.fx_payment_purpose = PAYMENT_PURPOSE_TYPES.UNSTRUCTURED;
    }

    return json;
  },
});
