import { APPROVAL_WORKFLOW_CONDITION_FIELDS } from 'qonto/constants/approval-workflow';

export async function generateConditionString({ field, value }, index, store, intl, errors) {
  let isAmountCondition = field === APPROVAL_WORKFLOW_CONDITION_FIELDS.AMOUNT;
  let isSupplierIdCondition = field === APPROVAL_WORKFLOW_CONDITION_FIELDS.SUPPLIER_ID;
  let isUploaderIdCondition = field === APPROVAL_WORKFLOW_CONDITION_FIELDS.UPLOADER_ID;

  if (isAmountCondition) {
    if (index === 0) {
      return intl.t('approval-workflows.ruleset.conditions.gt', {
        amount: intl.formatNumber(value.value, {
          currency: value.currency,
          style: 'currency',
        }),
      });
    }

    return intl.t('approval-workflows.ruleset.conditions.gte', {
      amount: intl.formatNumber(value.value, {
        currency: value.currency,
        style: 'currency',
      }),
    });
  }

  if (isSupplierIdCondition) {
    let supplierIds = value?.supplier_ids;
    let supplierRecords;
    try {
      supplierRecords = await store.findAll('supplier');
    } catch (error) {
      errors.handleError(error);
      return;
    }
    let supplierNames = supplierRecords
      .filter(supplier => supplierIds.includes(supplier.get('id')))
      .map(supplier => supplier.get('name'));

    return `${intl.t('approval-workflows.ruleset.conditions.supplier.eq')} ${supplierNames.join(', ')}`;
  }

  if (isUploaderIdCondition) {
    let uploaderIds = value?.uploader_ids;
    let uploaderRecords;
    try {
      uploaderRecords = await store.findAll('membership');
    } catch (error) {
      errors.handleError(error);
      return;
    }
    let uploaderNames = uploaderRecords
      .filter(uploader => uploaderIds.includes(uploader.get('id')))
      .map(uploader => uploader.get('fullName'));

    return `${intl.t('approval-workflows.ruleset.conditions.uploader.eq')} ${uploaderNames.join(', ')}`;
  }
}
