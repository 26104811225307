import { buildValidations, validator } from 'ember-cp-validations';

import { DEBOUNCE_MS } from 'qonto/constants/timers';

const EInvoiceActivationValidations = buildValidations(
  {
    eInvoicingTermsAndConditionsAccepted: [
      validator('ds-error'),
      validator('presence', {
        presence: true,
        value(model) {
          // so errors if value is false
          return model.eInvoicingTermsAndConditionsAccepted ? true : undefined;
        },
        messageKey: 'validations.errors.e-invoice-activation.blank',
      }),
    ],
    fiscalCode: [
      validator('ds-error'),
      validator('presence', {
        presence: true,
        messageKey: 'validations.errors.e-invoice-activation.blank',
      }),
      validator('length', {
        min: 11,
        messageKey: 'validations.errors.e-invoice-activation.tooShort',
      }),
      validator('length', {
        max: 16,
        messageKey: 'validations.errors.e-invoice-activation.tooLong',
      }),
    ],
    provinceCode: [
      validator('ds-error'),
      validator('presence', {
        presence: true,
        messageKey: 'validations.errors.e-invoice-activation.blank',
      }),
    ],
    taxRegime: [
      validator('ds-error'),
      validator('presence', {
        presence: true,
        messageKey: 'validations.errors.e-invoice-activation.blank',
      }),
    ],
    legalTvaNumber: [
      validator('ds-error'),
      validator('presence', {
        presence: true,
        messageKey: 'validations.errors.e-invoice-activation.blank',
      }),
      validator('length', {
        min: 3,
        messageKey: 'validations.errors.e-invoice-activation.tooShort',
      }),
      validator('length', {
        max: 30,
        messageKey: 'validations.errors.e-invoice-activation.tooLong',
      }),
      validator('format', {
        regex: /^IT\d{11}$/,
        messageKey: 'validations.errors.e-invoice-activation.invalid-vat-number-format',
      }),
    ],
  },
  {
    debounce: DEBOUNCE_MS,
  }
);

export default EInvoiceActivationValidations;
