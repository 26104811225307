import { camelize } from '@ember/string';

export default function transformKeys(
  object,
  keyTransform = camelize,
  valueTransform = transformKeys
) {
  if (object === null || typeof object !== 'object') {
    return object;
  }

  if (Array.isArray(object)) {
    return object.map(member => valueTransform(member, keyTransform, valueTransform));
  }

  let result = {};
  for (let i = 0, keys = Object.keys(object); i < keys.length; i++) {
    let key = keys[i];
    result[keyTransform(key)] = valueTransform(object[key], keyTransform, valueTransform);
  }

  return result;
}
