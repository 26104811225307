import JSONSerializer from '@ember-data/serializer/json';

import ActiveModelSerializer from 'active-model-adapter/active-model-serializer';

// Restore serialization for hasMany relationships
// which is disabled by active-model-adapter
const { serializeHasMany } = JSONSerializer.proto();

export default ActiveModelSerializer.extend({
  serializeHasMany,
});
