/**
 * @typedef {import('./config-types').TopBannerConfig} TopBannerConfig
 */

/**
 * @type {Array<TopBannerConfig>}
 */
export const ACCOUNT_LOW_BALANCE_CONFIG = [
  {
    id: 'account-low-balance',
    type: 'warning',
    message: ({ zendeskLocalization }, intl) =>
      intl.t('topbar_msg.warning_alerts', {
        warning_alerts_url: intl.t('topbar_msg.warning_alerts_url', {
          faqUrl: zendeskLocalization.getLocalizedArticle(4359620),
        }),
        warning_alerts_url_text: intl.t('topbar_msg.warning_alerts_url_text'),
        htmlSafe: true,
      }),
    shouldDisplay: ({ organizationManager }) => {
      let { currentAccount } = organizationManager;
      return currentAccount && currentAccount.hasBalanceInformation && currentAccount.isLowBalance;
    },
  },
];
