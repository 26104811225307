import Service, { service } from '@ember/service';

// eslint-disable-next-line @qonto/no-import-roles-constants
import { ROLES } from 'qonto/constants/membership';

const OVERVIEW = 'overview';
const TASKS = 'tasks.pending';
const REQUESTS = 'requests';
const TRANSACTIONS = 'transactions';

export default class HomePageService extends Service {
  @service abilities;
  @service organizationManager;
  @service router;

  visitDefaultPage(params = this.organizationSlug) {
    return this.router.transitionTo(this.defaultRouteName, params);
  }

  replaceWithDefaultPage(params = this.organizationSlug) {
    return this.router.replaceWith(this.defaultRouteName, params);
  }

  get defaultRouteName() {
    let canNavigateOverview = this.abilities.can('view overview');
    let canNavigateTasks = this.abilities.can('navigate tasks');
    let isEmployee = this.organizationManager.membership.role === ROLES.EMPLOYEE;
    let canNavigateRequests = !isEmployee && this.abilities.can('navigate request');

    return {
      true: TRANSACTIONS,
      [canNavigateRequests]: REQUESTS,
      [canNavigateTasks]: TASKS,
      [canNavigateOverview]: OVERVIEW,
    }[true];
  }

  get organizationSlug() {
    return this.organizationManager.organization.slug;
  }
}
