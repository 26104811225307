import { dasherize } from '@ember/string';

import ApplicationSerializer from 'qonto/serializers/application';
import { normalizeMissingBankAccount } from 'qonto/utils/normalize-missing-bank-account';
import transformKeys from 'qonto/utils/transform-keys';

export default class TransactionSerializer extends ApplicationSerializer {
  serialize() {
    let json = super.serialize(...arguments);

    if (this.store.adapterFor('transaction').namespace === 'v5') {
      delete json.vat;
    } else {
      delete json.vat_amount;
      delete json.vat_country;
      delete json.vat_rate;
      delete json.vat_status;
    }

    return json;
  }

  shouldSerializeHasMany(snapshot, key, relationship) {
    if (relationship.key === 'labels') {
      return true;
    }

    return super.shouldSerializeHasMany(...arguments);
  }

  normalize(modelClass, resourceHash, prop) {
    // format subject data for polymorphic relationship
    if (resourceHash.subject_id && resourceHash.subject_type) {
      resourceHash.subject = {
        id: resourceHash.subject_id,
        type: dasherize(resourceHash.subject_type),
      };
    }

    if (resourceHash.vat) {
      resourceHash.vat = transformKeys(resourceHash.vat);
    }

    normalizeMissingBankAccount(this.store, resourceHash);

    return super.normalize(modelClass, resourceHash, prop);
  }
}
