import { camelize } from '@ember/string';

import ApplicationSerializer from './application';

export default class PagopaPaymentSerializer extends ApplicationSerializer {
  extractErrors(errors) {
    return errors?.map(error => {
      let pointer = error.source?.pointer;
      if (pointer) {
        error.source.pointer = camelize(pointer);
      }
      return error;
    });
  }

  serialize(snapshot, options) {
    let json = super.serialize(snapshot, options);
    // We just want to send these 3 properties to the backend
    return {
      fiscal_code: json.fiscal_code,
      notice_number: json.notice_number,
      ...(json.bank_account_id && {
        bank_account_id: json.bank_account_id,
      }),
    };
  }
}
