import Service from '@ember/service';
import { camelize } from '@ember/string';
import { tracked } from '@glimmer/tracking';

/**
  The features-managers is called to build the features flag of an organization or a membership.

  @class featuresManager
  @module qonto/services/features-manager
  @extends Ember.Service
 */

export default class FeaturesManager extends Service {
  @tracked flags = {};

  /**
   * Setup the features flags in the application
   *
   * @public
   * @method setup
   *
   * @param  {Array} flags Features's list to enable
   * @returns void
   */
  setup(flags = []) {
    this.flags = {};
    for (let flag of flags) {
      this.flags[camelize(flag)] = true;
    }
  }

  /**
   * Returns true if the feature is enabled
   *
   * @public
   * @method isEnabled
   *
   * @param  {String} feature Name of the feature
   * @returns {Boolean}
   */
  isEnabled(feature) {
    return this.flags[feature] || false;
  }

  /**
   * Returns true if the feature is disabled
   *
   * @public
   * @method isDisabled
   *
   * @param  {String} feature Name of the feature
   * @returns {Boolean}
   */
  isDisabled(feature) {
    return !this.isEnabled(feature);
  }
}
