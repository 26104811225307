import ApplicationSerializer from 'qonto/serializers/application';

export default class BeneficiarySerializer extends ApplicationSerializer {
  serialize(snapshot, options) {
    let json = super.serialize(snapshot, options);

    delete json.sort_code;

    let { iban, bic, account_number, routing_number, account_type, ...rest } = json;
    let identifierFields = {
      iban: { iban },
      bic_swift: { bic, account_number },
      aba: { routing_number, account_number },
    };

    return { ...identifierFields[account_type], account_type, ...rest };
  }
}
