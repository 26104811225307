// @ts-nocheck
import type IntlService from 'ember-intl/services/intl';

function categoryTranslation(intlService: IntlService, category: string): string {
  let translations = {
    hardware_and_equipment: intlService.t(
      'cards.settings.restrictions.categories.options.equipment'
    ),
    food_and_grocery: intlService.t('cards.settings.restrictions.categories.options.food'),
    gas_station: intlService.t('cards.settings.restrictions.categories.options.gas-station'),
    hotel_and_lodging: intlService.t('cards.settings.restrictions.categories.options.hotels'),
    restaurant_and_bar: intlService.t('cards.settings.restrictions.categories.options.restaurants'),
    transport: intlService.t('cards.settings.restrictions.categories.options.transport'),
  };

  return translations[category];
}

export function selectedCategoryList(categoryTags: string[], intlService: IntlService): string {
  if (!categoryTags.length) throw new Error('Card has no specified selected categories');

  let translatedCategories = categoryTags.map(category =>
    categoryTranslation(intlService, category)
  );
  return translatedCategories.join(', ');
}
