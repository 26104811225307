import { assert } from '@ember/debug';

export class DllNode {
  next = null;
  previous = null;
  value;

  constructor(value) {
    this.value = value;
  }
}

export default class DoublyLinkedList {
  length = 0;
  head = null;
  tail = null;

  append(value) {
    let tailToBe = new DllNode(value);
    this._makeTail(tailToBe);
  }

  _makeTail(tailToBe) {
    if (this.length === 0) {
      this.head = tailToBe;
    } else {
      this.tail.next = tailToBe;
      tailToBe.previous = this.tail;
    }

    this.tail = tailToBe;
    this.length++;
  }

  pop() {
    let nodeToPop = this.tail;
    if (this.length === 1) {
      this.head = null;
      this.tail = null;
      this.length--;
    }

    if (this.length > 1) {
      this.tail = this.tail.previous;
      this.tail.next = null;
      this.length--;
    }

    return nodeToPop;
  }

  prepend(value) {
    assert('Should not prepend to an empty dll!', this.length > 0);

    let headToBe = new DllNode(value);

    headToBe.next = this.head;
    this.head.previous = headToBe;

    this.head = headToBe;
    this.length++;
  }

  removeAll() {
    this.head = null;
    this.tail = null;
    this.length = 0;
  }

  _appendPreviousValues(node, array) {
    if (node.previous) {
      array.unshift(node.previous.value);
      this._appendPreviousValues(node.previous, array);
    }
  }

  toArray() {
    if (this.length === 0) {
      return [];
    }

    let array = [this.tail.value];

    this._appendPreviousValues(this.tail, array);

    return array;
  }
}
