/* eslint-disable ember/require-computed-macros */
import { computed } from '@ember/object';
import { readOnly } from '@ember/object/computed';

import { buildValidations, validator } from 'ember-cp-validations';

import { ACCOUNT_TYPES } from 'qonto/constants/beneficiaries';
import { DEBOUNCE_MS } from 'qonto/constants/timers';
import { PAYMENT_PURPOSE_TYPES } from 'qonto/constants/transfers';

const TransferValidations = buildValidations(
  {
    name: [
      validator('ds-error'),
      validator('presence', {
        presence: true,
        messageKey: 'validations.errors.presence',
      }),
    ],
    localAmount: [
      validator('number', {
        disabled: computed('model.fx', function () {
          return !this.model.fx;
        }),
        allowString: true,
        gt: 0,
        messageKey: 'validations.errors.transfer.amount',
      }),
      validator('number', {
        disabled: computed('model.{fx,minFxAmount}', function () {
          return !(this.model.fx && this.model.minFxAmount);
        }),
        allowString: true,
        gte: computed('model.minFxAmount', function () {
          return this.model.minFxAmount;
        }),
        message: computed('model.{intl.locale,minFxAmount,localAmountCurrency}', function () {
          return this.model.intl?.t('transfers.errors.min-amount', {
            amount_currency: `${this.model.minFxAmount} ${this.model.localAmountCurrency}`,
          });
        }),
      }),
    ],
    amount: [
      validator('ds-error'),
      validator('presence', {
        presence: true,
        messageKey: 'validations.errors.transfer.presence',
      }),
      validator('number', {
        disabled: computed('model.fx', function () {
          return this.model.fx;
        }),
        allowString: true,
        gt: 0,
        messageKey: 'validations.errors.transfer.amount',
      }),
      validator('number', {
        disabled: computed('model.shouldValidateMaxAmount', function () {
          return !this.model.shouldValidateMaxAmount;
        }),
        allowString: true,
        lte: readOnly('model.bankAccount.authorizedBalance'),
        messageKey: 'validations.errors.insufficient_funds',
      }),
    ],
    activityTag: [
      validator('ds-error'),
      validator('presence', {
        presence: true,
        disabled: computed('model.activityTagCode', function () {
          return this.model.activityTagCode;
        }),
        messageKey: 'validations.errors.presence',
      }),
    ],
    activityTagCode: [
      validator('ds-error'),
      validator('presence', {
        presence: true,
        disabled: computed('model.activityTag', function () {
          return this.model.activityTag;
        }),
        messageKey: 'validations.errors.presence',
      }),
    ],
    bankAccount: [
      validator('inline', {
        disabled: computed('model.shouldValidateBankAccountBalance', function () {
          return !this.model.shouldValidateBankAccountBalance;
        }),
        validate(value) {
          return value.authorizedBalance > 0 ? true : this.createErrorMessage('insufficient_funds');
        },
      }),
    ],
    reference: [
      validator('ds-error'),
      validator('presence', {
        presence: true,
        messageKey: 'transfers.errors.reference-required',
      }),
      validator('length', {
        max: 135,
      }),
    ],

    iban: [
      validator('ds-error'),
      validator('presence', {
        presence: true,
        disabled: computed('model.{accountType,creditBankAccountId}', function () {
          return (
            this.model.accountType !== ACCOUNT_TYPES.IBAN || Boolean(this.model.creditBankAccountId)
          );
        }),
        messageKey: 'validations.errors.presence',
      }),
    ],

    routingNumber: [
      validator('ds-error'),
      validator('presence', {
        presence: true,
        disabled: computed('model.accountType', function () {
          return this.model.accountType !== ACCOUNT_TYPES.ABA;
        }),
        messageKey: 'validations.errors.presence',
      }),
    ],

    bic: [
      validator('ds-error'),
      validator('presence', {
        presence: true,
        disabled: computed('model.accountType', function () {
          return this.model.accountType !== ACCOUNT_TYPES.BIC_SWIFT;
        }),
        messageKey: 'validations.errors.presence',
      }),
    ],

    accountNumber: [
      validator('ds-error'),
      validator('presence', {
        presence: true,
        disabled: computed('model.accountType', function () {
          return ![ACCOUNT_TYPES.ABA, ACCOUNT_TYPES.BIC_SWIFT].includes(this.model.accountType);
        }),
        messageKey: 'validations.errors.presence',
      }),
    ],

    vatAmount: [
      validator('number', {
        value(model) {
          return Number(model.vatAmount);
        },
        disabled: computed('model.{fx,amount,vatAmount}', function () {
          return this.model.fx || !this.model.amount || !this.model.vatAmount;
        }),
        allowString: true,
        lt: computed('model.amount', function () {
          return Number(this.model.amount);
        }),
        messageKey: 'transfers.new.optional-settings.vat.error.vat-too-high',
      }),
    ],

    attachments: [
      validator('ds-error'),
      validator('presence', {
        dependentKeys: ['model.attachments.[]'],
        presence: true,
        // If transfer has already been validated and amount is updated, this force ember-cp to recompute shouldHaveAttachments
        disabled: computed('model.{shouldHaveAttachments,amount}', function () {
          return !this.model.shouldHaveAttachments;
        }),
        messageKey: computed('model.fx', function () {
          return this.model.fx
            ? 'validations.errors.uploaded_attachment-fx'
            : 'validations.errors.uploaded_attachment';
        }),
      }),
    ],

    note: [
      validator('ds-error'),
      validator('presence', {
        presence: true,
        messageKey: 'validations.errors.presence',
        disabled: computed('model.requestType', function () {
          return !this.model.requestType;
        }),
      }),
    ],

    email: [
      validator('ds-error'),
      validator('presence', {
        presence: true,
        disabled: computed('model.notifyByEmail', function () {
          return (
            !this.model.notifyByEmail ||
            this.model.get('constructor.modelName') === 'request-transfer'
          );
        }),
        messageKey: computed('model.hasMultiTransfer', function () {
          return this.model.hasMultiTransfer
            ? 'validations.errors.presence'
            : 'validations.errors.beneficiary-email.presence';
        }),
      }),
      validator('format', {
        type: 'email',
        disabled: computed('model.notifyByEmail', function () {
          return (
            !this.model.notifyByEmail ||
            this.model.get('constructor.modelName') === 'request-transfer'
          );
        }),
        minTldLength: 2,
        messageKey: 'validations.errors.email',
      }),
    ],

    beneficiaryEmail: [
      validator('ds-error'),
      validator('presence', {
        presence: true,
        disabled: computed('model.notifyByEmail', function () {
          return (
            !this.model.notifyByEmail ||
            this.model.get('constructor.modelName') !== 'request-transfer'
          );
        }),
        messageKey: 'validations.errors.presence',
      }),
      validator('format', {
        type: 'email',
        disabled: computed('model.notifyByEmail', function () {
          return (
            !this.model.notifyByEmail ||
            this.model.get('constructor.modelName') !== 'request-transfer'
          );
        }),
        minTldLength: 2,
        messageKey: 'validations.errors.email',
      }),
    ],

    fxPaymentPurpose: [
      validator('presence', {
        presence: true,
        disabled: computed('model.{fx,paymentPurposeType}', function () {
          let { fx, paymentPurposeType } = this.model;

          if (fx && paymentPurposeType) {
            return paymentPurposeType === PAYMENT_PURPOSE_TYPES.NONE;
          } else {
            return true;
          }
        }),
        messageKey: 'validations.errors.purpose_of_payment',
      }),
    ],

    scheduledDate: [
      validator('ds-error'),
      validator('presence', {
        presence: true,
        messageKey: 'validations.errors.transfer.start-date.presence',
      }),
    ],
  },
  {
    debounce: DEBOUNCE_MS,
  }
);

export default TransferValidations;
