import * as Sentry from '@sentry/ember';

export function getSessionStorageItem(key) {
  try {
    return sessionStorage.getItem(key);
  } catch (error) {
    Sentry.captureException(error);
    return undefined;
  }
}

export function setSessionStorageItem(key, value) {
  try {
    sessionStorage.setItem(key, value);
  } catch (error) {
    Sentry.captureException(error);
  }
}
