import { CARD_DESIGNS, CARD_LEVELS, CARD_NAMES, CARD_TYPES } from 'qonto/constants/cards';

interface IntlService {
  t: (key: string, opts?: Record<string, string | number> | undefined) => string;
}

export function getCardShortName(cardDesign: string, intlService: IntlService): string | null {
  switch (cardDesign) {
    case CARD_LEVELS.METAL:
      return intlService.t('cards.name.metal');
    case CARD_LEVELS.PLUS:
      return intlService.t('cards.name.plus');
    case CARD_LEVELS.STANDARD:
      return intlService.t('cards.name.standard');
    case CARD_LEVELS.VIRTUAL:
      return intlService.t('cards.name.virtual');
    case CARD_LEVELS.FLASH:
      return intlService.t('cards.name.flash');
    case CARD_LEVELS.ADVERTISING:
      return intlService.t('cards.name.advertising');
    case CARD_DESIGNS.PLUS_BLACK_2023:
      return intlService.t('cards.name.plus-black-2023');
    default:
      return null;
  }
}

export function getCardFullName(
  cardLevel: string,
  cardDesign: string,
  intlService: IntlService
): string | null {
  const isPhysical = CARD_TYPES.PHYSICALS.includes(cardLevel);

  if (isPhysical) {
    if (cardDesign === CARD_DESIGNS.PLUS_BLACK_2023) {
      return intlService.t('cards.fullname.plus-black-2023');
    }

    let name = CARD_NAMES[cardLevel as keyof typeof CARD_NAMES];
    return intlService.t('cards.fullname.physical', { cardName: name });
  }

  switch (cardLevel) {
    case CARD_LEVELS.VIRTUAL:
      return intlService.t('cards.fullname.virtual');
    case CARD_LEVELS.FLASH:
      return intlService.t('cards.fullname.flash');
    case CARD_LEVELS.ADVERTISING:
      return intlService.t('cards.fullname.advertising');
  }

  return null;
}
