import ApplicationSerializer from 'qonto/serializers/application';

export default class CountrySerializer extends ApplicationSerializer {
  normalizeResponse(store, primaryModelClass, payload, id, requestType) {
    if (requestType === 'findAll') {
      let newPayload = {
        ...payload,
        countries: payload.countries.map(country => {
          country.id = crypto.randomUUID();

          return country;
        }),
      };

      return super.normalizeResponse(store, primaryModelClass, newPayload, id, requestType);
    }

    return super.normalizeResponse(store, primaryModelClass, payload, id, requestType);
  }
}
