import Transform from '@ember-data/serializer/transform';
import { isNone as none } from '@ember/utils';

export default class StringTransform extends Transform {
  deserialize(serialized) {
    return none(serialized) ? null : String(serialized);
  }

  serialize(deserialized) {
    return none(deserialized) ? null : String(deserialized);
  }
}
