import { FRENCH_EINVOICE_PAYMENT_STORAGE_KEY } from 'qonto/constants/supplier-invoice';
import {
  USER_ACTIONS_CTA_TYPE,
  USER_ACTIONS_ILLUSTRATION_TYPE,
} from 'qonto/constants/user-actions';
import { safeLocalStorage } from 'qonto/helpers/safe-local-storage';

export function discoveryCards({ intl, router }) {
  return {
    'french-e-invoicing-payement-action'() {
      return {
        heading: intl.t('user-actions.discovery-cards.first-supplier-einvoice.title'),
        cta: {
          type: USER_ACTIONS_CTA_TYPE.BUTTON,
          onClick: () => {
            router.transitionTo('supplier-invoices.index');
            safeLocalStorage.setItem(FRENCH_EINVOICE_PAYMENT_STORAGE_KEY, true);
          },
        },
        illustration: {
          name: 'e-invoice',
          type: USER_ACTIONS_ILLUSTRATION_TYPE.SVG,
        },
        hiddenBy: [],
      };
    },
  };
}
