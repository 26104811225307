const GOOGLE_MAPS_BASE_URL = 'https://www.google.com/maps/dir/?api=1';
const TRAVEL_MODE = 'driving';

/**
 * Generates a Google Maps link for given origin and destination locations. The locations can either be
 * a one-way trip or a round-trip journey based on the isRoundTrip parameter.
 *
 * @export
 * @param {Object} origin - An object representing the starting location of the journey.
 * @param {string} origin.formattedAddress - A human-readable address of the origin location.
 * @param {string} origin.googlePlaceId - Google's place identifier for the origin location.
 * @param {Object} destination - An object representing the end location of the journey.
 * @param {string} destination.formattedAddress - A human-readable address of the destination location.
 * @param {string} destination.googlePlaceId - Google's place identifier for the destination location.
 * @param {boolean} [isRoundTrip=false] - A boolean indicating if the journey is a round trip.
 *                                       Defaults to false, which indicates a one-way trip.
 * @returns {string} Returns a string URL that can be used to navigate from the origin to destination
 *                   (and back to origin if isRoundTrip is true) using Google Maps.
 */
export function generateGoogleMapsLink(origin, destination, isRoundTrip = false) {
  let encodedDeparture = encodeURIComponent(origin.formattedAddress);
  let encodedArrival = encodeURIComponent(destination.formattedAddress);

  let originParams = `origin=${encodedDeparture}&origin_place_id=${origin.googlePlaceId}`;
  let destinationParams = isRoundTrip
    ? `destination=${encodedDeparture}&destination_place_id=${origin.googlePlaceId}`
    : `destination=${encodedArrival}&destination_place_id=${destination.googlePlaceId}`;

  let waypointsParams = '';
  if (isRoundTrip) {
    waypointsParams = `&waypoints=${encodedArrival}&waypoints_place_id=${destination.googlePlaceId}`;
  }

  return `${GOOGLE_MAPS_BASE_URL}&${originParams}&${destinationParams}${waypointsParams}&travelmode=${TRAVEL_MODE}`;
}
