/* eslint-disable ember/require-computed-macros */
import { computed } from '@ember/object';

import { buildValidations, validator } from 'ember-cp-validations';

export default buildValidations({
  name: [
    validator('presence', {
      presence: true,
      disabled: computed('model.{isCompany,kind}', function () {
        return !this.model.isCompany;
      }),
      messageKey: 'validations.errors.blank',
    }),
  ],
  firstName: [
    validator('presence', {
      presence: true,
      disabled: computed('model.{isCompany,kind}', function () {
        return this.model.isCompany;
      }),
      messageKey: 'validations.errors.blank',
    }),
  ],
  lastName: [
    validator('presence', {
      presence: true,
      disabled: computed('model.{isCompany,kind}', function () {
        return this.model.isCompany;
      }),
      messageKey: 'validations.errors.blank',
    }),
  ],
  email: [
    validator('presence', {
      presence: true,
      messageKey: 'validations.errors.blank',
    }),
    validator('format', {
      type: 'email',
      minTldLength: 2,
      messageKey: 'receivable-invoices.customer-creation.errors.invalid-email',
    }),
  ],
  zipCode: [
    validator('length', {
      is: 5,
      disabled: computed('model.{countryCode,zipCode}', function () {
        return !this.model.zipCode || this.model.countryCode !== 'IT';
      }),
      messageKey: 'validations.errors.sdd.zipcode',
    }),
    validator('number', {
      allowString: true,
      integer: true,
      disabled: computed('model.{countryCode,zipCode}', function () {
        return !this.model.zipCode || this.model.countryCode !== 'IT';
      }),
      messageKey: 'validations.errors.sdd.zipcode',
    }),
  ],
});
