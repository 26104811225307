import { getOwner } from '@ember/application';

import { buildValidations, validator } from 'ember-cp-validations';

export default buildValidations({
  sendTo: [
    validator('presence', {
      presence: true,
      message() {
        return getOwner(this.model)
          .lookup('service:intl')
          .t('receivable-invoices.share-email.required-field');
      },
    }),
    validator('ds-error'),
  ],
  rules: validator('has-many'),
});
