/**
 * Filters out main menu button groups with empty items from an array of group objects.
 * @param {Array} groups - An array of group objects.
 * @returns {Array} - An array containing filtered group objects.
 */
export default function filterEmptyGroups(groups) {
  return groups
    ?.map(({ items, label }) => ({
      items: items.filter(Boolean),
      label,
    }))
    .filter(({ items }) => items.length);
}
