import { EmbeddedRecordsMixin } from '@ember-data/serializer/rest';

import ApplicationSerializer from 'qonto/serializers/application';

export default class InviteSerializer extends ApplicationSerializer.extend(EmbeddedRecordsMixin) {
  attrs = {
    address: { embedded: 'always' },
    documents: { embedded: 'always' },
  };

  serialize() {
    let json = super.serialize(...arguments);

    let perTransferLimit = json.per_transfer_limit;
    delete json.per_transfer_limit;

    let monthlyTransferLimit = json.monthly_transfer_limit;
    delete json.monthly_transfer_limit;

    let monthly_transfer_limit = null;
    if (monthlyTransferLimit && monthlyTransferLimit.value) {
      monthly_transfer_limit = {
        value: monthlyTransferLimit.value ? `${monthlyTransferLimit.value}` : null,
        currency: 'EUR',
      };
    }

    let per_transfer_limit = null;
    if (perTransferLimit && perTransferLimit.value) {
      per_transfer_limit = {
        value: perTransferLimit.value ? `${perTransferLimit.value}` : null,
        currency: 'EUR',
      };
    }

    let spend_limits = null;
    if (monthly_transfer_limit || per_transfer_limit) {
      spend_limits = {
        per_transfer_limit,
        monthly_transfer_limit,
      };
    }

    json.spend_limits = spend_limits;
    json.skip_limits = !spend_limits;

    return json;
  }
}
