import { buildValidations, validator } from 'ember-cp-validations';

import { DEBOUNCE_MS } from 'qonto/constants/timers';

const PeriodValidations = buildValidations(
  {
    startDate: [validator('ds-error'), validator('presence', true)],
    endDate: [validator('ds-error'), validator('presence', true)],
    amountBudgeted: [validator('ds-error'), validator('amount')],
  },
  {
    debounce: DEBOUNCE_MS,
  }
);

export default PeriodValidations;
