import { EmbeddedRecordsMixin } from '@ember-data/serializer/rest';

import ApplicationSerializer from 'qonto/serializers/application';
import { normalizeMissingBankAccount } from 'qonto/utils/normalize-missing-bank-account';

export default class RequestMultiTransferSerializer extends ApplicationSerializer.extend(
  EmbeddedRecordsMixin
) {
  attrs = {
    transfers: {
      deserialize: 'records',
      serialize: 'records',
    },
  };

  normalize(modelClass, payload) {
    normalizeMissingBankAccount(this.store, payload);
    return super.normalize(modelClass, payload);
  }
}
