import Service, { service } from '@ember/service';

import { apiBaseURL, bankAccountNamespace } from 'qonto/constants/hosts';
import { ErrorInfo } from 'qonto/utils/error-info';

export default class BankAccountService extends Service {
  @service networkManager;
  @service toastFlashMessages;
  @service intl;
  @service sentry;

  async getIbanPdf(bankAccountId, locale) {
    let url = `${apiBaseURL}/${bankAccountNamespace}/bank_accounts/${bankAccountId}/iban.pdf`;

    if (locale) {
      url += `?locale=${locale}`;
    }

    let response = await this.networkManager.rawRequest(url);

    return await response.blob();
  }

  async downloadIbanPdf(bankAccountId, locale) {
    let objectURL;

    try {
      let pdfBlob = await this.getIbanPdf(bankAccountId, locale);
      objectURL = URL.createObjectURL(pdfBlob);
      let link = document.createElement('a');

      link.href = objectURL;
      link.download = locale ? `iban_${locale}.pdf` : 'iban.pdf';
      link.click();
    } catch (error) {
      this.toastFlashMessages.toastError(this.intl.t('errors.internal_server_error'));
      let errorInfo = ErrorInfo.for(error);
      if (errorInfo.shouldSendToSentry) this.sentry.captureException(error);
    } finally {
      if (objectURL) URL.revokeObjectURL(objectURL);
    }
  }
}
