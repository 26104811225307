import { setOwner } from '@ember/owner';
import { service } from '@ember/service';
import { dasherize } from '@ember/string';

import { singularize } from 'ember-inflector';

export default class BulkRelationErrors {
  @service store;
  @service networkManager;

  constructor(owner) {
    setOwner(this, owner);
  }

  extractAndInject(payload, rootKey, relationKey, idKey = 'bulk_id') {
    if (!payload || !payload[rootKey] || !payload[rootKey][relationKey]) {
      return;
    }

    for (let index = 0; index < payload[rootKey][relationKey].length; index++) {
      let hasManyObject = payload[rootKey][relationKey][index];

      if (hasManyObject.errors) {
        let { amount_cents, ...errors } = hasManyObject.errors;
        // turn backend `amount_cents` error key into `amount`
        if (!errors.amount && amount_cents) {
          errors.amount = amount_cents;
        }

        let store = this.store;
        let model;
        let recordName;

        if (idKey !== 'bulk_id') {
          recordName = `${singularize(dasherize(relationKey))}`;
        } else {
          recordName = `bulk-${singularize(dasherize(relationKey))}`;
        }

        // Ember Data requires us to pass in an `Error` for the
        // `recordWasInvalid()` call to work. An empty dummy error appears
        // to be sufficient though.
        let dummyError = new Error('Validation failed');

        if (recordName === 'bulk-transfer') {
          let { id } = payload[rootKey];
          let item = hasManyObject[idKey];
          model = store.peekRecord(recordName, `${id}#${item}`);
        } else {
          model = store.peekRecord(recordName, hasManyObject[idKey]);
        }

        this.networkManager.errorModelInjector(model, errors, dummyError);
      }
    }
  }
}
