import ApplicationSerializer from 'qonto/serializers/application';
import { normalizeMissingBankAccount } from 'qonto/utils/normalize-missing-bank-account';

export default class CheckSerializer extends ApplicationSerializer {
  serialize(snapshot, options) {
    let json = super.serialize(snapshot, options);

    let cmc7 = json.cmc7.slice(); // make a copy
    json.cmc7 = {};
    json.cmc7.a = cmc7.substring(0, 7);
    json.cmc7.b = cmc7.substring(7, 19);
    json.cmc7.c = cmc7.substring(19, 31);

    // serialize `amount` into backend expected `amount_cents`
    json.amount_cents = Math.round(json.amount * 100);

    delete json.amount;

    return json;
  }

  normalize(modelClass, hash) {
    hash.cmc7 = `${hash.cmc7.a}${hash.cmc7.b}${hash.cmc7.c}`;

    // normalize backend provided `amount_cents` into `amount`
    hash.amount = hash.amount_cents / 100;

    normalizeMissingBankAccount(this.store, hash);

    return super.normalize(modelClass, hash);
  }
}
