import Service, { service } from '@ember/service';

import { DATE_FORMAT_TOKENS, dateToken } from '@qonto/ui-kit/utils/date-token';
import dayjs from 'dayjs';
import { dropTask } from 'ember-concurrency';

import { ErrorInfo } from 'qonto/utils/error-info';

export default class FreeUsersDiscountManagerService extends Service {
  @service organizationManager;
  @service subscriptionManager;
  @service localeManager;
  @service store;
  @service intl;
  @service toastFlashMessages;
  @service sentry;
  @service abilities;

  fetchDiscountTask = dropTask(async ({ includeFinishedThisMonth, includeInvalid } = {}) => {
    let { organization } = this.organizationManager;
    try {
      let discounts = await this.store.query('discount', {
        organization_id: organization.id,
        voucher_type: 'promotional',
        voucher_fee_type: 'additional_user',
        include_invalid: includeInvalid,
        include_finished_this_month: includeFinishedThisMonth,
      });

      return discounts[0];
    } catch (error) {
      this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));

      let errorInfo = ErrorInfo.for(error);
      if (errorInfo.shouldSendToSentry) {
        this.sentry.captureException(error);
      }
    }
  });

  get canCreatePaidMembers() {
    return this.abilities.can('create paid members member');
  }

  getExpirationDate(voucherEndDate) {
    // Voucher's last date = last day of the (month n-1), where (month n) = month when voucher expires
    return dateToken({
      date: dayjs(voucherEndDate).subtract(1, 'month').endOf('month').startOf('day'),
      locale: this.localeManager.locale,
      token: DATE_FORMAT_TOKENS.DATE_YEAR_L,
    });
  }

  getIsDiscountActive(discount) {
    return Boolean(discount);
  }

  getEligibilityCriteria(discount) {
    if (this.getIsDiscountActive(discount) && this.canCreatePaidMembers) {
      let { membershipsCountingTowardsPlanLimitCount: activeMembersCount } =
        this.organizationManager.organization;

      let { id: currentPricePlanId } = this.subscriptionManager.currentPricePlan;
      let userLimit = this.subscriptionManager.getLimitByFeatureCode('additional_users');
      if (activeMembersCount < userLimit) {
        return undefined;
      }

      let { voucherRestrictions, voucherAmount, finishOn } = discount;

      let voucherMinimumActiveUsers = parseFloat(
        voucherRestrictions.find(({ entity }) => entity === 'minimum_active_users')?.values?.[0] ||
          0
      );

      let maximumActiveUsersCount;
      let minimumActiveUsersCount;

      let additionalUserCost = this.subscriptionManager.options.additionalUser?.value;

      let freeAdditionalUsers = Math.floor(voucherAmount / additionalUserCost);

      if (voucherMinimumActiveUsers <= userLimit) {
        minimumActiveUsersCount = userLimit + 1;
        maximumActiveUsersCount = userLimit + freeAdditionalUsers;
      } else {
        minimumActiveUsersCount = voucherMinimumActiveUsers;
        maximumActiveUsersCount = voucherMinimumActiveUsers + freeAdditionalUsers - 1;
      }

      let isAboveMinimum = minimumActiveUsersCount <= activeMembersCount + 1;
      let isBelowMaximum = activeMembersCount < maximumActiveUsersCount;

      let voucherPricePlanIds =
        voucherRestrictions.find(({ entity }) => entity === 'price_plan')?.values || [];

      let isCurrentPricePlanAllowed =
        voucherPricePlanIds.length === 0 || voucherPricePlanIds.includes(currentPricePlanId);

      let canAddFreeUsers = isAboveMinimum && isBelowMaximum && isCurrentPricePlanAllowed;

      let minimumRequiredActiveUsersCount =
        voucherMinimumActiveUsers !== 0 ? voucherMinimumActiveUsers : undefined;

      return {
        canAddFreeUsers,
        isBelowMaximum,
        freeAdditionalUsers,
        isAboveMinimum,
        isCurrentPricePlanAllowed,
        voucherPricePlanIds,
        minimumRequiredActiveUsersCount,
        finishOn,
      };
    }
  }
}
