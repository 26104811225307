/**
 * Extracts a field key from a given JSON Pointer string.
 *
 * It takes a JSON Pointer string and a prefix, removes the prefix
 * from the pointer, and converts the remaining path to a dot notation format.
 *
 * @param {Object} params - The parameters for the function.
 * @param {string} params.pointer - The JSON Pointer string from which to extract the field key.
 * @param {string} params.prefix - The prefix to remove from the pointer.
 * @returns {string} The extracted field key in dot notation format.
 *
 * @example
 * const fieldKey = extractFieldKeyFromPointer({
 *   pointer: '/data/attributes/name/first',
 *   prefix: '/data/attributes'
 * });
 * console.log(fieldKey); // 'name.first'
 */
export function extractFieldKeyFromPointer({ pointer, prefix }) {
  return pointer.substring(pointer.indexOf(prefix) + prefix.length).replace(/\//g, '.');
}

/**
 * Extracts a nested value from a payload object using a dot-separated key.
 *
 * @param {Object} params - The parameters object.
 * @param {string} params.fieldKey - The dot-separated key string indicating the path to the desired value in the payload.
 * @param {Object} params.payload - The object from which to extract the value.
 * @returns {*} The value found at the specified key path in the payload, or `undefined` if any key in the path does not exist.
 *
 * @example
 * const fieldKey = 'user.name.first';
 * const payload = {
 *   user: {
 *     name: {
 *       first: 'John'
 *     }
 *   }
 * };
 * const value = extractInvalidValueFromPayload({ fieldKey, payload });
 * console.log(value); // 'John'
 */
export function extractInvalidValueFromPayload({ fieldKey, payload }) {
  return fieldKey.split('.').reduce((accumulator, key) => accumulator?.[key], payload);
}
