import Service, { service } from '@ember/service';

import { safeLocalStorage } from 'qonto/helpers/safe-local-storage';

export const MODAL_LAST_OPENED_AT_KEY = 'association-kyb-pending-modal-last-opened-at';
const ONE_DAY_IN_MS = 24 * 60 * 60 * 1000;

/**
 * Checks whether or not Date.now() is greater than the given Unix timestamp.
 */
function isNowAfter(timestamp) {
  return Date.now() > timestamp;
}

export default class AssociationKybPendingModalService extends Service {
  @service organizationManager;
  @service modals;

  show() {
    let { isAssociationKybPending } = this.organizationManager.organization;

    if (isAssociationKybPending && this.#shouldOpen()) {
      this.modals.open('association-kyb-pending-modal');
      this.#persistLastOpenedUnixTimestamp();
    }
  }

  get localStorageKey() {
    return `${this.organizationManager.organization.id}-${MODAL_LAST_OPENED_AT_KEY}`;
  }

  #shouldOpen() {
    let timestamp = this.#getLastOpenedUnixTimestamp();
    return isNowAfter(timestamp + ONE_DAY_IN_MS);
  }

  #getLastOpenedUnixTimestamp() {
    let unixTime = safeLocalStorage.getItem(this.localStorageKey);

    if (!unixTime) return 0;

    if (Number(unixTime) > 0) {
      return Number(unixTime);
    } else {
      return 0;
    }
  }

  #persistLastOpenedUnixTimestamp() {
    safeLocalStorage.setItem(this.localStorageKey, Date.now());
  }
}
