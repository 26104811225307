import { EmbeddedRecordsMixin } from '@ember-data/serializer/rest';

import ApplicationSerializer from 'qonto/serializers/application';

export default class ExerciseSerializer extends ApplicationSerializer.extend(EmbeddedRecordsMixin) {
  attrs = {
    periods: { embedded: 'always' },
  };

  serialize(snapshot, options) {
    let serialized = super.serialize(snapshot, options);
    delete serialized.budget_id;
    return serialized;
  }

  normalize(model, hash, prop) {
    let normalized = super.normalize(model, hash, prop);
    if (hash.lid) normalized.data.lid = hash.lid;
    return normalized;
  }
}
