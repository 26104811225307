import { safeLocalStorage } from 'qonto/helpers/safe-local-storage';

/**
 * @typedef {import('./config-types').TopBannerConfig} TopBannerConfig
 */

/**
 * @type {Array<TopBannerConfig>}
 */
export const SUBSCRIPTION_CHANGE_CONFIG = [
  {
    id: 'subscription-change',
    type: 'info',
    componentName: 'subscription/topbar',
    message: (_, intl) => intl.t('subscription.change.top-bar'),
    shouldDisplay: ({ organizationManager }) => {
      let { organization } = organizationManager;
      let data = safeLocalStorage.getItem('price-plan-change-in-progress');
      try {
        let { code, orgId } = JSON.parse(data);
        if (!code || !orgId) {
          return false;
        }
        return organization.id === orgId;
      } catch {
        return false;
      }
    },
  },
];
