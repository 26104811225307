import Service, { service } from '@ember/service';

import { dropTask } from 'ember-concurrency';
import { reads } from 'macro-decorators';

export default class ActivityTypeService extends Service {
  @service intl;
  @service organizationManager;

  @reads('loadActivityCodesTask.lastSuccessful.value') activityCodesCache;
  @reads('organizationManager.organization.legalCountry') legalCountry;

  get activityCodes() {
    if (this.legalCountry === 'FR') {
      return this.activityCodesCache.fr[this.intl.primaryLocale];
    }
    return this.activityCodesCache.int[this.intl.primaryLocale];
  }

  loadActivityCodesTask = dropTask(async () => {
    let response = await fetch(`/activity-codes.json`);
    return response.json();
  });
}
