// @ts-nocheck
import dayjs from 'dayjs';

interface CardEstimate {
  monthly_cost: number;
  next_recurrence_on: string;
}

type CardsEstimates = {
  [key in string]: CardEstimate;
};

export function getMonthlyCost(cardLevel: string, estimates: CardsEstimates): number {
  return estimates[cardLevel].monthly_cost;
}

export function getUpsellNextBillingRecurrence(
  cardUpsellLevel: string,
  estimates: CardEstimates
): Date {
  return dayjs(Date.parse(estimates[cardUpsellLevel].next_recurrence_on)).add(1, 'month').toDate();
}

export function getUpsellFreeUntil(cardUpsellLevel: string, estimates: CardsEstimates): Date {
  let willStartPayingAt = getUpsellNextBillingRecurrence(cardUpsellLevel, estimates);
  return dayjs(willStartPayingAt).subtract(1, 'day').toDate();
}
