/**
 * Returns an array of params for the route hierarchy starting with the passed-in `RouteInfo`.
 * This array is ordered top-down so it can be passed onto methods such as `RouterService#urlFor`
 *
 * @param [RouteInfo] routeInfo The leaf routeInfo to tranverse
 */
export default function dynamicSegmentsFromRouteInfo(routeInfo) {
  // https://github.com/NullVoxPopuli/ember-query-params-service/blob/e98e64e6cfa320f28a5f11c83b18da171c055355/addon/-private/dynamic-segments-from-route-info.ts
  if (!routeInfo) {
    return [];
  }

  let parts = dynamicSegmentsFromRouteInfo(routeInfo.parent);
  for (let key of routeInfo.paramNames) {
    parts.push(routeInfo.params[key]);
  }

  return parts;
}
