// @ts-nocheck
const CARDS_PARENT_ROUTE_PATTERN: RegExp = /^cards\.?/;

interface Transition {
  from?: {
    name: string;
  };
}

export function isTransitioningFromCardsRoute(transition: Transition): boolean {
  return CARDS_PARENT_ROUTE_PATTERN.test(transition?.from?.name);
}
